import React, { useState, useEffect } from 'react';
import './App.scss';

import { HashRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { History } from 'history'

import { Card, Elevation, FormGroup, AnchorButton, Button, Navbar, Alignment, Popover, Menu, MenuDivider, MenuItem, Position, Intent, Spinner, Switch as BPSwitch, Tooltip } from "@blueprintjs/core";
import Draggable from 'react-draggable';

import './gsbx.scss';
import Login, { LoginCredential } from './Login';
import ClMastMain from './ClMast';
import ClACListMain from './ClACList';
import ClACListSfcMain from './ClACListSfc';
import ClACBalXlsMain from './ClACBalXls';


import ClDocSetMain from './ClDocSet';

import ClLogMain from './ClLog';
import ImportClMast2Main from './ImportClMast2';
import ImportClMast3Main from './ImportClMast3';    // 20230814
import ImportClMast1Main from './ImportClMast1';  //20240312

import SmMastMain from './SmMast';
import SmListMain from './SmList';
import SmLogMain from './SmLog';
import ImportStkMastMain from './ImportStkMast';    // 20230814
import ImportStkMast2Main from './ImportStkMast2';  // 20230828
import ImportStkMastNilMain from './ImportStkMastNil';  // 20230829
import ImportStkMastMarginRatioMain from './ImportStkMastMarginRatio';  // 20230829
import ImportStkMastGroupMain from './ImportStkMastGroup';  // 20230829
import BkMastMain from './BkMast';
import BkLogMain from './BkLog';
import IntRMastMain from './IntRMast';
import PrimeRateMastMain from './PrimeRateMast';
import IntRMastListMain from './IntRMastList';
import IntRLogMain from './IntRLog';

import HolidayMastMain from './holidayMast';
import HolidayMastListMain from './HolidayMastList';

import CommMastMain from './CommMast';
import CommLogMain from './CommLog';
import MktMastMain from './MktMast';
import MktLogMain from './MktLog';
import MktFeeMastMain from './MktFeeMast';
import MktFeeLogMain from './MktFeeLog';

import MultiBankMastMain from './MultiBankMast';
import MultiBankMastListMain from './MultiBankMastList';
import MultiBankLogMain from './MultiBankLog';

import ExchangeRateMain from './ExchangeRate';
import ExchangeRateListMain from './ExchangeRateList';

import DayStartProcMain from './DayStartProc';
import ClientTranEntryMain from './ClientTranEntry';
import ClientTranCheckListMain from './ClientTranCheckList';
import ClientTranLogMain from './ClientTranLog';
import ClientInterestAccruedMain from './ClientInterestAccrued';    // 20230823
import ClientInterestAccruedRptMain from './ClientInterestAccruedRpt';  // 20230823
import ClientTranClearMain from './ClientTranClear';
import BrokerTranEntryMain from './BrokerTranEntry';
import BrokerTranCheckListMain from './BrokerTranCheckList';
import BrokerTranLogMain from './BrokerTranLog';
import BrokerInterestAccruedMain from './BrokerInterestAccrued';    // 20230823
import BrokerInterestAccruedRptMain from './BrokerInterestAccruedRpt';    // 20230823
import BrokerTranClearMain from './BrokerTranClear';
import PayRecTranEntryMain from './PayRecTranEntry';
import PayRecTranCheckListMain from './PayRecTranCheckList';
import PayRecCheqMain from './PayRecCheq';
import PayRecTranLogMain from './PayRecTranLog';
import PayRecTranClearMain from './PayRecTranClear';
import StkDepWithTranEntryMain from './StkDepWithTranEntry';
import StkDepWithTranCheckListMain from './StkDepWithTranCheckList';
import StkDepWithTranLogMain from './StkDepWithTranLog';
import StkDepWithClearMain from './StkDepWithClear';
import StkGlobalWithdrawalMain from './StkGlobalWithdrawal';
import ClientTranSettlementEntryMain from './ClientTranSettlementEntry';
import ClientTranSettlementCheckListMain from './ClientTranSettlementCheckList';
import ClientTranSettlementLogMain from './ClientTranSettlementLog';
import StockAllocationEntryMain from './StockAllocationEntry';
import StockAllocationRptMain from './StockAllocationStatusRpt';
import MatchingMain from './Matching';
//import ShareTransGenErMain from './ShareTransGenEr'
import MatchingReportMain from './MatchingReport';
import CancelPrevTradeMain from './CancelPrevTrade';
import CorrectTradeMain from './CorrectTrade';
import CancelCorrectTradeRegisterMain from './CancelCorrectTradeRegister';
import CancelCorrectTradeLogMain from './CancelCorrectTradeLog';

import Sas_Rpt1Main from './Sas_Rpt1';
import Sas_Rpt2Main from './Sas_Rpt2';
import Sas_Rpt3Main from './Sas_Rpt3';
import Sas_Rpt4Main from './Sas_Rpt4';
import Sas_Rpt5Main from './Sas_Rpt5';

import Ati_Rpt1Main from './Ati_Rpt1';
import Ati_Rpt2Main from './Ati_Rpt2';
import Ati_Rpt3Main from './Ati_Rpt3';
import Ati_Rpt4Main from './Ati_Rpt4';

import Eps_Rpt1Main from './Eps_Rpt1';
import Eps_Rpt2Main from './Eps_Rpt2';

import ImportMain from './Import';

import PreDayEndProcMain from './PreDayEndProc';
import Cheque2ClientMain from './Cheque2Client';
import PrintClientChqRegisterMain from './PrintClientChqRegister';
import DayEndProcMain from './DayEndProc';
import DayEndProc2Main from './DayEndProc2';


import StkOverdrawnRptMain from './StkOverdrawnRpt';
import StkHoldListMain from './StkHoldList';
import StkHoldListSfcMain from './StkHoldListSfc';
import StkHoldListSfc2Main from './StkHoldListSfc2';
import StkHoldXlsMain from './StkHoldXls';        //20231107 Bug4387 Quot11532
import StkHaircutXlsMain from './StkHaircutXls';  //20231128 Bug4387 Quot11532
import PortfolioEvaluationMain from './PortfolioEvaluation';
import PortfolioEvaluation2Main from './PortfolioEvaluation2';
import AcBalListMain from './AcBalList';
import MgnPositionMain from './MgnPosition';
import TopmostBalanceListMain from './TopmostBalanceList';
import ApprovedStockAssetsReportMain from './ApprovedStockAssetsReport';
import RollingBalanceReportMain from './RollingBalanceReport';
import UnsettleTranAcceptValueMain from './UnsettleTranAcceptValue';
import ClHistoryMain from './ClHistory';
import ClHistory2Main from './ClHistory2';
import ClHistorySfcMain from './ClHistorySfc';

import MthRebateRptMain from './MthRebateRpt';
import MthContractStmpRptMain from './MthContractStmpRpt'; //(85)
import MthStmpReturnRptMain from './MthStmpReturnRpt';
import MthInterestRptMain from './MthInterestRpt';
import MthLevyCCassFeeRptMain from './MthLevyCcassFeeRpt';

import ClientPosEnquiryMain from './ClientPosEnquiry';

import MthSummaryRptMain from './MthSummaryRpt';
import MthCCassCustodyFeeRptMain from './MthCCassCustodyFeeRpt';

import ClientTurnOverRptMain from './ClientTurnOverRpt';

import AccInfoMain from './AccInfo';
import DviewMain from './Dview';    // AD VIEW1 show addr 
import Dview2Main from './Dview2';  // AD VIEW no show addr
 
import DviewTransDateMain from './DviewTransDate';

import DviewDownMain from './DviewDown';
//import DviewOutMain from './DviewOut';

import DvStkHolderListMain from './DvStkHolderList';
import DvStkHolderList2Main from './DvStkHolderList2';

import DvDividendMain from './DvDividend';
 
import DvStkAllocStatusMain from './DvStkAllocStatus';  //AD8 Dview

import DstatementMain from './Dstatement';
import MstatementMain from './Mstatement';
import Mstatement2Main from './Mstatement2';
import SentStatementMain from './SentStatement';
import LevyRptMain from './levyRpt';

import StockAnnouncementEntryMain from './StockAnnouncementEntry';
import StockAnnouncementLogMain from './StockAnnouncementLog';
import BookCloseReminderMain from './BookCloseReminder';
import MktDividendChargeMain from './MktDividendCharge';
import DividendProcessMain from './DividendProcess';
import DividendProcess02Main from './DividendProcess02';
import DividendProcess03Main from './DividendProcess03';
import DividendProcess04Main from './DividendProcess04';
import WarrantRightsProcessMain from './WarrantRightsProcess';
import WarrantRightsProcess02Main from './WarrantRightsProcess02';
import WarrantRightsProcess03Main from './WarrantRightsProcess03';
import BookClosedQtyMain from './BookClosedQty';
import InputAnnouncementMain from './InputAnnouncement';


import ExportWebStmt1Main from './ExportWebStmt';

import SBReindexMain from './SBReindex';
import ReUpdateTrnMain from './ReUpdateTrn';
import SBBackupMain from './SBBackup';
import SBBatchMain from './SBBatch';
import SBBatch2Main from './SBBatch2';
import SBBatch3Main from './SBBatch3';
import SBBatch4Main from './SBBatch4';
import SBBatch10Main from './SBBatch10';
import SBBatch5Main from './SBBatch5';
import LoginLogMain from './LoginLog';
import ResetprocessMain from './Resetprocess';
import SBstatusMain from './SBstatus';
import {SBstatusMini} from './SBstatus';
import SBModuleMain from './SBModule';
import UserMastMain from './UserMast';
import UserMastListMain from './UserMastList';
import UserMastLogMain from './UserMastLog';
import UserchgPasswdMain from './UserchgPasswd';
import UserchgPasswd2Main from './UserchgPasswd2';
import UserchgPasswd3Main from './UserchgPasswd3';
import UserAccessGroupMain from './UserAccessGroup';
import UserPgmAccessMain from './UserPgmAccess';
import UserPgmAccessListMain from './UserPgmAccessList';
import UserPgmAccessLogMain from './UserPgmAccessLog';
import GroupAccessMain from './GroupAccess';
import GroupAccessListMain from './GroupAccessList';
import GroupAccessLogMain from './GroupAccessLog';
import GroupAccess02Main from './GroupAccess02';
import ReqAuthListMain from './ReqAuthList';
import AboutMain from './About';
import TempChgFieldMain from './TempChgField';



import HkCrsImpAccountMain from './HkCrsImpAccount';
import HkCrsMastMain from './HkCrsMast';
import HkCrsMastListMain from './HkCrsMastList';
import HkCRSLogMain from './HkCRSLog';

import HkCrsDataMastMain from './HkCrsDataMast';
import HkCrsDataImpAccountMain from './HkCrsDataImpAccount';
import HkCrsExportMain from './HkCrsExport';
import HkCrsEnquiryMain from './HkCrsEnquiry';


import HkidrImpAccountMain from './HkidrImpAccount';
import HkidrMastMain from './HkidrMast';
import HkidrMastListMain from './HkidrMastList';
import HkidrLogMain from './HkidrLog';
import GsbNameChangeLogMain from './GsbNameChangeLog';
import CNBcanGenMain from './CNBcanGen';
import ExportHkConsentMain from './HkidrMastExport';

import ImportHkConsentCsvMain from './ImportHkConsentCsv';

import HkidrHkConsentMain from './HkidrHkConsent';
import HkidrBcanMappingMain from './HkidrBcanMapping';
import HkidrResponseFileMain from './HkidrResponseFile';

//CNINA CONNECT
import CNBcanMappingMain from './CNBcanMapping';
import CNBcanResponseFileMain from './CNBcanResponseFile';
import CNBcanAurpResponseFileMain from './CNBcanAurpResponseFile';
import CNBcanFimgFileMain from './CNBcanFimgFile';
import CNBcanRsltResultFileMain from './CNBcanRsltResultFile';

import HkidrOtcrMain from './HkidrOtcr';
import HkidrOtcr2Main from './HkidrOtcr2';

//import IPOMastMain from './IPOMast';
import ExportEIPOMain from './ExportEIPO';
import IPOMastImportMain from './IPOMastImport';
import EIPOAuthorisedMain from './EIPOAuthorised' ;

//import HkidrOtcrResponseFileMain from './HkidrOtcrResponseFile';

import HkidrProcessResultFileMain from './HkidrProcesssResultFile';
import HkidrFullImageFileMain from './HkidrFullImageFile';
 
import HkidrETTradeListMain from './HkidrETTradeList';
import HkidreBrokerListMain from './HkidreBrokerList';
import HkidrAyersListMain from './HkidrAyersList';
import HkidrInfocastListMain from './HkidrInfocastList';

import GsbxConfigMain from './GsbxConfig';
import BcanConfigMain from './BcanConfig';
import CrsConfigMain from './CrsConfig';

import TaskMain from './Task';
import WIPMain from './WIP';
import ClMast2Main from './ClMast2';
import * as utils from "./utils";

import md5 from './md5'

import axios from 'axios';

import svgLogo from './svg/logo.svg'
//import svgLogo from './svg/plogo.gif'
import svgAccount from './svg/client.svg'
import svgMaster from './svg/Master.svg'
//import svgDocument from './svg/document.svg'
import svgDocument from './svg/Circle-icons-document.svg'
import svgFileBox from './svg/file-box.svg'
import svgProfit from './svg/profit.svg'
import svgMoney from './svg/money.svg'
import svgFinancialreport from './svg/financial-report.svg'

//import svgDownload from './svg/download.svg'
import svgDownload from './svg/download1.svg'
import svgZip from './svg/zip.svg'
import zipDocument from './svg/zip.svg'
import svgImport from './svg/import.svg'
import svgUser from './svg/user.svg'
import svgInput from './svg/input.svg'
import svgTask from './svg/task.svg'
import svgChecklist from './svg/checklist.svg'
import svgbuild from './svg/build.svg'
import svgsort_asc from './svg/sort-asc.svg'
import tick_circle from './svg/checked.svg'

import i18next from 'i18next';
import { PgmAccessList } from './Types'
import { SystemHeader } from './CommonUI';

var GsbFlag: any;

const gsb = axios.create({
    //baseURL: 'http://localhost:8000',
    //baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:8000/api' : '/api',
    baseURL: process.env.NODE_ENV === "development" ? `http://${window.location.hostname}:8000/api` : '/api',
})

//const hostId = process.env.NODE_ENV === "development" ? md5('localhost:8000').slice(-8) : md5(window.location.host).slice(-8)
const hostId = process.env.NODE_ENV === "development" ? md5(`${window.location.hostname}:8000`).slice(-8) : md5(window.location.host).slice(-8)

// try load config from server, can be a real file or a config in web server
/*
axios.get("config.json").then((res) => {
    //console.log("load config.json from server", res.data)
    gsb.defaults.baseURL = res.data.baseUrl
}).catch(() => {
    // do nothing, use default
})
*/

var GSB_Sys_VerNo = "";

function App() {

 

    const pingServer = () => {
        (async () => {
            try {
                //console.log("app GSB_Sys_VerNo", GSB_Sys_VerNo)
                const res =await gsb.post('/ping',
                    {});
                //console.log("app pingServer", res.data, res.data.Sys_VerNo)
                if ( GSB_Sys_VerNo == "" ) {
                    GSB_Sys_VerNo = res.data.Sys_VerNo
                } else if ( GSB_Sys_VerNo == res.data.Sys_VerNo ) {

                } else {
                    //console.log("app pingServer reloaded")
                    window.location.reload()
                }

            } catch (error) {
                //utils.showError(error)
                //console.log(error)
            }
        })()
    }

    useEffect(() => {
        pingServer()
        const worker = new Worker('worker.js')
        worker.onmessage = () => {
            pingServer()
        }
        return () => worker.terminate()
    }, [])


    window.onkeydown = (e: KeyboardEvent) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // disable ctrl + s to prevent save page when user trying to submit form while lost focus
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
        }
    }
    const [IPAddr, setIPAddr] = useState("")
    const [userId, setuserId] = useState("")

/*
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [gsb])
*/

    const handleLogin = (credential: LoginCredential, history: History) => {
        (async () => {
            try {
                const res = await gsb.post('/login',
                    {
                        userId: credential.login,
                        password: credential.password,
                        cIPAddr: IPAddr,
                    });
                if (res.data.ok) {
                    if (! res.data.chg_passwd) {
                        //console.log('Bearer '+res.data.token)
                        gsb.defaults.headers = {
                            'Authorization': 'Bearer ' + res.data.token
                        }
                        localStorage.setItem('token', res.data.token)
                        document.cookie = `token_${hostId}=${res.data.token}`
                        setisLoggedIn(true)
                        setuserId(credential.login)
                        if (res.data.message.trim().length > 0 ) {
                            utils.showError(res.data.message)
                        }
                        history.push("/")
                    } else {
                        setisLoggedIn(false)
                        setuserId(credential.login)
                        if (res.data.message.trim().length > 0 ) {
                            utils.showError(res.data.message)
                        }
                        history.push("/UserchgPasswd3")
                    }
                } else {
                    //console.log(res.data)
                    utils.showError(res.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const handleLogout = () => {
        (async () => {
            try {
                const res = await gsb.post('/logout',
                {
                    cIPAddr: IPAddr,
                });
            } catch (error) {
                utils.showError(error)
            }
        })()

        gsb.defaults.headers = {}
        localStorage.removeItem('token')
        document.cookie = `token_${hostId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        setisLoggedIn(false)
    }

/*    
    const token = localStorage.getItem('token')
    let initIsLoggedIn = false
    if (token) {
        gsb.defaults.headers = {
            'Authorization': 'Bearer ' + token
        }
        document.cookie = `token=${token}`
        initIsLoggedIn = true
    }
*/

    let initIsLoggedIn = false

    useEffect(() => {
        //console.log("useEffect []");
        const token = localStorage.getItem('token')
        if (token) {
            /*
            svc.defaults.headers = {
                'Authorization': 'Bearer ' + token
            }
            */
            gsb.defaults.headers["Authorization"] = 'Bearer ' + token
            document.cookie = `token_${hostId}=${token}`
            setisLoggedIn(true);
            initIsLoggedIn = true
        }
    }, [])


    const [isLoggedIn, setisLoggedIn] = useState(initIsLoggedIn)
    const [showStatus, setShowStatus] = useState(false)

    const [PgmLists, setPgmLists] = useState([] as PgmAccessList[])
    const [RecordPgmName, setRecordPgmName] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [GSBx_USER, setGSBx_USER] = useState(false)
    const [GSBce_USER, setGSBce_USER] = useState(true)
    const [HKIDR_USER, setHKIDR_USER] = useState(false)
    const [ATI_USER, setATI_USER] = useState(false)

    const [CM2K_USER, setCM2K_USER] = useState(false)
    const { t, i18n } = useTranslation();    

    const [stmtEncode, setstmtEncode] = useState("N")
 
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag0');
                //console.log("App disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("App disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setGSBx_USER(GsbFlag.GSBx_USER) ;
                setGSBce_USER(GsbFlag.GSBce_USER) ;
                setHKIDR_USER(GsbFlag.HKIDR_USER) ;
                setCM2K_USER(GsbFlag.CM2K_USER);
                setATI_USER(GsbFlag.ATI_USER);
                setstmtEncode(GsbFlag.stmtEncode);
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/GsbFlag');
                    setPgmLists(res.data.UserPgmListData)

                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, isLoggedIn])


    useEffect(() => {
        //console.log("App RecordPgmName ", RecordPgmName)
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: "",
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])


    function CheckPgmAccess(PgmName: string): boolean{
        var canAccess = false;
        //console.log("App CheckPgmAccess PgmLists", PgmName, PgmLists)
        if ( GSBce_USER || HKIDR_USER ) {
            canAccess = true;
        } else {
            if ( isLoggedIn && PgmLists.length > 0 ) {
                for (let i = 0; i < PgmLists.length; i++) {
                    //console.log("App CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
                    if (PgmLists[i].d_PgmCode === PgmName ) {
                        //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
                        if (PgmLists[i].d_PgmAccess === "Y" ) {
                            canAccess = true;
                        }
                        break ;
                    }
                }
            }
        }
        return canAccess;
    }
    


    return (
        <React.Suspense fallback={<Spinner></Spinner>}>
        <div id="app" className="App">
            <Router>
                { HKIDR_USER && <MenuBar_HKIDR isLoggedIn={isLoggedIn} /> }
                { GSBce_USER && GSBx_USER && ! HKIDR_USER && <MenuBar isLoggedIn={isLoggedIn} /> }
                { GSBce_USER && ! GSBx_USER && ! HKIDR_USER && <MenuBar_ce isLoggedIn={isLoggedIn} /> }
                { ! GSBce_USER && GSBx_USER && ! HKIDR_USER && <MenuBar isLoggedIn={isLoggedIn} onSystemStatusClick={() => setShowStatus(!showStatus)} /> }
                <div className="AppBody">
                    <Switch>
                        { HKIDR_USER && <Route exact path="/">
                            {isLoggedIn ? <SideMenu_HKIDR isLoggedIn={isLoggedIn} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        }
                        { GSBce_USER && GSBx_USER && ! HKIDR_USER && <Route exact path="/">
                            {isLoggedIn ? <SideMenu isLoggedIn={isLoggedIn} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        }
                        { GSBce_USER && ! GSBx_USER && ! HKIDR_USER && <Route exact path="/">
                            {isLoggedIn ? <SideMenu_ce isLoggedIn={isLoggedIn} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        }
                        { ! GSBce_USER && GSBx_USER && ! HKIDR_USER && <Route exact path="/">
                            {isLoggedIn ? <SideMenu isLoggedIn={isLoggedIn} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        }
                        <Route exact path="/login">
                            <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
                        </Route>
                        <Route path="/accounts/:acCode?">
                            {isLoggedIn && CheckPgmAccess("M0001") ? <ClMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/accounts_DocSet/:acCode?">
                            {isLoggedIn && CheckPgmAccess("M0033") ? <ClDocSetMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/ClACList">
                            {isLoggedIn && CheckPgmAccess("M0002") ? <ClACListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClACListSfc">
                            {isLoggedIn && CheckPgmAccess("M0003") ? <ClACListSfcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClLog">
                            {isLoggedIn && CheckPgmAccess("M0004") ? <ClLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ImportClMast2">
                            {isLoggedIn && CheckPgmAccess("M0023") ? <ImportClMast2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230814 */}
                        <Route path="/ImportClMast3">
                            {isLoggedIn && CheckPgmAccess("M0026") ? <ImportClMast3Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/ImportClMast1">
                            {isLoggedIn && CheckPgmAccess("M0034") ? <ImportClMast1Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/SmMast">
                            {isLoggedIn && CheckPgmAccess("M0007") ? <SmMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SmList">
                            {isLoggedIn && CheckPgmAccess("M0022") ? <SmListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SmLog">
                            {isLoggedIn && CheckPgmAccess("M0008") ? <SmLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230814 */}
                        <Route path="/ImportStkMast">
                            {isLoggedIn && CheckPgmAccess("M0027") ? <ImportStkMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230828 */}
                        <Route path="/ImportStkMast2">
                            {isLoggedIn && CheckPgmAccess("M0028") ? <ImportStkMast2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230829 */}
                        <Route path="/ImportStkMastNil">
                            {isLoggedIn && CheckPgmAccess("M0029") ? <ImportStkMastNilMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ImportStkMastMarginRatio">
                            {isLoggedIn && CheckPgmAccess("M0030") ? <ImportStkMastMarginRatioMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ImportStkMastGroup">
                            {isLoggedIn && CheckPgmAccess("M0031") ? <ImportStkMastGroupMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BkMast">
                            {isLoggedIn && CheckPgmAccess("M0005") ? <BkMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BkLog">
                            {isLoggedIn && CheckPgmAccess("M0006") ? <BkLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/HolidayMast">
                            {isLoggedIn && CheckPgmAccess("M0032") ? <HolidayMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/HolidayMastList">
                            {isLoggedIn && CheckPgmAccess("M0032") ? <HolidayMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>


                        <Route path="/IntRMast">
                            {isLoggedIn && CheckPgmAccess("M0009") ? <IntRMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/IntRMastList">
                            {isLoggedIn && CheckPgmAccess("M0018") ? <IntRMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/IntRLog">
                            {isLoggedIn && CheckPgmAccess("M0010") ? <IntRLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PrimeRateMast">
                            {isLoggedIn && CheckPgmAccess("M0009") ? <PrimeRateMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CommMast">
                            {isLoggedIn && CheckPgmAccess("M0011") ? <CommMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CommLog">
                            {isLoggedIn && CheckPgmAccess("M0012") ? <CommLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MktMast">
                            {isLoggedIn && CheckPgmAccess("M0013") ? <MktMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MktLog">
                            {isLoggedIn && CheckPgmAccess("M0014") ? <MktLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MktFeeMast">
                            {isLoggedIn && CheckPgmAccess("M0015") ? <MktFeeMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MktFeeLog">
                            {isLoggedIn && CheckPgmAccess("M0016") ? <MktFeeLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MultiBankMast">
                            {isLoggedIn && CheckPgmAccess("M0019") ? <MultiBankMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MultiBankMastList">
                            {isLoggedIn && CheckPgmAccess("M0020") ? <MultiBankMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {<Route path="/MultiBankLog">
                            {isLoggedIn && CheckPgmAccess("M0021") ? <MultiBankLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>}
                        <Route path="/ExchangeRate">
                            {isLoggedIn && CheckPgmAccess("M0024") ? <ExchangeRateMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ExchangeRateList">
                            {isLoggedIn && CheckPgmAccess("M0025") ? <ExchangeRateListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DayStartProc">
                            {isLoggedIn && CheckPgmAccess("T0018") ? <DayStartProcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranEntry">
                            {isLoggedIn && CheckPgmAccess("T0001") ? <ClientTranEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranCheckList">
                            {isLoggedIn && CheckPgmAccess("T0002") ? <ClientTranCheckListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranLog">
                            {isLoggedIn && CheckPgmAccess("T0003") ? <ClientTranLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230823 */}
                        <Route path="/ClientInterestAccrued">
                            {isLoggedIn && CheckPgmAccess("T0001") ? <ClientInterestAccruedMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230823 */}
                        <Route path="/ClientInterestAccruedRpt">
                            {isLoggedIn && CheckPgmAccess("T0001") ? <ClientInterestAccruedRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranClear">
                            {isLoggedIn && CheckPgmAccess("T0001") ? <ClientTranClearMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BrokerTranEntry">
                            {isLoggedIn && CheckPgmAccess("T0004") ? <BrokerTranEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BrokerTranCheckList">
                            {isLoggedIn && CheckPgmAccess("T0005") ? <BrokerTranCheckListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BrokerTranLog">
                            {isLoggedIn && CheckPgmAccess("T0006") ? <BrokerTranLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* 20230823 */}
                        <Route path="/BrokerInterestAccrued">
                            {isLoggedIn && CheckPgmAccess("T0004") ? <BrokerInterestAccruedMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BrokerInterestAccruedRpt">
                            {isLoggedIn && CheckPgmAccess("T0004") ? <BrokerInterestAccruedRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BrokerTranClear">
                            {isLoggedIn && CheckPgmAccess("T0004") ? <BrokerTranClearMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PayRecTranEntry">
                            {isLoggedIn && CheckPgmAccess("T0007") ? <PayRecTranEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PayRecTranCheckList">
                            {isLoggedIn && CheckPgmAccess("T0008") ? <PayRecTranCheckListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PayRecCheq">
                            {isLoggedIn && CheckPgmAccess("T0024") ? <PayRecCheqMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PayRecTranLog">
                            {isLoggedIn && CheckPgmAccess("T0009") ? <PayRecTranLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PayRecTranClear">
                            {isLoggedIn && CheckPgmAccess("T0007") ? <PayRecTranClearMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkDepWithTranEntry">
                            {isLoggedIn && CheckPgmAccess("T0010") ? <StkDepWithTranEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/*
                        <Route path="/ShareTransGenEr">
                            {isLoggedIn && CheckPgmAccess("T0023") ? <ShareTransGenErMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        */}
                        <Route path="/StkDepWithTranCheckList">
                            {isLoggedIn && CheckPgmAccess("T0011") ? <StkDepWithTranCheckListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkDepWithTranLog">
                            {isLoggedIn && CheckPgmAccess("T0012") ? <StkDepWithTranLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkDepWithClear">
                            {isLoggedIn && CheckPgmAccess("T0010") ? <StkDepWithClearMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkGlobalWithdrawal">
                            {isLoggedIn && CheckPgmAccess("T0013") ? <StkGlobalWithdrawalMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranSettlementEntry">
                            {isLoggedIn && CheckPgmAccess("T0017") ? <ClientTranSettlementEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranSettlementCheckList">
                            {isLoggedIn && CheckPgmAccess("T0020") ? <ClientTranSettlementCheckListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientTranSettlementLog">
                            {isLoggedIn && CheckPgmAccess("T0021") ? <ClientTranSettlementLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Matching">
                            {isLoggedIn && CheckPgmAccess("T0014") ? <MatchingMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MatchingReport">
                             {isLoggedIn && CheckPgmAccess("T0014") ? <MatchingReportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CancelPrevTrade">
                             {isLoggedIn && CheckPgmAccess("T0022") ? <CancelPrevTradeMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CorrectTrade">
                             {isLoggedIn && CheckPgmAccess("T0022") ? <CorrectTradeMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CancelCorrectTradeRegister">
                             {isLoggedIn && CheckPgmAccess("T0022") ? <CancelCorrectTradeRegisterMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CancelCorrectTradeLog">
                             {isLoggedIn && CheckPgmAccess("T0022") ? <CancelCorrectTradeLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StockAllocationEntry">
                            {isLoggedIn && CheckPgmAccess("T0019") ? <StockAllocationEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StockAllocationStatusRpt">
                            {isLoggedIn && CheckPgmAccess("T0019") ? <StockAllocationRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/Sas_Rpt1">
                            {isLoggedIn && CheckPgmAccess("SA001") ? <Sas_Rpt1Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Sas_Rpt2">
                            {isLoggedIn && CheckPgmAccess("SA002") ? <Sas_Rpt2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Sas_Rpt3">
                            {isLoggedIn && CheckPgmAccess("SA003") ? <Sas_Rpt3Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Sas_Rpt4">
                            {isLoggedIn && CheckPgmAccess("SA004") ? <Sas_Rpt4Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Sas_Rpt5">
                            {isLoggedIn && CheckPgmAccess("SA005") ? <Sas_Rpt5Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/Ati_Rpt1">
                            {isLoggedIn && CheckPgmAccess("AT001") ? <Ati_Rpt1Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Ati_Rpt2">
                            {isLoggedIn && CheckPgmAccess("AT002") ? <Ati_Rpt2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Ati_Rpt3">
                            {isLoggedIn && CheckPgmAccess("AT003") ? <Ati_Rpt3Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Ati_Rpt4">
                            {isLoggedIn && CheckPgmAccess("AT004") ? <Ati_Rpt4Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/Eps_Rpt1">
                            {isLoggedIn && CheckPgmAccess("EP001") ? <Eps_Rpt1Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Eps_Rpt2">
                            {isLoggedIn && CheckPgmAccess("EP002") ? <Eps_Rpt2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/import">
                            {isLoggedIn && CheckPgmAccess("T0015") ? <ImportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/PreDayEndProc">
                            {isLoggedIn && CheckPgmAccess("T0016") ? <PreDayEndProcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Cheque2Client">
                            {isLoggedIn && CheckPgmAccess("T0016") ? <Cheque2ClientMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PrintClientChqRegister">
                            {isLoggedIn && CheckPgmAccess("T0016") ? <PrintClientChqRegisterMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DayEndProc">
                            {isLoggedIn && CheckPgmAccess("T0016") ? <DayEndProcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DayEndProc2">
                            {isLoggedIn && CheckPgmAccess("T0016") ? <DayEndProc2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        
                        <Route path="/StkOverdrawnRpt">
                            {isLoggedIn && CheckPgmAccess("R0001") ? <StkOverdrawnRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkHoldList">
                            {isLoggedIn && CheckPgmAccess("R0002") ? <StkHoldListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkHoldListSfc">
                            {isLoggedIn && CheckPgmAccess("R0020") ? <StkHoldListSfcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkHoldListSfc2">
                            {isLoggedIn && CheckPgmAccess("R0020") ? <StkHoldListSfc2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkHoldXls">
                            {isLoggedIn && CheckPgmAccess("R0036") ? <StkHoldXlsMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StkHaircutXls">
                            {isLoggedIn && CheckPgmAccess("R0037") ? <StkHaircutXlsMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PortfolioEvaluation">
                            {isLoggedIn && CheckPgmAccess("R0003") ? <PortfolioEvaluationMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/PortfolioEvaluation2">
                            {isLoggedIn && CheckPgmAccess("R0015") ? <PortfolioEvaluation2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/AcBalList">
                            {isLoggedIn && CheckPgmAccess("R0004") ? <AcBalListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClACBalXls">
                            {isLoggedIn && CheckPgmAccess("R0034") ? <ClACBalXlsMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MgnPosition">
                            {isLoggedIn && CheckPgmAccess("R0005") ? <MgnPositionMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/TopmostBalanceList">
                            {isLoggedIn && CheckPgmAccess("R0032") ? <TopmostBalanceListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ApprovedStockAssetsReport">
                            {isLoggedIn && CheckPgmAccess("R0033") ? <ApprovedStockAssetsReportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/RollingBalanceReport">
                            {isLoggedIn && CheckPgmAccess("R0035") ? <RollingBalanceReportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UnsettleTranAcceptValue">
                            {isLoggedIn && CheckPgmAccess("R0039") ? <UnsettleTranAcceptValueMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClHistory">
                            {isLoggedIn && CheckPgmAccess("R0006") ? <ClHistoryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClHistory2">
                            {isLoggedIn && CheckPgmAccess("R0006") ? <ClHistory2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClHistorySfc">
                            {isLoggedIn && CheckPgmAccess("R0006") ? <ClHistorySfcMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MthRebateRpt">
                            {isLoggedIn && CheckPgmAccess("R0007") ? <MthRebateRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MthContractStmpRpt">
                            {isLoggedIn && CheckPgmAccess("R0011") ? <MthContractStmpRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MthStmpReturnRpt">
                            {isLoggedIn && CheckPgmAccess("R0011") ? <MthStmpReturnRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>  
                        <Route path="/MthInterestRpt">
                            {isLoggedIn && CheckPgmAccess("R0012") ? <MthInterestRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        
                        </Route>
                        <Route path="/MthLevyCCassFeeRpt">
                            {isLoggedIn && CheckPgmAccess("R0013") ? <MthLevyCCassFeeRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MthSummaryRpt">
                            {isLoggedIn && CheckPgmAccess("R0014") ? <MthSummaryRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClientPosEnquiry">
                            {isLoggedIn && CheckPgmAccess("R0016") ? <ClientPosEnquiryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MthCCassCustodyFeeRpt">
                            {isLoggedIn && CheckPgmAccess("R0019") ? <MthCCassCustodyFeeRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>   
						
                        <Route path="/ClientTurnOverRpt">
                            {isLoggedIn && CheckPgmAccess("R0026") ? <ClientTurnOverRptMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route> 
					

                        <Route path="/AccInfo">
                            {isLoggedIn && CheckPgmAccess("R0003") ? <AccInfoMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Dstatement">
                            {isLoggedIn && CheckPgmAccess("R0009") ? <DstatementMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                       
                        <Route path="/Dv1Stmt">
                            {isLoggedIn && CheckPgmAccess("R0021") ? <DviewMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>    
                       
                        <Route path="/Dv2Stmt">
                            {isLoggedIn && CheckPgmAccess("R0022") ? <Dview2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>   

                        <Route path="/DvTransDate">
                            {isLoggedIn && CheckPgmAccess("R0023") ? <DviewTransDateMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>     

                        <Route path="/DvsDowntatement">
                            {isLoggedIn && CheckPgmAccess("R0024") ? <DviewDownMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>    
                      
                        <Route path="/DvStkHolderList">
                            {isLoggedIn && CheckPgmAccess("R0027") ? <DvStkHolderListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>   
                        { 
                        <Route path="/DvStkHolderList2">
                            {isLoggedIn && CheckPgmAccess("R0028") ? <DvStkHolderList2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>    
                        }

                        <Route path="/DvDividend">
                            {isLoggedIn && CheckPgmAccess("R0029") ? <DvDividendMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>             

                        <Route path="/DvStkAllocStatus">
                            {isLoggedIn && CheckPgmAccess("R0030") ? <DvStkAllocStatusMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>    

                        {/* 
                        <Route path="/DviewOut">
                            {isLoggedIn && CheckPgmAccess("R0010") ? <DviewOutMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>                                            
                        */}
                        <Route path="/Mstatement">
                            {isLoggedIn && CheckPgmAccess("R0010") ? <MstatementMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Mstatement2">
                            {isLoggedIn && CheckPgmAccess("R0031") ? <Mstatement2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/ExportWebStmt">
                            {isLoggedIn && CheckPgmAccess("R0025") ? <ExportWebStmt1Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/SentStatement">
                            {isLoggedIn && CheckPgmAccess("R0017") ? <SentStatementMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StockAnnouncementEntry">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <StockAnnouncementEntryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/StockAnnouncementLog">
                            {isLoggedIn && CheckPgmAccess("D0002") ? <StockAnnouncementLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BookCloseReminder">
                            {isLoggedIn && CheckPgmAccess("D0003") ? <BookCloseReminderMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/MktDividendCharge">
                            {isLoggedIn && CheckPgmAccess("D0004") ? <MktDividendChargeMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DividendProcess">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <DividendProcessMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DividendProcess02">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <DividendProcess02Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DividendProcess03">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <DividendProcess03Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/DividendProcess04">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <DividendProcess04Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/WarrantRightsProcess">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <WarrantRightsProcessMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/WarrantRightsProcess02">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <WarrantRightsProcess02Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/WarrantRightsProcess03">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <WarrantRightsProcess03Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BookClosedQty">
                            {isLoggedIn && CheckPgmAccess("D0001") ? <BookClosedQtyMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/InputAnnouncement">
                            {isLoggedIn && CheckPgmAccess("D0005") ? <InputAnnouncementMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/SBReindex">
                            {isLoggedIn && CheckPgmAccess("S0003") ? <SBReindexMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Reindex">
                            {isLoggedIn && CheckPgmAccess("S0003") ? <SBReindexMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ReUpdateTrn">
                            {isLoggedIn && CheckPgmAccess("S0012") ? <ReUpdateTrnMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBackup">
                            {isLoggedIn && CheckPgmAccess("S0004") ? <SBBackupMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatchMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch2">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatch2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch3">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatch3Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch4">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatch4Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch10">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatch10Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBBatch5">
                            {isLoggedIn && CheckPgmAccess("R0008") ? <SBBatch5Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/LoginLog">
                            {isLoggedIn && CheckPgmAccess("S0005") ? <LoginLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/Resetprocess">
                            {isLoggedIn && CheckPgmAccess("S0010") ? <ResetprocessMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBstatus">
                            {isLoggedIn && CheckPgmAccess("S0006") ? <SBstatusMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/SBModule">
                            {isLoggedIn ? <SBModuleMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMast">
                            {isLoggedIn && CheckPgmAccess("S0007") ? <UserMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMastList">
                            {isLoggedIn && CheckPgmAccess("S0007") ? <UserMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserMastLog">
                            {isLoggedIn && CheckPgmAccess("S0007") ? <UserMastLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserchgPasswd">
                            {isLoggedIn ? <UserchgPasswdMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserchgPasswd2">
                            {isLoggedIn && CheckPgmAccess("S0007") ? <UserchgPasswd2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/* <Route path="/UserchgPasswd3">
                            <UserchgPasswd3Main gsb={gsb} />
                        </Route> */}
                        <Route path="/UserAccessGroup">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <UserAccessGroupMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserPgmAccess">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <UserPgmAccessMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserPgmAccessList">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <UserPgmAccessListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/UserPgmAccessLog">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <UserPgmAccessLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GroupAccess">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <GroupAccessMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GroupAccessList">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <GroupAccessListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GroupAccessLog">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <GroupAccessLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GroupAccess02">
                            {isLoggedIn && CheckPgmAccess("S0008") ? <GroupAccess02Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ReqAuthList">
                            {isLoggedIn && CheckPgmAccess("M0017") ? <ReqAuthListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/About">
                            {isLoggedIn && CheckPgmAccess("S0009") ? <AboutMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/TempChgField">
                            {isLoggedIn && CheckPgmAccess("S0011") ? <TempChgFieldMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrImpAccount">
                            {isLoggedIn && CheckPgmAccess("H0001") ? <HkidrImpAccountMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrMast">
                            {isLoggedIn && CheckPgmAccess("H0002") ? <HkidrMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/HkCrsImpAccount">
                            {isLoggedIn && CheckPgmAccess("H0013") ? <HkCrsImpAccountMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route> 
                    
                        <Route path="/HkCrsDataImpAccount">
                            {isLoggedIn && CheckPgmAccess("H0014") ? <HkCrsDataImpAccountMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                       
                        <Route path="/HkCrsMast">
                            {isLoggedIn && CheckPgmAccess("H0015") ? <HkCrsMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkCrsMastList">
                            {isLoggedIn && CheckPgmAccess("H0016") ? <HkCrsMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                     
                        <Route path="/HkCrsLog">
                            {isLoggedIn && CheckPgmAccess("H0017") ? <HkCRSLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                     
                         
                        <Route path="/HkCrsDataMast">
                            {isLoggedIn && CheckPgmAccess("H0018") ? <HkCrsDataMastMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                                        
                        <Route path="/HkCrsExport">
                            {isLoggedIn && CheckPgmAccess("H0019") ? <HkCrsExportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
					
                        <Route path="/HkCrsEnquiry">
                            {isLoggedIn && CheckPgmAccess("H0021") ? <HkCrsEnquiryMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/HkidrMastList">
                            {isLoggedIn && CheckPgmAccess("H0003") ? <HkidrMastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrLog">
                            {isLoggedIn && CheckPgmAccess("H0004") ? <HkidrLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GsbNameChangeLog">
                            {isLoggedIn && CheckPgmAccess("H0005") ? <GsbNameChangeLogMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrHkConsent">
                            {isLoggedIn && CheckPgmAccess("H0008") ? <HkidrHkConsentMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CNBcanGen">
                            {isLoggedIn && CheckPgmAccess("H0007") ? <CNBcanGenMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>                        
                        <Route path="/HkidrBcanMapping">
                            {isLoggedIn && CheckPgmAccess("H0010") ? <HkidrBcanMappingMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrResponseFile">
                            {isLoggedIn && CheckPgmAccess("H0010") ? <HkidrResponseFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/CNBcanMapping">
                            {isLoggedIn && CheckPgmAccess("H0022") ? <CNBcanMappingMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CNBcanResponseFile">
                            {isLoggedIn && CheckPgmAccess("H0023") ? <CNBcanResponseFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CNBcanAurpResponseFile">
                            {isLoggedIn && CheckPgmAccess("H0023") ? <CNBcanAurpResponseFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CNBcanRsltResultFile">
                            {isLoggedIn && CheckPgmAccess("H0023") ? <CNBcanRsltResultFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route> 

                        <Route path="/CNBcanFimgFile">
                            {isLoggedIn && CheckPgmAccess("H0023") ? <CNBcanFimgFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/importHkconsentCSV">
                            {isLoggedIn && CheckPgmAccess("H0006") ? <ImportHkConsentCsvMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>

                        <Route path="/ExportHkConsent">
                            {isLoggedIn && CheckPgmAccess("H0009") ? <ExportHkConsentMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        
                        <Route path="/HkidrOtcr">
                            {isLoggedIn && CheckPgmAccess("H0011") ? <HkidrOtcrMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
						
                        <Route path="/HkidrOtcr2">
                            {isLoggedIn && CheckPgmAccess("H0011") ? <HkidrOtcr2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
						
                         <Route path="/ExportEIPO">
                            {isLoggedIn && CheckPgmAccess("H0012") ? <ExportEIPOMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>                        
                      
                        <Route path="/EIPOAllotmentResultFile">
                            {isLoggedIn && CheckPgmAccess("H0012") ? <IPOMastImportMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>       

                        <Route path="/EIPOAuthorisedFile">
                            {isLoggedIn && CheckPgmAccess("H0012") ? <EIPOAuthorisedMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>  
   

                        <Route path="/HkidrProcessResultFile">
                            {isLoggedIn && CheckPgmAccess("H0010") ? <HkidrProcessResultFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrFullImageFile">
                            {isLoggedIn && CheckPgmAccess("H0010") ? <HkidrFullImageFileMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        
                        <Route path="/HkidrETTradeList">
                            {isLoggedIn ? <HkidrETTradeListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidreBrokerList">
                            {isLoggedIn ? <HkidreBrokerListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrAyersList">
                            {isLoggedIn ? <HkidrAyersListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/HkidrInfocastList">
                            {isLoggedIn ? <HkidrInfocastListMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/GsbxConfig">
                            {isLoggedIn && CheckPgmAccess("S0001") ? <GsbxConfigMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/BcanConfig">
                            {isLoggedIn && CheckPgmAccess("S0002") ? <BcanConfigMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/CrsConfig">
                            {isLoggedIn && CheckPgmAccess("S0013") ? <CrsConfigMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/tasks">
                            {isLoggedIn ? <TaskMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/WIP">
                            {isLoggedIn ? <WIPMain gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/ClMast2">
                            {isLoggedIn ? <ClMast2Main gsb={gsb} /> : <Login gsb={gsb} isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                    </Switch>
                    {isLoggedIn && showStatus && <Draggable><Card elevation={Elevation.TWO} style={{width: "240px", position: "fixed", top: "96px", right: "24px"}}><SBstatusMini gsb={gsb} /></Card></Draggable>}
                </div>
            </Router>
        </div>
        </React.Suspense>
    );
}


function SideMenu({ isLoggedIn = false } : MenuBar_HKIDRProps) {
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    
    const [PgmLists, setPgmLists] = useState([] as PgmAccessList[])
    const [RecordPgmName, setRecordPgmName] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")
    const [userId, setuserId] = useState("")
    const [showHKIDR1, setshowHKIDR1] = useState(false)
    const [CM2K_USER, setCM2K_USER] = useState(false)
    const [GSBXHKCRS, setGSBXHKCRS] = useState(false)
    const [CNBCAN, setCNBCAN] = useState(false)
    const { t, i18n } = useTranslation();    

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SideMenu disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SideMenu disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                //console.log("SideMenu companyLogoName: ", GsbFlag.companyLogoName)
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key)
                setuserId( GsbFlag.UserID)
                setshowHKIDR1(GsbFlag.HKIDR_USER1)
                setGSBXHKCRS(GsbFlag.GSBXHKCRS)
                setCNBCAN (GsbFlag.CNBCAN)
                setCM2K_USER( GsbFlag.CM2K_USER);
                
                setPgmLists(res.data.UserPgmListData)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        //console.log("sidemenu RecordPgmName ", RecordPgmName)
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: "",
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])


    function CheckPgmAccess(PgmName: string): boolean{
        var canAccess = false;

        for (let i = 0; i < PgmLists.length; i++) {
            //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
            if (PgmLists[i].d_PgmCode === PgmName ) {
                //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
                if (PgmLists[i].d_PgmAccess === "Y" ) {
                    canAccess = true;
                }
                break ;
            }
        }
        return canAccess;
    }


    /*
    let CoHead = <>
        <span id="DispCoName"> {coLogo} <b> { CO_NAME } </b> </span>
        <span id="DispTrnDate">Tran Date: {TRAN_DATE} </span>
        <div className="Spacing-V-8" />
        <span id="DispCoName2">Curr Path: {SBPath}</span>
        <span id="DispTrnDate">Setl Date: {SETL_DATE} </span>
    </>
    */
   
    //let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <div className="Row">
                {/* <Link to="/accounts">
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="Account" />
                        <div>{t('Accounts')}</div>
                    </div>
                </Link> */}
                { CheckPgmAccess("M0001") && <a href="#/accounts" onClick={() => { setRecordPgmName("21 ClMast") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="Client Master" />
                        <div>{t('Accounts')}</div>
                    </div>
                </a> }
                { CheckPgmAccess("M0007") && <a href="#/SmMast" onClick={() => { setRecordPgmName("23 SmMast") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFileBox} alt="Stock Master" />
                        <div>{t('Stock Master')}</div>
                    </div>
                </a> }
                { CheckPgmAccess("M0009") && <a href="#/IntRMast" onClick={() => { setRecordPgmName("11 IntRMast") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFileBox} alt="Interest Master" />
                        <div>{t('Interest Master')}</div>
                    </div>
                </a> }
                { CheckPgmAccess("M0011") && <a href="#/CommMast" onClick={() => { setRecordPgmName("12 CommMastt") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFileBox} alt="Commission Master" />
                        <div>{t('Commission Master')}</div>
                    </div>
                </a> }

                {CheckPgmAccess("M0032") &&  <a href="#/HolidayMast" onClick={() => { setRecordPgmName("HolidayMast") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFileBox} alt="Holiday Master" />
                        <div>{t('Holiday Master')}</div>
                    </div>
                </a> }


                { CheckPgmAccess("D0001") && <a href="#/StockAnnouncementEntry" onClick={() => { setRecordPgmName("91 StockAnnouncementEntry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFileBox} alt="91 Stock Announcement Entry" />
                        <div>Stock Announcement Entry</div>
                    </div>
                </a> }
            </div>


            <div className="Spacing-V-16"></div>

            <div className="Row">
                { CheckPgmAccess("T0001") && <a href="#/ClientTranEntry" onClick={() => { setRecordPgmName("31 ClientTranEntry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgProfit} alt="31 ClientTranEntry" />
                        <div>Client Transaction Entry</div>
                    </div>
                </a> }
                { CheckPgmAccess("T0004") && <a href="#/BrokerTranEntry" onClick={() => { setRecordPgmName("32 BrokerTranEntry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="32 BrokerTranEntry" />
                        <div>Broker Transaction Entry</div>
                    </div>
                </a> }
                { CheckPgmAccess("T0007") && <a href="#/PayRecTranEntry" onClick={() => { setRecordPgmName("33 PayRecTranEntry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgMoney} alt="33 PayRecTranEntry" />
                        <div>Payment / Receipt Transaction Entry</div>
                    </div>
                </a> }
                { CheckPgmAccess("T0010") && <a href="#/StkDepWithTranEntry" onClick={() => { setRecordPgmName("34 StkDepWithTranEntry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgProfit} alt="34 StkDepWithTranEntry" />
                        <div>Stock Deposit/ Withdrawal Transaction Entry</div>
                    </div>
                </a> }

                {/*showHKIDR1 && CheckPgmAccess("T0023") && <a href="#/ShareTransGenEr" onClick={() => { setRecordPgmName("ShareTransGenEr") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgProfit} alt="ShareTransGenEr" />
                        <div>Gen Er ref in share transfer</div>
                    </div>
                </a>*/}
                
                { CheckPgmAccess("T0017") && <a href="#/ClientTranSettlementEntry" onClick={() => { setRecordPgmName("351 Settlement Entry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgMoney} alt="351 Settlement Entry" />
                        <div>Client Transaction Settlement Entry</div>
                    </div>
                </a> }
                { CheckPgmAccess("T0014") && <a href="#/Matching" onClick={() => { setRecordPgmName("36 Start Matching") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="36 Start Matching" />
                        <div>Start Matching</div>
                    </div>
                </a> }
                {/* 20230330 */}
                { CheckPgmAccess("T0019") && <a href="#/StockAllocationEntry" onClick={() => { setRecordPgmName("791 Stock Allocation Entry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgMoney} alt="791 Stock Allocation Entry" />
                        <div>Stock Allocation Entry</div>
                    </div>
                </a> }
                { CheckPgmAccess("T0015") && <a href="#/import" onClick={() => { setRecordPgmName("Import files") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgImport} alt="Import" />
                        <div>Import files</div>
                    </div>
                </a> }
            </div>

            <div className="Row">
                { CheckPgmAccess("R0009") && <a href="#/Dstatement" onClick={() => { setRecordPgmName("542 Daily Statement") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFinancialreport} alt="542 Daily Statement" />
                        <div>Daily Statement</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0002") && <a href="#/StkHoldList" onClick={() => { setRecordPgmName("74 StkHoldList") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="74 StkHoldList" />
                        <div>Stock Holder List</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0003") && <a href="#/PortfolioEvaluation" onClick={() => { setRecordPgmName("751 Portfolio Evaluation") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="751 Portfolio Evaluation" />
                        <div>Portfolio Evaluation</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0015") && <a href="#/PortfolioEvaluation2" onClick={() => { setRecordPgmName("752 Stock Asset Evaluation") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="752 Stock Asset Evaluation" />
                        <div>Stock Asset Evaluation</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0016") && <a href="#/ClientPosEnquiry" onClick={() => { setRecordPgmName("754 Client Position Enquiry") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="754 Client Position Enquiry" />
                        <div>Client Position Enquiry</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0004") && (GsbFlag.user_key != "WINGHANG") && <a href="#/AcBalList" onClick={() => { setRecordPgmName("25B AcBalList") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="25B Ac Bal List" />
                        <div>Account Balance List</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0010") && <a href="#/Mstatement" onClick={() => { setRecordPgmName("831 Monthly Statement") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFinancialreport} alt="831 Monthly Statement" />
                        <div>Monthly Statement</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0021") && <a href="#/Dv1Stmt" onClick={() => { setRecordPgmName("DVIEW Statement") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFinancialreport} alt="DVIEW Statement" />
                        <div>DVIEW Statement (show Address)</div>
                    </div>
                </a> }
                { CheckPgmAccess("R0022") && <a href="#/Dv2Stmt" onClick={() => { setRecordPgmName("DVIEW Statement (NO Address)") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgFinancialreport} alt="DVIEW Statement (NO Address)" />
                        <div>DVIEW Statement (NO Address)</div>
                    </div>
                </a> }
            </div>

            { showHKIDR1 && <div className="Row">
                { CheckPgmAccess("H0001") && <a href="#/HkidrImpAccount" onClick={() => { setRecordPgmName("Import Client from GSB (New Client)") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="Import Client from GSB (New Client)" />
                        <div>Import Client from GSB (New Client)</div>
                    </div>
                </a> }
                { CheckPgmAccess("H0002") && <a href="#/HkidrMast" onClick={() => { setRecordPgmName("HKIDR Master") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgAccount} alt="HKIDR Master" />
                        <div>{t('HKIDR Master')}</div>
                    </div>
                </a> }
           
                { CheckPgmAccess("H0010") && <a href="#/HkidrBcanMapping" onClick={() => { setRecordPgmName("Export HKBCAN Mapping File") }}>
                    <div className="MenuTile" >
                        <img className="MenuTileImage" src={svgDownload} alt="HKBCAN-CID Mapping File" />
                        <div>Export HKBCAN-CID Mapping File</div>
                    </div>
                </a> }
                { CheckPgmAccess("H0010") && <a href="#/HkidrResponseFile" onClick={() => { setRecordPgmName("Read HKBCAN Response File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID Response File" />
                        <div>Read HKBCAN-CID Response File</div>
                    </div>
                </a> }
                { CheckPgmAccess("H0010") && <a href="#/HkidrProcessResultFile" onClick={() => { setRecordPgmName("Read HKBCAN Result File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID Process Result File" />
                        <div>Read HKBCAN-CID Process Result File</div>
                    </div>
                </a> }
                { CheckPgmAccess("H0010") && <a href="#/HkidrFullImageFile" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID BCAN Full Image Reconcile File" />
                        <div>Read HKBCAN-CID BCAN Full Image Reconcile File</div>
                    </div>
                </a> }


                { CheckPgmAccess("H0011") && <a href="#/HkidrOtcr" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W)") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="Export OTCR Report File (D/W)" />
                        <div>Export OTCR Report File (D/W)</div>
                    </div>
                </a> }
               
			    { CheckPgmAccess("H0011") && <a href="#/HkidrOtcr2" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W) in History") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="Export OTCR Report File (D/W) in History" />
                        <div>Export OTCR Report File (D/W) in History</div>
                    </div>
                </a> }

                { CM2K_USER && CheckPgmAccess("H0012") && (GsbFlag.user_key != "WINGHANG") && <a href="#/ExportEIPO" onClick={() => { setRecordPgmName("Export Fini EIPO bulk upload file") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="IPO Mast" />
                        <div> Export EIPO Bulk list</div>
                    </div>
                </a> }

            </div> }

        </>
    )
}


function SideMenu_ce({ isLoggedIn = false } : MenuBar_HKIDRProps) {
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    
    const [PgmLists, setPgmLists] = useState([] as PgmAccessList[])
    const [RecordPgmName, setRecordPgmName] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [userKey, setuserKey] = useState("DEMO")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setuserKey(GsbFlag.user_key)
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        //console.log("sidemenu RecordPgmName ", RecordPgmName)
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: "",
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <div className="Row">
                { userKey == "COMPSHARE" && <a href="#/ClACList" onClick={() => { setRecordPgmName("25 ClACList") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="25 ClACList" />
                        <div>Client Master List</div>
                    </div>
                </a> }
                <a href="#/ClACListSfc" onClick={() => { setRecordPgmName("ACListSfc") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="ACListSfc" />
                        <div>Account List SFC</div>
                    </div>
                </a>
            </div>
            <div className="Row">
                { userKey == "COMPSHARE" && <a href="#/ClientTranCheckList" onClick={() => { setRecordPgmName("312 ClientTranCheckList") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="312 ClientTranCheckList" />
                        <div>Client Transaction Check List</div>
                    </div>
                </a> }
                { userKey == "COMPSHARE" && <a href="#/StkHoldList" onClick={() => { setRecordPgmName("74 StkHoldList") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="74 StkHoldList" />
                        <div>Stock Holder List</div>
                    </div>
                </a> }
                { userKey !== "COMPSHARE" && <a href="#/ClHistory" onClick={() => { setRecordPgmName("29 ClHistory") }} >
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="ClHistory" />
                        <div>Client History Report</div>
                    </div>
                </a> }
            </div>
        </>
    )
}


function SideMenu_HKIDR({ isLoggedIn = false } : MenuBar_HKIDRProps) {
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    
    const [showSysAdmin, setshowSysAdmin] = useState(false)
    const [showHKIDRUser, setshowHKIDRUser] = useState(false)

    const [RecordPgmName, setRecordPgmName] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")
    const [userId, setuserId] = useState("")
    const [CM2K_USER, setCM2K_USER] = useState(false)
 
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key)
                setuserId( GsbFlag.UserID)
                setCM2K_USER( GsbFlag.CM2K_USER);
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        setshowSysAdmin(false)
        setshowHKIDRUser(false)

        ;
        if ( isLoggedIn ) {
            
            (async () => {
                try {
                    // get UserAccessGroupInfo
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            cUserid2: userId,
                        },
                        cPgmId: "UserAccessGroup",
                        cAction: "GetUserAccessGroupInfo",
                        cUserid2: userId,
                    });
                    //console.log("GetUserAccessGroupInfo res.data.data", res.data.data)
                    if ( res.data.count > 0 ) {
                        setshowSysAdmin( res.data.data[0].d_User_Group1=="Y")
                        setshowHKIDRUser( res.data.data[0].d_User_Group2=="Y")
                    }


                } catch (error) {
                    utils.showError(error)
                }
            })()


            //setshowSysAdmin(true)
            //setshowHKIDRUser(true)

        }
    }, [gsb, isLoggedIn, userId, GsbFlag])


    useEffect(() => {
        //console.log("sidemenu RecordPgmName ", RecordPgmName)
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: "",
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            { showHKIDRUser && <div className="Row">
                {/*} <Link to="/HkidrMast" >
                    <div className="MenuTile" >
                        <img className="MenuTileImage" src={svgAccount} alt="HkidrMast" />
                        <div>HKIDR Master</div>
                    </div>
                </Link> */}
                <a href="#/HkidrMast" onClick={() => { setRecordPgmName("HKIDR Master") }}>
                    <div className="MenuTile" >
                        <img className="MenuTileImage" src={svgAccount} alt="HkidrMast" />
                        <div>HKIDR Master</div>
                    </div>
                </a>
          
                <a href="#/HkidrBcanMapping" onClick={() => { setRecordPgmName("Export HKBCAN Mapping File") }}>
                    <div className="MenuTile" >
                        <img className="MenuTileImage" src={svgDownload} alt="HKBCAN-CID Mapping File" />
                        <div>Export HKBCAN-CID Mapping File</div>
                    </div>
                </a>
             
                <a href="#/HkidrOtcr" onClick={() => { setRecordPgmName("HKIDR Master") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="Export OTCR Report File (D/W)" />
                        <div>Export OTCR Report File (D/W)</div>
                    </div>
                </a>
               
			    <a href="#/HkidrOtcr2" onClick={() => { setRecordPgmName("HKIDR Master") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="Export OTCR Report File (D/W) in History" />
                        <div>Export OTCR Report File (D/W) in History</div>
                    </div>
                </a>
				

            </div> }
            {GsbFlag.user_key !== "WINGHANG" &&
            <div className="Row">
                { CM2K_USER && <a href="#/ExportEIPO" onClick={() => { setRecordPgmName("Export Fini EIPO bulk upload file") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDownload} alt="IPO Mast" />
                        <div> Export EIPO Bulk list</div>
                    </div>
                </a> }

                { CM2K_USER && <a href="#/EIPOAuthorisedFile" onClick={() => { setRecordPgmName("Read Fini EIPO Authorised Csv Report File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read Fini EIPO Authorised" />
                        <div> Read Fini EIPO Authorised</div>
                    </div>
                </a> }


                { CM2K_USER && <a href="#/EIPOAllotmentResultFile" onClick={() => { setRecordPgmName("Read Fini EIPO Allotment Csv Report File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read Fini EIPO Allotment" />
                        <div> Read Fini EIPO Allotment</div>
                    </div>
                </a> }

     
             </div>
            }

            { showHKIDRUser && <div className="Row">
                <a href="#/HkidrResponseFile" onClick={() => { setRecordPgmName("Read HKBCAN Response File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID Response File" />
                        <div>Read HKBCAN-CID Response File</div>
                    </div>
                </a>
                <a href="#/HkidrProcessResultFile" onClick={() => { setRecordPgmName("Read HKBCAN Result File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID Process Result File" />
                        <div>Read HKBCAN-CID Process Result File</div>
                    </div>
                </a>
                <a href="#/HkidrFullImageFile" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Read HKBCAN-CID BCAN Full Image Reconcile File" />
                        <div>Read HKBCAN-CID BCAN Full Image Reconcile File</div>
                    </div>
                </a>


                { CM2K_USER && <a href="#/EIPO" onClick={() => { setRecordPgmName("Fini EIPO") }}>
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgDocument} alt="Fini EIPO " />
                        <div> Read Fini EIPO Authorised</div>
                    </div>
                </a> }

            </div> }
        </>
    )
}

/*
interface MenuBarProps {
    isLoggedIn?: boolean,
}
*/

function MenuBar({ isLoggedIn = false, onSystemStatusClick = ()=>{}} : MenuBarProps) {
    const [showClMast, setshowClMast] = useState(true)
    const [showSmMast, setshowSmMast] = useState(true)
    const [showBkMast, setshowBkMast] = useState(true)
    const [showIntMast, setshowIntMast] = useState(true)
    const [showCommMast, setshowCommMast] = useState(true)
    const [showHolidayMast,setshowHolidayMast] = useState(true)
    const [showMktMast, setshowMktMast] = useState(true)
    const [showMktFeeMast, setshowMktFeeMast] = useState(true)
    const [showMultiBankMast, setshowMultiBankMast] = useState(false)
    const [showHKIDR2, setshowHKIDR2] = useState(true)
    const [showClientTranEntry, setshowClientTranEntry] = useState(true)
    const [showBrokerTranEntry, setshowBrokerTranEntry] = useState(true)
    const [showPayRecTranEntry, setshowPayRecTranEntry] = useState(true)
    const [showStkDepWithTranEntry, setshowStkDepWithTranEntry] = useState(true)
    const [showClientTranSettlementEntry, setshowClientTranSettlementEntry] = useState(true)
    const [showStockAllocationEntry, setshowStockAllocationEntry] = useState(true)  // 20230330
    const [showSASReport, setshowSASReport] = useState(false)
    const [showImport, setshowImport] = useState(true)

    const [showHKIDR1, setshowHKIDR1] = useState(false)
    const [GSBXHKCRS, setGSBXHKCRS] = useState(false) 
    const [CNBCAN,setCNBCAN] = useState(false)
    const [showDividendProcess, setshowDividendProcess] = useState(true)
    const [showStatement, setshowStatement] = useState(true)
    const [showSentEmailStatement, setshowSentEmailStatement] = useState(false)
    const [showDayEndOneProcess, setshowDayEndOneProcess] = useState(false)

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))


    const [PgmLists, setPgmLists] = useState([] as PgmAccessList[])

    const [warnSysMain, setwarnSysMain] = useState(false)
    const [warnAbout, setwarnAbout] = useState(false)
    const [isMatch, setisMatch] = useState(0)
    const [isPreDayEnd, setisPreDayEnd] = useState(0)
    const [isPrn43Chq, setisPrn43Chq] = useState(0)
    const [isSysEnd, setisSysEnd] = useState(0)


    const [Sys_VerNo, setSys_VerNo] = useState("")
    const [userKey, setuserKey] = useState("DEMO")
    const [userId, setuserId] = useState("")
    const [RecordPgmName, setRecordPgmName] = useState("")
    const [IPAddr, setIPAddr] = useState("")

    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [MULTI_MKT, setMULTI_MKT] = useState(false)
    const [SING_CURR, setSING_CURR] = useState(false)
    const [MULTI_BANK, setMULTI_BANK] = useState(false)
    const [CM2K_USER, setCM2K_USER] = useState(false)
    const [ATI_USER, setATI_USER] = useState(false)
    const [EPS_USER, setEPS_USER] = useState(false)
    const { t, i18n } = useTranslation()
    const [stmtEncode, setstmtEncode] = useState("N")
    const [ShowEditUser, setShowEditUser] = useState(true);    
    const [USER_CTRL, setUSER_CTRL] = useState(false);    

    // 20230814
    const [IMPORTSTK, setIMPORTSTK] = useState(false)
    const [IMPORTCLN, setIMPORTCLN] = useState(false);    
    // 20240312
    const [IMP_CL_Mast1, setIMP_CL_Mast1] = useState(false);    

    // 20230828B
    const [SFC_EXCEL, setSFC_EXCEL] = useState(false);

 
    useEffect(() => {
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/GsbFlag');
                    //console.log("MenuBar disp user_key: ", res.data.Flag.user_key)
                    GsbFlag = res.data.Flag;
                    //console.log("MenuBar disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                    setSys_VerNo("v"+GsbFlag.Sys_VerNo)
                    setuserKey(GsbFlag.user_key)
                    setuserId( GsbFlag.UserID)
                    setMULTI_MKT(GsbFlag.MULTI_MKT)
                    setSING_CURR(GsbFlag.SING_CURR)
                    setMULTI_BANK(GsbFlag.MULTI_BANK)
                    setCM2K_USER( GsbFlag.CM2K_USER);
                    setATI_USER( GsbFlag.ATI_USER);
                    setEPS_USER( GsbFlag.EPS_USER);
                    setshowSentEmailStatement(GsbFlag.EMAIL_STMT)
                    setshowHKIDR1(GsbFlag.HKIDR_USER1)
                    setGSBXHKCRS(GsbFlag.GSBXHKCRS)
                    setCNBCAN(GsbFlag.CNBCAN)
                    setshowSASReport(GsbFlag.SAS_MKT)
                    setstmtEncode(GsbFlag.stmtEncode)
                    console.log(GsbFlag.stmtEncode)

                    setUSER_CTRL(GsbFlag.USER_CTRL)

                    // 20230814 
                    setIMPORTCLN(GsbFlag.IMPORTCLN)
					// 20240312
					setIMP_CL_Mast1(GsbFlag.IMP_CL_Mast1)
					
                    setIMPORTSTK(GsbFlag.IMPORTSTK)
                    // 20230829
                    setSFC_EXCEL(GsbFlag.SFC_EXCEL)

                    if ( GsbFlag.isProduction == "Y" ) {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                    } else {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                    }


                    setPgmLists(res.data.UserPgmListData)
                    //console.log("MenuBar res.data.UserPgmListData", res.data.UserPgmListData)
                    //console.log("MenuBar PgmLists", PgmLists)

                } catch (error) {
                    utils.showError(error)
                }
            })()
        } else {
            (async () => {
                try {
                    const res = await gsb.post('/GsbFlag0');
                    setSys_VerNo("v"+res.data.Flag.Sys_VerNo)
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, isLoggedIn])


    useEffect(() => {
        setwarnSysMain(false)
        setwarnAbout(false)

        ;
        if ( isLoggedIn ) {
            (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                   },
                    cPgmId: "GetIconWarnInfo",
                });
                if ( res.data.ok ) {
                    //"GetIconWarnInfo res.data.data", res.data.data)
                    setwarnSysMain(res.data.data[0].warnAbout) 
                    setwarnAbout(res.data.data[0].warnAbout) 
                    setisMatch(res.data.data[0].isMatch) 
                    setisPreDayEnd(res.data.data[0].isPreDayEnd) 
                    setisPrn43Chq(res.data.data[0].isPrn43Chq) 
                    setisSysEnd(res.data.data[0].isSysEnd) 
                }
            } catch (error) {
                utils.showError(error)
            }
        })()


        }
 
    }, [gsb, isLoggedIn, userId, GsbFlag, Sys_VerNo])


    useEffect(() => {
        //console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, isLoggedIn, userId, GsbFlag, cardOpen01])


    useEffect(() => {

        if ( MULTI_MKT  &&  SING_CURR)  {
            setshowMktMast(false)
            setshowMktFeeMast(false)
        }

        if ( userKey == "FAIREAGLE" ) {
            setshowStatement(false)
        }
        if ( CheckPgmAccess("M0001") || CheckPgmAccess("M0002") || CheckPgmAccess("M0003") || CheckPgmAccess("M0004") || CheckPgmAccess("M0023") ) {
            setshowClMast(true)
        } else {
            setshowClMast(false)
        }

        if ( CheckPgmAccess("M0005") || CheckPgmAccess("M0006") ) {
            setshowBkMast(true)
        } else {
            setshowBkMast(false)
        }

        if ( CheckPgmAccess("M0007") || CheckPgmAccess("M0008") || CheckPgmAccess("M0022") ) {
            setshowSmMast(true)
        } else {
            setshowSmMast(false)
        }

        if ( CheckPgmAccess("M0009") || CheckPgmAccess("M0010") || CheckPgmAccess("M0018") ) {
            setshowIntMast(true)
        } else {
            setshowIntMast(false)
        }

        if ( CheckPgmAccess("M0011") || CheckPgmAccess("M0012") ) {
            setshowCommMast(true)
        } else {
            setshowCommMast(false)
        }
        if (CheckPgmAccess("M0032")){
            setshowHolidayMast(true)
        } else {
            setshowHolidayMast(false)
        }

        
        if ( CheckPgmAccess("M0013") || CheckPgmAccess("M0014") ) {
            setshowMktMast(true)
        } else {
            setshowMktMast(false)
        }

        if ( CheckPgmAccess("M0015") || CheckPgmAccess("M0016") ) {
            setshowMktFeeMast(true)
        } else {
            setshowMktFeeMast(false)
        }

        if ( MULTI_BANK ) {
            if ( CheckPgmAccess("M0019") || CheckPgmAccess("M0020") || CheckPgmAccess("M0021") ) {
                setshowMultiBankMast(true)
            }
        }

        if ( CheckPgmAccess("H0001") || CheckPgmAccess("H0002")  || CheckPgmAccess("H0003") || CheckPgmAccess("H0004") || CheckPgmAccess("H0005") || CheckPgmAccess("H0006") || CheckPgmAccess("H0007") || CheckPgmAccess("H0008") || CheckPgmAccess("H0009") || CheckPgmAccess("H0010") ) {
            setshowHKIDR2(true)
        } else {
            setshowHKIDR2(false)
        }
        

        if ( CheckPgmAccess("T0001") || CheckPgmAccess("T0002") || CheckPgmAccess("T0003") ) {
            setshowClientTranEntry(true)
        } else {
            setshowClientTranEntry(false)
        }
        
        if ( CheckPgmAccess("T0004") || CheckPgmAccess("T0005") || CheckPgmAccess("T0006") ) {
            setshowBrokerTranEntry(true)
        } else {
            setshowBrokerTranEntry(false)
        }
        
        if ( CheckPgmAccess("T0007") || CheckPgmAccess("T0008") || CheckPgmAccess("T0009") ) {
            setshowPayRecTranEntry(true)
        } else {
            setshowPayRecTranEntry(false)
        }

        if ( CheckPgmAccess("T0010") || CheckPgmAccess("T0011") || CheckPgmAccess("T0012") || CheckPgmAccess("T0013") ) {
            setshowStkDepWithTranEntry(true)
        } else {
            setshowStkDepWithTranEntry(false)
        }

        if ( CheckPgmAccess("T0017") || CheckPgmAccess("T0020") || CheckPgmAccess("T0021") ) {
            setshowClientTranSettlementEntry(true)
        } else {
            setshowClientTranSettlementEntry(false)
        }

        if ( CheckPgmAccess("T0019") ) {    // 20230330
            setshowStockAllocationEntry(true)
        } else {
            setshowStockAllocationEntry(false)
        }

        if ( userKey == "DEMO" ) {
            //setShowEditUser(false)
        } else {
            //setShowEditUser(true)
        }

        if ( userKey == "DEMO" || userKey == "BENDURA" ) {
            setshowDayEndOneProcess(true)
        } else {
            setshowDayEndOneProcess(false)
        }

        
    }, [gsb, isLoggedIn, userId, GsbFlag, PgmLists])

/*
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const data = await response.json();
                setIPAddr(data["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, isLoggedIn])
*/

    useEffect(() => {
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: IPAddr,
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])
    

    function CheckPgmAccess(PgmName: string): boolean{
        var canAccess = false;

        for (let i = 0; i < PgmLists.length; i++) {
            //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
            if (PgmLists[i].d_PgmCode === PgmName ) {
                //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
                if (PgmLists[i].d_PgmAccess === "Y" ) {
                    canAccess = true;
                }
                break ;
            }
        }
        return canAccess;
    }

    //is isReadOnly
    function CheckPgmAccess2(PgmName: string): boolean{
        var canAccess = true;

        for (let i = 0; i < PgmLists.length; i++) {
            //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
            if (PgmLists[i].d_PgmCode === PgmName ) {
                //console.log("func CheckPgmAccess PgmLists", PgmName, PgmLists[i].d_PgmCode, PgmLists[i].d_PgmAccess)
                if (PgmLists[i].d_PgmAction === "R" ) {
                    canAccess = false;
                }
                break ;
            }
        }
        return canAccess;
    }

    

    const imgLogo = <img className="AppLogo" src={svgLogo} alt="Logo" />
    const imgAccount = <img className="MenuIcon" src={svgAccount} alt="Account" />
    const imgMaster = <img className="MenuIcon" src={svgMaster} alt="Master" />
    const imgDocument = <img className="MenuIcon" src={svgDocument} alt="Document" />
    const imgMoney = <img className="MenuIcon" src={svgMoney} alt="Money" />
    const imgFinancialreport = <img className="MenuIcon" src={svgFinancialreport} alt="Financialreport" />
    const imgzipDocument = <img className="MenuIcon" src={svgZip} alt="Zipdocument" />
    const imgTransaction = <img className="MenuIcon" src={svgInput} alt="Transaction" />
    const imgImport = <img className="MenuIcon" src={svgImport} alt="Import" />
    const imgUser = <img className="MenuIcon" src={svgUser} alt="Login" />
    const imgTask = <img className="MenuIcon" src={svgTask} alt="Task" />
    const imgChecklist = <img className="MenuIcon" src={svgChecklist} alt="Checklist" />
    const imgbuild = <img className="MenuIcon" src={svgbuild} alt="Utility" />
    const imgsort_asc = <img className="MenuIcon" src={svgsort_asc} alt="Sort" />
    const imgtick_circle = <img className="MenuIcon" src={tick_circle} alt="completed" />
    const history = useHistory()

        const menuMaster =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showClMast && 
                    <MenuItem icon={imgAccount} text="Client Master">
                        { CheckPgmAccess("M0001") && <MenuItem icon={imgAccount} text="21 Client Account" onClick={() => { setRecordPgmName("21 ClMast") }} href="#/accounts" /> }
                        { CheckPgmAccess("M0033") && ( userKey == "CHEERFUL" )  && <MenuItem icon={imgAccount} text="Client Control Document Set" onClick={() => { setRecordPgmName("Client Control Document set") }} href="#/accounts_DocSet" /> }                 
                        { CheckPgmAccess("M0023") && ( userKey == "CHEERFUL" ) && <MenuItem icon={imgImport} text="Import Client Master 2 (Chinese Name)" onClick={() => { setRecordPgmName("Import Clmast 2(Chinese Name)") }} href="#/ImportClMast2" />  }
                        {/* 20230814 */}
                        { CheckPgmAccess("M0026") && ( IMPORTCLN ) && <MenuItem icon={imgImport} text="21I Import Client Master from Text File" onClick={() => { setRecordPgmName("21I Import Client Master from Text File") }} href="#/ImportClMast3" />  }
                          {/* 20240312 */}
						{ CheckPgmAccess("M0034") && ( IMP_CL_Mast1 ) && <MenuItem icon={imgImport} text="Import Client from Text File to HKIDR/Client " onClick={() => { setRecordPgmName("21I Import Client Master from Text File") }} href="#/ImportClMast1" />  }
                        { CheckPgmAccess("M0004") && <MenuItem icon={imgDocument} text="21L Client Master Audit Log" onClick={() => { setRecordPgmName("21L ClLog") }} href="#/ClLog" />  }
                        <MenuDivider />
                        { CheckPgmAccess("M0002") && <MenuItem icon={imgDocument} text="25 Client Master List" onClick={() => { setRecordPgmName("25 ClACList") }} href="#/ClACList" /> }
                        { CheckPgmAccess("M0003") && <MenuItem icon={imgDocument} text="Client List for SFC" onClick={() => { setRecordPgmName("ClACListSfc") }} href="#/ClACListSfc" /> }                        
                    </MenuItem>
                }
                { showBkMast &&
                    <MenuItem icon={imgAccount} text="Broker Master">
                        { CheckPgmAccess("M0005") && <MenuItem icon={imgAccount} text="22 Broker Master" onClick={() => { setRecordPgmName("22 BkMast") }} href="#/BkMast" /> }
                        { CheckPgmAccess("M0006") && <MenuItem icon={imgDocument} text="22L Broker Master Audit Log" onClick={() => { setRecordPgmName("22L BkLog") }} href="#/BkLog" /> }
                    </MenuItem>
                }
                { showSmMast &&
                    <MenuItem icon={imgAccount} text="Stock Master">
                        { CheckPgmAccess("M0007") && <MenuItem icon={imgAccount} text="23 Stock Master" onClick={() => { setRecordPgmName("23 SmMast") }} href="#/SmMast" /> }
                        { CheckPgmAccess("M0022") && <MenuItem icon={imgAccount} text="27 Stock List" onClick={() => { setRecordPgmName("27 STock List") }} href="#/SmList" /> }
                        {/* 20230828 */}
                        { CheckPgmAccess("M0028") && ( GsbFlag.MULTI_LING >= 1 ) && <MenuItem icon={imgImport} text="Import Stock Master (Chinese Name)" onClick={() => { setRecordPgmName("23I Import Stock Master (Chinese Name)") }} href="#/ImportStkMast2" />  }
                        {/* 20230814 */}
                        { CheckPgmAccess("M0027") && ( IMPORTSTK ) && <MenuItem icon={imgImport} text="23I Import Stock Master from Text File" onClick={() => { setRecordPgmName("23I Import Stock Master from Text File") }} href="#/ImportStkMast" />  }
                        {/* 20230829 */}

                        { ( SFC_EXCEL ) && <MenuItem icon={imgDocument} text="Others">
                            { CheckPgmAccess("M0029") && ( SFC_EXCEL ) && <MenuItem icon={imgImport} text="23O4 Import CSV File To Specific Stock As Zero Value" onClick={() => { setRecordPgmName("23O4 Import CSV File To Specific Stock As Zero Value") }} href="#/ImportStkMastNil" />  }
                            { CheckPgmAccess("M0030") && ( SFC_EXCEL ) && <MenuItem icon={imgImport} text="23O5 Import 'MGN' & 'TIER' From CSV File" onClick={() => { setRecordPgmName("23O5 Import 'MGN' & 'TIER' From CSV File") }} href="#/ImportStkMastMarginRatio" />  }
                            { CheckPgmAccess("M0031") &&  ( userKey == "CHEERFUL")  && <MenuItem icon={imgImport} text="23O6 Import CSV File To Connected Stock Group" onClick={() => { setRecordPgmName("23O6 Import CSV File To Connected Stock Group") }} href="#/ImportStkMastGroup" />  }                                                
                         </MenuItem> }                        
                        { CheckPgmAccess("M0008") && <MenuItem icon={imgDocument} text="23L Stock Master Audit Log" onClick={() => { setRecordPgmName("23L SmLog") }} href="#/SmLog" /> }
                    </MenuItem>
                }
                <MenuDivider />
                { showIntMast &&
                    <MenuItem icon={imgAccount} text="Interest Master">
                        { CheckPgmAccess("M0009") && <MenuItem icon={imgAccount} text="11 Interest Master" onClick={() => { setRecordPgmName("11 IntRMast") }} href="#/IntRMast" /> }
                        { CheckPgmAccess("M0018") && <MenuItem icon={imgDocument} text="Interest Rate Report" onClick={() => { setRecordPgmName("Interest Rate Report") }} href="#/IntRMastList" /> }
                        { CheckPgmAccess("M0010") && <MenuItem icon={imgDocument} text="11L Interest Master Audit Log" onClick={() => { setRecordPgmName("11L IntRLog") }} href="#/IntRLog" /> }
                        { CheckPgmAccess("M0009") && <MenuItem icon={imgAccount} text="Prime Interest Rate" onClick={() => { setRecordPgmName("PrimeRateMast") }} href="#/PrimeRateMast" /> }
                    </MenuItem>
                }
                { showCommMast &&
                    <MenuItem icon={imgAccount} text="Commission Master">
                        { CheckPgmAccess("M0011") && <MenuItem icon={imgAccount} text="12 Commission Master" onClick={() => { setRecordPgmName("12 CommMast") }} href="#/CommMast" /> }
                        { CheckPgmAccess("M0012") && <MenuItem icon={imgDocument} text="12L Commission Master Audit Log" onClick={() => { setRecordPgmName("12L CommLog") }} href="#/CommLog" /> }
                    </MenuItem>
                }

               {showHolidayMast &&
                    <MenuItem icon={imgAccount} text="Holiday">
                       { CheckPgmAccess("M0032") && <MenuItem icon={imgAccount} text="1H Holiday Master" onClick={() => { setRecordPgmName("1H Holiday Master") }} href="#/HolidayMast" /> }
                       { CheckPgmAccess("M0032") && <MenuItem icon={imgDocument} text="Holiday Table Report" onClick={() => { setRecordPgmName("Holiday Table Report") }} href="#/HolidayMastList" /> }
  
                    </MenuItem>
                }
                { showMultiBankMast &&
                    <MenuItem disabled={SING_CURR} icon={imgAccount} text="Multiple Bank Master">
                        { CheckPgmAccess("M0019") && <MenuItem icon={imgAccount} text="1K Multiple Bank Master" onClick={() => { setRecordPgmName("1K MultiBankMast") ; history.push("/MultiBankMast") }} /> }
                        { CheckPgmAccess("M0020") && <MenuItem icon={imgDocument} text="Multiple Bank Table Report" onClick={() => { setRecordPgmName("Multi-Bank Master List") ; history.push("/MultiBankMastList") }} /> }
                        { CheckPgmAccess("M0021") && <MenuItem icon={imgDocument} text="1KL Multiple Bank Master Audit Log" onClick={() => { setRecordPgmName("1KL MultiBankLog") ; history.push("/MultiBankLog") }} /> }
                    </MenuItem>
                }
                { showMktMast &&
                    <MenuItem disabled={SING_CURR} icon={imgAccount} text="Market Master">
                        { CheckPgmAccess("M0013") && <MenuItem icon={imgAccount} text="23M Market Master" onClick={() => { setRecordPgmName("23M MktMast") }} href="#/MktMast" /> }
                        { CheckPgmAccess("M0014") && <MenuItem icon={imgDocument} text="23ML Market Master Audit Log" onClick={() => { setRecordPgmName("23ML MktLog") }} href="#/MktLog" /> }
                    </MenuItem>
                }
                { showMktFeeMast &&
                    <MenuItem disabled={SING_CURR} icon={imgAccount} text="Market Fee Master">
                        { CheckPgmAccess("M0015") && <MenuItem icon={imgAccount} text="23F Market Fee Master" onClick={() => { setRecordPgmName("23F MktFeeMast") }} href="#/MktFeeMast" /> }
                        { CheckPgmAccess("M0016") && <MenuItem icon={imgDocument} text="23FL Market Fee Master Audit Log" onClick={() => { setRecordPgmName("23FL MktFeeLog") }} href="#/MktFeeLog" /> }
                    </MenuItem>
                }
                <MenuItem disabled={SING_CURR} icon={imgAccount} text="Exchange Rate">
                    { CheckPgmAccess("M0024") && <MenuItem icon={imgAccount} text="122 Exchange Rate Setup" onClick={() => { setRecordPgmName("122 Exchange Rate Setup") }} href="#/ExchangeRate" /> }
                    { CheckPgmAccess("M0025") && <MenuItem icon={imgDocument} text="122 Exchange Rate Report" onClick={() => { setRecordPgmName("122 Exchange Rate Report") }} href="#/ExchangeRateList" /> }
                </MenuItem>

                {/*
                { showHKIDR1 && showHKIDR2 &&
                    <MenuItem icon={imgAccount} text="HKIDR">
                        <MenuItem icon={imgAccount} text="HKIDR Master">
                            { CheckPgmAccess("H0001") && <MenuItem icon={imgAccount} text="Import Client from GSB (new Client)" onClick={() => { setRecordPgmName("Import Client from GSB (new Client)") }} href="#/HkidrImpAccount" /> }
                            <MenuDivider />
                            { CheckPgmAccess("H0002") && <MenuItem icon={imgAccount} text="HKIDR Master" onClick={() => { setRecordPgmName("HKIDR Master") }} href="#/HkidrMast" /> }
                            { CheckPgmAccess("H0003") && <MenuItem icon={imgDocument} text="HKIDR Master List" onClick={() => { setRecordPgmName("HKIDR Master List") }} href="#/HkidrMastList" /> }
                            { CheckPgmAccess("H0004") && <MenuItem icon={imgDocument} text="HKIDR Master Audit Log" onClick={() => { setRecordPgmName("HKIDR Audit Log") }} href="#/HkidrLog" /> }
                            { CheckPgmAccess("H0005") && <MenuItem icon={imgDocument} text="GSB Client Name Change Log" onClick={() => { setRecordPgmName("GSB Client Name Change Log") }} href="#/GsbNameChangeLog" /> }
                            <MenuDivider />
                            { CheckPgmAccess("H0006") && <MenuItem icon={imgAccount} text="Import Hk Consent Status (CSV)" onClick={() => { setRecordPgmName("Import Hk Consent Status") }} href="#/importHkconsentCSV" /> }
    
                            <MenuDivider />
                        </MenuItem>
                        <MenuItem icon={imgAccount} text="Print/Export Consent">
                            { CheckPgmAccess("H0008") && <MenuItem icon={imgDocument} text="Print HK Consent" onClick={() => { setRecordPgmName("Print HK RConsent") }} href="#/HkidrHkConsent" /> }
                            { CheckPgmAccess("H0009") && <MenuItem icon={imgAccount} text="Export Hk Consent Status for Mail Merge (CSV)" onClick={() => { setRecordPgmName("Export Hk Consent Status") }} href="#/ExportHkConsent" /> }
                        </MenuItem>
                        <MenuItem icon={imgAccount} text="BCAN-CID Mapping/Result File">
                            { CheckPgmAccess("H0010") && <MenuItem icon={imgAccount} text="HKBCAN-CID">
                                <MenuItem icon={imgDocument} text="Export HKBCAN-CID Mapping File" onClick={() => { setRecordPgmName("Export HKBCAN Mapping File") }} href="#/HkidrBcanMapping" />
                                <MenuDivider />
                                <MenuItem icon={imgDocument} text="Read HKBCAN-CID Response File" onClick={() => { setRecordPgmName("Read HKBCAN Response File") }} href="#/HkidrResponseFile" />
                                <MenuItem icon={imgDocument} text="Read HKBCAN-CID Process Result File" onClick={() => { setRecordPgmName("Read HKBCAN Result File") }} href="#/HkidrProcessResultFile" />
                                <MenuItem icon={imgDocument} text="Read HKBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") }} href="#/HkidrFullImageFile" />
                            </MenuItem> }
                            { CheckPgmAccess("H0011") && <MenuItem icon={imgDocument} text="Export OTCR Report File(D/W)" onClick={() => { setRecordPgmName("Export OTCR Report File(D/W)") }} href="#/HkidrOtcr" /> }
                            { CheckPgmAccess("H0011") && <MenuItem icon={imgDocument} text="Export OTCR Report File(D/W) in History" onClick={() => { setRecordPgmName("Export OTCR Report File(D/W) in History") }} href="#/HkidrOtcr2" /> }
                        
							{ CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Export Fini Report File" onClick={() => { setRecordPgmName("Export Fini Report File") }} href="#/ExportEIPO" />}
                            { CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Read Fini EIPO Authorised Csv File" onClick={() => { setRecordPgmName("Read Fini EIPO Authorised Csv Report File") }} href="#/EIPOAuthorisedFile" />}
      
                            { CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Read Fini EIPO Allotment Csv File" onClick={() => { setRecordPgmName("Read Fini EIPO Allotment Csv Report File") }} href="#/EIPOAllotmentResultFile" />}
      
                        </MenuItem>
                        <MenuItem icon={imgAccount} text="Configuration">
                            { CheckPgmAccess("S0002") && 
                            
                                <MenuItem icon={imgDocument} text="BCAN Configuration" onClick={() => { setRecordPgmName("BcanConfig") }} href="#/BcanConfig" />
                            }
                        </MenuItem>
                    </MenuItem>
                }
                */}

            </Menu>
        }>
            <Button minimal icon={imgMaster} text="Master" />
        </Popover>

        const menuHKIDR =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                <MenuItem icon={imgAccount} text="HKIDR Master">
                    { CheckPgmAccess("H0001") && <MenuItem icon={imgAccount} text="Import Client from GSB (New Client)" onClick={() => { setRecordPgmName("Import Client from GSB (New Client)") }} href="#/HkidrImpAccount" /> }
                    { CheckPgmAccess("M0034") && ( IMP_CL_Mast1 ) && <MenuItem icon={imgImport} text="Import Client from Text File to HKIDR/Client" onClick={() => { setRecordPgmName("21I Import Client Master from Text File") }} href="#/ImportClMast1" />  }
         
                    <MenuDivider />
                    { CheckPgmAccess("H0002") && <MenuItem icon={imgAccount} text="HKIDR Master" onClick={() => { setRecordPgmName("HKIDR Master") }} href="#/HkidrMast" /> }
                    { CheckPgmAccess("H0003") && <MenuItem icon={imgDocument} text="HKIDR Master List" onClick={() => { setRecordPgmName("HKIDR Master List") }} href="#/HkidrMastList" /> }
                    { CheckPgmAccess("H0004") && <MenuItem icon={imgDocument} text="HKIDR Master Audit Log" onClick={() => { setRecordPgmName("HKIDR Audit Log") }} href="#/HkidrLog" /> }
                    { CheckPgmAccess("H0005") && <MenuItem icon={imgDocument} text="GSB Client Name Change Log" onClick={() => { setRecordPgmName("GSB Client Name Change Log") }} href="#/GsbNameChangeLog" /> }
                    <MenuDivider />
                    { CheckPgmAccess("H0006") && <MenuItem icon={imgAccount} text="Import HK Consent Status (CSV)" onClick={() => { setRecordPgmName("Import HK Consent Status") }} href="#/importHkconsentCSV" /> }

                    {/*<MenuDivider />*/}
                    {/* CheckPgmAccess("H0007") && <MenuItem icon={imgAccount} text="Generate CNBcan" onClick={() => { setRecordPgmName("Generate CNBcan") }} href="#/CNBcanGen" /> */}
                </MenuItem>
                <MenuItem icon={imgAccount} text="Print/Export Consent">
                    { CheckPgmAccess("H0008") && <MenuItem icon={imgDocument} text="Print HK Consent" onClick={() => { setRecordPgmName("Print HK RConsent") }} href="#/HkidrHkConsent" /> }
                    { CheckPgmAccess("H0009") && <MenuItem icon={imgAccount} text="Export HK Consent Status for Mail Merge (CSV)" onClick={() => { setRecordPgmName("Export HK Consent Status") }} href="#/ExportHkConsent" /> }
                </MenuItem>
                <MenuItem icon={imgAccount} text="BCAN-CID Mapping/Result File">
                    { CheckPgmAccess("H0010") && <MenuItem icon={imgAccount} text="HKBCAN-CID">
                        <MenuItem icon={imgDocument} text="Export HKBCAN-CID Mapping File" onClick={() => { setRecordPgmName("Export HKBCAN Mapping File") }} href="#/HkidrBcanMapping" />
                        <MenuDivider />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID Response File" onClick={() => { setRecordPgmName("Read HKBCAN Response File") }} href="#/HkidrResponseFile" />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID Process Result File" onClick={() => { setRecordPgmName("Read HKBCAN Result File") }} href="#/HkidrProcessResultFile" />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") }} href="#/HkidrFullImageFile" />
                    </MenuItem> }

                     <MenuItem icon={imgAccount} text="CNBCAN">
                        { CheckPgmAccess("H0022") &&  <MenuItem icon={imgDocument} text="Export CNBCAN-CID Mapping File" onClick={() => { setRecordPgmName("Export CNBCAN-CID Mapping File") }} href="#/CNBcanMapping" />}
                        <MenuDivider />
                        
                        { CheckPgmAccess("H0023") &&  <MenuItem icon={imgDocument} text="Read CNBCAN-CID Response File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Response File") }} href="#/CNBcanResponseFile" />}
                        { CheckPgmAccess("H0023") && <MenuItem icon={imgDocument} text="Read CNBCAN-CID Authorisied TTEP List Response File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Result File") }} href="#/CNBcanAurpResponseFile" />}
                        { CheckPgmAccess("H0023") && <MenuItem icon={imgDocument} text="Read CNBCAN-CID validation result File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Result File") }} href="#/CNBcanRsltResultFile" />}
                        { CheckPgmAccess("H0023") && <MenuItem icon={imgDocument} text="Read CNBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Full Image File") }} href="#/CNBcanFimgFile" />}
                        </MenuItem>
                     

                    { CheckPgmAccess("H0011") && <MenuItem icon={imgDocument} text="Export OTCR Report File (D/W)" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W)") }} href="#/HkidrOtcr" /> }
                    { CheckPgmAccess("H0011") && <MenuItem icon={imgDocument} text="Export OTCR Report File (D/W) in History" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W) in History") }} href="#/HkidrOtcr2" /> }
                
                    { CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Export Fini Report File" onClick={() => { setRecordPgmName("Export Fini Report File") }} href="#/ExportEIPO" />}
                    { CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Read Fini EIPO Authorised Csv File" onClick={() => { setRecordPgmName("Read Fini EIPO Authorised Csv Report File") }} href="#/EIPOAuthorisedFile" />}

                    { CheckPgmAccess("H0012") && CM2K_USER && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="Read Fini EIPO Allotment Csv File" onClick={() => { setRecordPgmName("Read Fini EIPO Allotment Csv Report File") }} href="#/EIPOAllotmentResultFile" />}

                </MenuItem>
                <MenuItem icon={imgAccount} text="Configuration">
                    { CheckPgmAccess("S0002") && 
                    
                        <MenuItem icon={imgDocument} text="BCAN Configuration" onClick={() => { setRecordPgmName("BcanConfig") }} href="#/BcanConfig" />
                    }
                </MenuItem>
            </Menu>
            }>
                <Button minimal icon={imgMaster} text="HKIDR" />
        </Popover>

        const menuHKCRS =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                {CheckPgmAccess("H0013") && <MenuItem icon={imgAccount} text="CRS Import Client from GSB" onClick={() => { setRecordPgmName("CRS Import Client from GSB") }} href="#/HkCrsImpAccount" /> }
                {CheckPgmAccess("H0014") && <MenuItem icon={imgAccount} text="CRS Data Import Client from GSB" disabled={false} onClick={() => { setRecordPgmName("CRS Data Import Client from GSB") }} href="#/HkCrsDataImpAccount" />}
                {<MenuDivider />}
                { CheckPgmAccess("H0015") && <MenuItem icon={imgAccount} text="CRS Master" disabled={false} onClick={() => { setRecordPgmName("CRS Master") }} href="#/HkCrsMast" /> }
                {CheckPgmAccess("H0016") && <MenuItem icon={imgAccount} text="CRS Master List" disabled={false} onClick={() => { setRecordPgmName("CRS Master List") }} href="#/HkCrsMastList" /> }                
                {CheckPgmAccess("H0017") && <MenuItem icon={imgAccount} text="CRS Master Audit Log" disabled={false}  onClick={() => { setRecordPgmName("CRS Master Audit Log") }} href="#/HkCrsLog" /> }
                {<MenuItem icon={imgAccount} text="CRS Data Master" disabled={false} onClick={() => { setRecordPgmName("Crs Data Master") }} href="#/HkCrsDataMast" />}
                {CheckPgmAccess("H0019") && <MenuItem icon={imgAccount} text="CRS Data Master Audit Log" disabled={false} onClick={() => { setRecordPgmName("CRS Data Master Audit Log") }} href="#/HkCrsDLog" />}
                {CheckPgmAccess("H0020") &&<MenuItem icon={imgAccount} text="CRS Export"onClick={() => { setRecordPgmName("CrsExport") }} href="#/HkCrsExport" />}
                <MenuDivider />
                {CheckPgmAccess("H0020") &&<MenuItem icon={imgAccount} text="GSB Data For CRS enquiry"onClick={() => { setRecordPgmName("CrsExport") }} href="#/HkCrsEnquiry" />}
                <MenuDivider />
                { CheckPgmAccess("S0013") &&<MenuItem icon={imgAccount} text="CRS Configuration" onClick={() => { setRecordPgmName("CrsConfig") }} href="#/CrsConfig" />}
            </Menu>
            }>
                <Button minimal icon={imgMaster} text="CRS" />
        </Popover>


        const menuDividendProcess =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                
                { showDividendProcess && CheckPgmAccess("D0001") &&
                    <MenuItem icon={imgAccount} text="91 Stock Announcement Entry" onClick={() => { setRecordPgmName("91 StockAnnouncementEntry") }} href="#/StockAnnouncementEntry" />
                }
                { showDividendProcess && CheckPgmAccess("D0002") &&
                    <MenuItem icon={imgDocument} text="91L Stock Announcement Audit Log" onClick={() => { setRecordPgmName("91L StockAnnouncementLog") }} href="#/StockAnnouncementLog" />
                }
                { showDividendProcess && CheckPgmAccess("D0003") &&
                    <MenuItem icon={imgDocument} text="96 Book Close Reminder Report" onClick={() => { setRecordPgmName("96 BookCloseReminder") }} href="#/BookCloseReminder" />
                }
                { showDividendProcess && CheckPgmAccess("D0004") &&
                    <MenuItem icon={imgAccount} text="9M Foreign Market Dividend Charges Setup" onClick={() => { setRecordPgmName("9M MktDividendCharge") }} href="#/MktDividendCharge" />
                }
                { showDividendProcess && CheckPgmAccess("D0005") &&
                    <MenuItem icon={imgAccount} text="Input Corporate Announcement Master Data File" onClick={() => { setRecordPgmName("Input Announcement") }} href="#/InputAnnouncement" />
                }
            </Menu>
            }>
                <Button minimal icon={imgMaster} text="Dividend" />
        </Popover>

        const menuTransaction =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { CheckPgmAccess("T0018") && <MenuItem icon={imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="16 Day Start" onClick={() => { setRecordPgmName("16 Day Start") }} href="#/DayStartProc" /> }
                
                { showClientTranEntry &&
                    <MenuItem icon={imgAccount} text="Client Transaction">
                        { CheckPgmAccess("T0001") && <MenuItem icon={imgAccount} text="31 Client Transaction Entry" onClick={() => { setRecordPgmName("31 ClientTranEntry") }} href="#/ClientTranEntry" /> }
                        { CheckPgmAccess("T0002") && <MenuItem icon={imgDocument} text="312 Client Transaction Check List" onClick={() => { setRecordPgmName("312 ClientTranCheckList") }} href="#/ClientTranCheckList" /> }
                        { CheckPgmAccess("T0003") && <MenuItem icon={imgDocument} text="31L Client Transaction Audit Log" onClick={() => { setRecordPgmName("31L ClientTranLog") }} href="#/ClientTranLog" /> }
                        {/* 20230823 */}
                        { CheckPgmAccess("T0001") && CheckPgmAccess2("T0001") && <MenuItem icon={imgAccount} text="31V1 Setup Interest Accrued For Client Trades" onClick={() => { setRecordPgmName("31V1 ClientInterestAccrued") }} href="#/ClientInterestAccrued" /> }
                        { CheckPgmAccess("T0001") && CheckPgmAccess2("T0001") && <MenuItem icon={imgDocument} text="31V2 Interest Accrued Report For Client Trades" onClick={() => { setRecordPgmName("31V2 ClientInterestAccruedRport") }} href="#/ClientInterestAccruedRpt" /> }
                        { CheckPgmAccess("T0001") && CheckPgmAccess2("T0001") && <MenuItem icon={imgAccount} text="31C Clear Client Transaction" onClick={() => { setRecordPgmName("31C Clear ClientTran") }} href="#/ClientTranClear" /> }
                    </MenuItem>
                }
                { showBrokerTranEntry &&
                    <MenuItem icon={imgAccount} text="Broker Transaction">
                        { CheckPgmAccess("T0004") && <MenuItem icon={imgAccount} text="32 Broker Transaction Entry" onClick={() => { setRecordPgmName("32 BrokerTranEntry") }} href="#/BrokerTranEntry" /> }
                        { CheckPgmAccess("T0005") && <MenuItem icon={imgDocument} text="322 Broker Transaction Check List" onClick={() => { setRecordPgmName("322 BrokerTranCheckList") }} href="#/BrokerTranCheckList" /> }
                        { CheckPgmAccess("T0006") && <MenuItem icon={imgDocument} text="32L Broker Transaction Audit Log" onClick={() => { setRecordPgmName("32L BrokerTranLog") }} href="#/BrokerTranLog" /> }
                        {/* 20230823 */}
                        { CheckPgmAccess("T0004") && CheckPgmAccess2("T0004") && <MenuItem icon={imgAccount} text="32V1 Setup Interest Accrued For Broker Trades" onClick={() => { setRecordPgmName("32V1 BrokerInterestAccrued") }} href="#/BrokerInterestAccrued" /> }
                        { CheckPgmAccess("T0004") && CheckPgmAccess2("T0004") && <MenuItem icon={imgDocument} text="32V2 Interest Accrued Report For Broker Trades" onClick={() => { setRecordPgmName("32V2 BrokerInterestAccruedReport") }} href="#/BrokerInterestAccruedRpt" /> }
                        { CheckPgmAccess("T0004") && CheckPgmAccess2("T0004") && <MenuItem icon={imgAccount} text="32C Clear Broker Transaction" onClick={() => { setRecordPgmName("32C Clear BrokerTran") }} href="#/BrokerTranClear" /> }
                    </MenuItem>
                }
                { showPayRecTranEntry &&
                    <MenuItem icon={imgMoney} text="Payment / Receipt Transaction">
                        { CheckPgmAccess("T0007") && <MenuItem icon={imgMoney} text="33 Payment / Receipt Transaction Entry" onClick={() => { setRecordPgmName("33 PayRecTranEntry") }} href="#/PayRecTranEntry" /> }
                        { CheckPgmAccess("T0008") && <MenuItem icon={imgDocument} text="332 Payment / Receipt Transaction Check List" onClick={() => { setRecordPgmName("332 PayRecTranCheckList") }} href="#/PayRecTranCheckList" /> }
                        { CheckPgmAccess("T0024") && <MenuItem icon={imgDocument} text='335 Print Cheque / "Direct Debit List" to Client ' onClick={() => { setRecordPgmName("335 PayRec Print Cheque") }} href="#/PayRecCheq" /> }
                        { CheckPgmAccess("T0009") && <MenuItem icon={imgDocument} text="33L Payment / Receipt Transaction Audit Log" onClick={() => { setRecordPgmName("33L PayRecTranLog") }} href="#/PayRecTranLog" /> }
                        { CheckPgmAccess("T0007") && CheckPgmAccess2("T0007") && <MenuItem icon={imgMoney} text="33C Clear Payment / Receipt Transaction" onClick={() => { setRecordPgmName("33C Clear PayRecTran") }} href="#/PayRecTranClear" /> }
                    </MenuItem>
                }
                { showStkDepWithTranEntry &&
                    <MenuItem icon={imgAccount} text="Stock Deposit/ Withdrawal Transaction">
                        { CheckPgmAccess("T0010") && <MenuItem icon={imgAccount} text="34 Stock Deposit / Withdrawal Transaction Entry" onClick={() => { setRecordPgmName("34 StkDepWithTranEntry") }} href="#/StkDepWithTranEntry" /> }
                   
                        {/*showHKIDR1 && showHKIDR2 && CheckPgmAccess("T0023") && <MenuItem icon={imgDocument} text="Gen Er ref in share transfer" onClick={() => { setRecordPgmName("Gen Er ref in share transfer") }} href="#/ShareTransGenEr" /> */}
           
                        { CheckPgmAccess("T0011") && <MenuItem icon={imgDocument} text="342 Stock Deposit / Withdrawal Transaction Check List" onClick={() => { setRecordPgmName("342 StkDepWithTranCheckList") }} href="#/StkDepWithTranCheckList" /> }
                        { CheckPgmAccess("T0012") && <MenuItem icon={imgDocument} text="34L Stock Deposit / Withdrawal Transaction Audit Log" onClick={() => { setRecordPgmName("34L StkDepWithTranLog") }} href="#/StkDepWithTranLog" /> }
                        { CheckPgmAccess("T0013") && <MenuItem icon={imgDocument} text="344 Global Withdrawal / Transfer of a Stock" onClick={() => { setRecordPgmName("344 Stk Global Withdrawal") }} href="#/StkGlobalWithdrawal" /> }
                        { CheckPgmAccess("T0010") && CheckPgmAccess2("T0010") && <MenuItem icon={imgAccount} text="34C Clear Stock Deposit / Withdrawal Transaction" onClick={() => { setRecordPgmName("34C Clear StkDepWithTran") }} href="#/StkDepWithClear" /> }
                    </MenuItem>
                }
                { showClientTranSettlementEntry &&
                    <MenuItem icon={imgAccount} text="Settlement Entry">
                        { CheckPgmAccess("T0017") && <MenuItem icon={imgAccount} text="351 Client Transaction Settlement Entry" onClick={() => { setRecordPgmName("351 Settlement Entry") }} href="#/ClientTranSettlementEntry" /> }
                        { CheckPgmAccess("T0020") && <MenuItem icon={imgDocument} text="353 Client Transaction Settlement Check List" onClick={() => { setRecordPgmName("353 Settlement Check List") }} href="#/ClientTranSettlementCheckList" /> }
                        { CheckPgmAccess("T0021") && <MenuItem icon={imgDocument} text="35L Client Transaction Settlement Audit Log" onClick={() => { setRecordPgmName("35L Settlement Check ListAudit Log") }} href="#/ClientTranSettlementLog" /> }
                    </MenuItem>
                }
                { CheckPgmAccess("T0014") &&
                    <MenuItem icon={isMatch == 2 ? imgtick_circle : imgDocument} text="Trial Match & Report">
                        <MenuItem icon={isMatch == 2 ? imgtick_circle : imgDocument} text="36 Start Matching" onClick={() => { setRecordPgmName("36 Start Matching") }} href="#/Matching" />
                        <MenuItem icon={imgDocument} text="362 Matching Report" onClick={() => { setRecordPgmName("362 Matching Report") }} href="#/MatchingReport" />
                    </MenuItem>
                }
                { CheckPgmAccess("T0022") &&
                    <MenuItem icon={imgDocument} text="Trade Cancel & Correct">
                        <MenuItem icon={imgDocument} text="3F1 Cancel Previous Day Trade" onClick={() => { setRecordPgmName("3F1 Cancel Previous Day Trade") }} href="#/CancelPrevTrade" />
                        <MenuItem icon={imgDocument} text="3F2 Input Previous Day Trade" onClick={() => { setRecordPgmName("3F2 Input Previous Day Trade") }} href="#/CorrectTrade" />
                        <MenuItem icon={imgDocument} text="3F5 Cancel and Correct Trade Register" onClick={() => { setRecordPgmName("3F5 Cancel and Correct Trade Register") }} href="#/CancelCorrectTradeRegister" />
                        <MenuItem icon={imgDocument} text="3FL Cancel and Correct Trade Audit Log" onClick={() => { setRecordPgmName("3FL Cancel and Correct Trade Audit Log") }} href="#/CancelCorrectTradeLog" />
                    </MenuItem>
                }
                {/* 20230330 */}
                <MenuDivider />
                {  CheckPgmAccess("T0019") && showStockAllocationEntry &&
                    <MenuItem icon={imgAccount} text="Stock Allocation Entry">
                        <MenuItem icon={imgAccount} text="791 Stock Allocation Entry" onClick={() => { setRecordPgmName("791 Stock Allocation Entry") }} href="#/StockAllocationEntry" /> 
                        <MenuItem icon={imgDocument} text="792 Stock Allocation Status Report" onClick={() => { setRecordPgmName("792 Stock Allocation Status Report") }} href="#/StockAllocationStatusRpt" /> 
                    </MenuItem>
                }
                <MenuDivider />
                {showSASReport &&
                    <MenuItem icon={imgDocument} text="SAS/SZMK Market Report">
                        { CheckPgmAccess("SA001") && <MenuItem icon={imgDocument} text="3S1 SAS/SZMK Daily Transaction Resister" onClick={() => { setRecordPgmName("3S1 SAS/SZMK Daily Transaction Resister") }} href="#/Sas_Rpt1" /> }
                        { CheckPgmAccess("SA002") && <MenuItem icon={imgDocument} text="3S2 Generate ATI File for SAS/SZMK" onClick={() => { setRecordPgmName("3S2 Generate ATI File for SAS/SZMK") }} href="#/Sas_Rpt2" /> }
                        { CheckPgmAccess("SA003") && <MenuItem icon={imgDocument} text="3S3 Monthly Expense for SAS/SZMK" onClick={() => { setRecordPgmName("3S3 Monthly Expense for SAS/SZMK") }} href="#/Sas_Rpt3" /> }
                        { CheckPgmAccess("SA004") && <MenuItem icon={imgDocument} text="3S4 Monthly Portfolio Fee for SAS/SZMK" onClick={() => { setRecordPgmName("3S4 Monthly Portfolio Fee for SAS/SZMK") }} href="#/Sas_Rpt4" /> }
                        { CheckPgmAccess("SA005") && <MenuItem icon={imgDocument} text="3S5 Generate Monthly Portfolio Fee for SAS/SZMK" onClick={() => { setRecordPgmName("3S5 Generate Monthly Portfolio Fee for SAS/SZMK") }} href="#/Sas_Rpt5" /> }
                    </MenuItem>
                }
                {showSASReport && <MenuDivider />}

                {ATI_USER &&
                    <MenuItem icon={imgDocument} text="ATI">
                        { CheckPgmAccess("AT001") && <MenuItem icon={imgDocument} text="ATI Setup" onClick={() => { setRecordPgmName("ATI Setup") }} href="#/Ati_Rpt1" /> }
                        { CheckPgmAccess("AT002") && <MenuItem icon={imgDocument} text="Generate ATI Upload Text File, HK" onClick={() => { setRecordPgmName("Generate ATI Text File, HK") }} href="#/Ati_Rpt2" /> }
                        { CheckPgmAccess("AT003") && <MenuItem icon={imgDocument} text="Segregated A/C Setup" onClick={() => { setRecordPgmName("Segregated A/C Setup") }} href="#/Ati_Rpt3" /> }
                        { CheckPgmAccess("AT004") && <MenuItem icon={imgDocument} text="Segregated A/C List" onClick={() => { setRecordPgmName("Segregated A/C List") }} href="#/Ati_Rpt4" /> }
                    </MenuItem>
                }

                {EPS_USER &&
                    <MenuItem icon={imgDocument} text="EPS">
                        { CheckPgmAccess("EP001") && <MenuItem icon={imgDocument} text="EPS Setup" onClick={() => { setRecordPgmName("EPS Setup") }} href="#/Eps_Rpt1" /> }
                        { CheckPgmAccess("EP002") && <MenuItem icon={imgDocument} text="EPS Start Checking" onClick={() => { setRecordPgmName("EPS Start Checking") }} href="#/Eps_Rpt2" /> }
                    </MenuItem>
                }
                {(ATI_USER || EPS_USER) && <MenuDivider />}

                { showImport && CheckPgmAccess("T0015") &&
                    <MenuItem icon={imgImport} text="Import files" onClick={() => { setRecordPgmName("Import files") }} href="#/import" />
                }
                { showImport && <MenuDivider />}

                { CheckPgmAccess("T0016") && ( ! showDayEndOneProcess || userKey == "DEMO" ) &&
                    <MenuItem icon={imgDocument} text="Daily Processing with 43 Client Cheque">
                        <MenuItem icon={isPreDayEnd == 2 ? imgtick_circle : imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="Before Print Client Cheque Processing" onClick={() => { setRecordPgmName("Before Print Cheque Processing") }} href="#/PreDayEndProc" />
                        {/* <MenuItem text="Reminder of Print Client Cheque at GSB 43" /> */}
                        <MenuItem icon={isPrn43Chq == 2 ? imgtick_circle : imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="43 Cheque To Client" onClick={() => { setRecordPgmName("43 Cheque To Client") }} href="#/Cheque2Client" />
                        {/* <MenuItem text="Please run 7C1 Day End Processing (SBBatch.TXT)" /> */}
                        <MenuItem icon={isSysEnd == 2 ? imgtick_circle : imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="Day End Processing" onClick={() => { setRecordPgmName("Day End Processing 1") }} href="#/DayEndProc" />
                    </MenuItem> 
                }
                { CheckPgmAccess("T0016") && showDayEndOneProcess &&
                    <MenuItem icon={imgDocument} text="Daily Processing without 43 Client Cheque">
                        <MenuItem icon={isSysEnd == 2 ? imgtick_circle : imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="Day End Processing" onClick={() => { setRecordPgmName("Day End Processing 2") }} href="#/DayEndProc2" />
                    </MenuItem> 
                }

            </Menu>
        }>
            <Button minimal icon={imgTransaction} text="Transaction" />
        </Popover>

        const menuReport =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>

                { showStatement && CheckPgmAccess("R0009") && <MenuItem icon={imgFinancialreport} text="542 Daily Statement" onClick={() => { setRecordPgmName("542 Daily Statement") }} href="#/Dstatement" /> }
                { (CheckPgmAccess("R0001") || CheckPgmAccess("R0002") || CheckPgmAccess("R0020")) && <MenuItem icon={imgDocument} text="74 Stock Holder">
                    { CheckPgmAccess("R0001") && <MenuItem icon={imgDocument} text="73 Stock Overdrawn Report" onClick={() => { setRecordPgmName("73 StkOverdrawnRpt") }} href="#/StkOverdrawnRpt" /> }
                    { CheckPgmAccess("R0002") && <MenuItem icon={imgDocument} text="74 Stock Holder List" onClick={() => { setRecordPgmName("74 StkHoldList") }} href="#/StkHoldList" /> }
                    { CheckPgmAccess("R0020") && <MenuItem icon={imgDocument} text="744 Stock Holder for SFC" onClick={() => { setRecordPgmName("744 StkHoldListSfc") }} href="#/StkHoldListSfc" /> }
                    { CheckPgmAccess("R0020") && <MenuItem icon={imgDocument} text="744 Stock Holder for SFC from folder GsbxRpt" onClick={() => { setRecordPgmName("744 StkHoldListSfc2") }} href="#/StkHoldListSfc2" /> }
                </MenuItem> }
                { (CheckPgmAccess("R0003") || CheckPgmAccess("R0015") ) && <MenuItem icon={imgDocument} text="75 Portfolio Evaluation">
                    { CheckPgmAccess("R0003") && <MenuItem icon={imgDocument} text="751 Portfolio Evaluation" onClick={() => { setRecordPgmName("751 Portfolio Evaluation") }} href="#/PortfolioEvaluation" /> }
                    { CheckPgmAccess("R0015") && <MenuItem icon={imgDocument} text="752 Stock Asset Evaluation" onClick={() => { setRecordPgmName("752 Stock Asset Evaluation") }} href="#/PortfolioEvaluation2" /> }
                </MenuItem> }
                { CheckPgmAccess("R0016") && <MenuItem icon={imgDocument} text="754 Client Position Enquiry" onClick={() => { setRecordPgmName("754 Client Post Enquiry") }} href="#/ClientPosEnquiry" /> }
                { CheckPgmAccess("R0004") && (GsbFlag.user_key != "WINGHANG") && <MenuItem icon={imgDocument} text="25B Account Balance List" onClick={() => { setRecordPgmName("25B Ac Bal List") }} href="#/AcBalList" /> }
                { CheckPgmAccess("R0034") && ( userKey == "CHEERFUL") && <MenuItem icon={imgDocument} text="25X Output Client Balance to XLS" onClick={() => { setRecordPgmName("25X Output Client Balance to XLS") }} href="#/ClACBalXls" /> }
                { CheckPgmAccess("R0005") && <MenuItem icon={imgDocument} text="72 Margin Position Report" onClick={() => { setRecordPgmName("72 Mgn Position") }} href="#/MgnPosition" /> }
                { CheckPgmAccess("R0033") && <MenuItem icon={imgDocument} text="76 Approved Stock Assets Report" onClick={() => { setRecordPgmName("76 Approved Stock Assets Report") }} href="#/ApprovedStockAssetsReport" /> }
                { CheckPgmAccess("R0032") && <MenuItem icon={imgDocument} text="A1 Topmost Balance Client List" onClick={() => { setRecordPgmName("A1 Topmost Balance List") }} href="#/TopmostBalanceList" /> }
                { CheckPgmAccess("R0039") && <MenuItem icon={imgDocument} text="AB Unsettled Tran Accepted Value Report"  onClick={() => { setRecordPgmName("AB Unsettled Transaction Accepted Value Report") }} href="#/UnsettleTranAcceptValue" /> }
                { CheckPgmAccess("R0035") && <MenuItem icon={imgDocument} text="AL Rolling Balance Client Analysis Report" onClick={() => { setRecordPgmName("AL Rolling Balance Client Analysis Report") }} href="#/RollingBalanceReport" /> }
                { (CheckPgmAccess("R0006") || CheckPgmAccess("R0015") ) && <MenuItem icon={imgDocument} text="29 Client Trading History">
                    { CheckPgmAccess("R0006") && <MenuItem icon={imgDocument} text="29 Client History Report" onClick={() => { setRecordPgmName("29 Client History") }} href="#/ClHistory" /> }
                    { CheckPgmAccess("R0006") && (GsbFlag.user_key != "CHEERFUL") && <MenuItem icon={imgDocument} text="29 Client History Report from Folder History" onClick={() => { setRecordPgmName("29 Client History from Folder history") }} href="#/ClHistory2" /> }
                    { CheckPgmAccess("R0006") && <MenuItem icon={imgDocument} text="294 Client Trading History XLS for SFC" onClick={() => { setRecordPgmName("294 Client Trading History XLS for SFC") }} href="#/ClHistorySfc" /> }
                </MenuItem> }
                <MenuDivider />
                <MenuItem icon={imgDocument} text="Monthly Report">
                    { showStatement && CheckPgmAccess("R0010") && <MenuItem icon={imgFinancialreport} text="831 Monthly Statement" onClick={() => { setRecordPgmName("831 Monthly Statement") }} href="#/Mstatement" /> }
                    { showStatement && CheckPgmAccess("R0031") && (stmtEncode === "Y") && <MenuItem icon={imgFinancialreport} text="832 Monthly Statement (NO Address)" onClick={() => { setRecordPgmName("832 Monthly Statement (NO Address)") }} href="#/Mstatement2" /> }
                    { CheckPgmAccess("R0007") && <MenuItem icon={imgDocument} text="84 Monthly Rebate Report" onClick={() => { setRecordPgmName("84 Monthly Rebate Report") }} href="#/MthRebateRpt" /> }
                    { CheckPgmAccess("R0011") && <MenuItem icon={imgDocument} text="85 Stamp Report">
                    <MenuItem icon={imgDocument} text=" Contact Stamp" onClick={() => { setRecordPgmName("851 Contract Stamp") }} href="#/MthContractStmpRpt" /> 
                    <MenuItem icon={imgDocument} text=" Monthly Stamp Return" onClick={() => { setRecordPgmName("852 Monthly Stamp Return") }} href="#/MthStmpReturnRpt" /> 
                   
                    </MenuItem> }
                   {CheckPgmAccess("R0012") && <MenuItem icon={imgDocument} text="86 Monthly Interest Report" onClick={() => { setRecordPgmName("86 Monthly Interest Report") }} href="#/MthInterestRpt" /> }
                   {CheckPgmAccess("R0013") && <MenuItem icon={imgDocument} text="87 Monthly Levy and CCASS Fee Report" onClick={() => { setRecordPgmName("87 Monthly Levy and CCASS Fee Report") }} href="#/MthLevyCCassFeeRpt" /> }
				   {CheckPgmAccess("R0014") && <MenuItem icon={imgDocument} text="88 Monthly Summary Report" onClick={() => { setRecordPgmName("88 Monthly Summary Report") }} href="#/MthSummaryRpt" /> }
 				   {CheckPgmAccess("R0019") && <MenuItem icon={imgDocument} text="89 Monthly CCASS Custody Fee Report" onClick={() => { setRecordPgmName("89 Monthly CCASS Custody Fee Report") }} href="#/MthCCassCustodyFeeRpt"  /> }
                   
                   {CheckPgmAccess("R0026") && <MenuItem icon={imgDocument} text="8A Client Turnover Report" onClick={() => { setRecordPgmName("8A Client Turnover Report") }} href="#/ClientTurnOverRpt"  /> }
                   
                </MenuItem>
                
                {/* <MenuItem icon={imgDocument} text="Margin Report"> */}
                    {/* // 20231124 74X Stock Holder to XLS, StkHoldList.PRG */}
                    {/* {CheckPgmAccess("R0036") && <MenuItem icon={imgDocument} text="74X Export Stock Holder to XLS" onClick={() => { setRecordPgmName("74X Export Stock Holder to XLS") }} href="#/StkHoldXls" /> } */}
                    {/* // 20231128 74Y Export Stock Haircuts for Re-pledges Margin Client, StkHoldList.PRG */}
                    {/* {CheckPgmAccess("R0037") && <MenuItem icon={imgDocument} text="74Y Export Stock Haircuts for Re-pledges Margin Client" onClick={() => { setRecordPgmName("74Y Export Stock Haircuts for Re-pledges Margin Client")}} href="#/StkHaircutXls" /> } */}
                   
                {/* </MenuItem> */}

                { showStatement && showSentEmailStatement && CheckPgmAccess("R0017") && <MenuItem icon={imgFinancialreport} text="Send Email Statment" onClick={() => { setRecordPgmName("Send Email Statment") }} href="#/SentStatement" /> }
                { showStatement && CheckPgmAccess("R0025") && <MenuItem icon={imgFinancialreport} text="Export Webstmt Statement" onClick={() => { setRecordPgmName("Export Webstmt Statement") }} href="#/ExportWebStmt" /> }

                {CheckPgmAccess("R0018") && <MenuItem icon={imgDocument} text="AD DVIEW">   
      
                {CheckPgmAccess("R0021") && (stmtEncode === "Y")  &&  <MenuItem icon={imgFinancialreport} text="AD1 DVIEW Statement" onClick={() => { setRecordPgmName("AD1 DVIEW Statement")}} href="#/Dv1Stmt" /> }
                {CheckPgmAccess("R0022") && (stmtEncode === "Y")  && <MenuItem icon={imgFinancialreport} text="AD2 DVIEW Statement (NO Address)" onClick={() => { setRecordPgmName("AD2 DVIEW Statement (NO Address") }} href="#/Dv2Stmt" /> }      
                {CheckPgmAccess("R0023") && (stmtEncode === "Y")  &&  <MenuItem icon={imgFinancialreport} text="AD3 DVIEW Statement (Today)" onClick={() => { setRecordPgmName("AD3 DVIEW Statement (Today)") }} href="#/DvTransDate" /> }
                
                {CheckPgmAccess("R0021") && (stmtEncode === "N")  &&  <MenuItem icon={imgFinancialreport} text="AD1 DVIEW Statement" onClick={() => { setRecordPgmName("AD1 DVIEW Statement")}} href="#/Dv1Stmt" /> }
                {CheckPgmAccess("R0023") && (stmtEncode === "N")  &&  <MenuItem icon={imgFinancialreport} text="AD3 DVIEW Statement (Today)" onClick={() => { setRecordPgmName("AD3 DVIEW Statement (Today)") }} href="#/DvTransDate" /> }
                
                {CheckPgmAccess("R0024") && (userKey == "CHEERFUL") && <MenuItem icon={imgFinancialreport} text="AD4 Import CSV DVIEW Report to search files" onClick={() => { setRecordPgmName("AD4 Import CSV DVIEW Report to search files") }} href="#/DvsDowntatement" />}
                <MenuDivider />
                {CheckPgmAccess("R0027") && <MenuItem icon={imgDocument} text="AD5 DVIEW Stock Holder Report (pdf) from GsbxRpt" onClick={() => { setRecordPgmName("AD5 DVIEW Stock Holder Report (pdf) from GsbxRpt") }} href="#/DvStkHolderList" />}                    
                {CheckPgmAccess("R0028") && <MenuItem icon={imgDocument} text="AD6 DVIEW Stock Holder Report (pop) from GsbxRpt" onClick={() => { setRecordPgmName("AD6 DVIEW Stock Holder Report (pop) from GsbxRpt") }} href="#/DvStkHolderList2" /> }              
                {CheckPgmAccess("R0029") && <MenuItem icon={imgDocument} text="AD7 DVIEW Dividend Report from GsbxDivRpt" onClick={() => { setRecordPgmName("AD7 DVIEW Dividend Report from GsbxDivRpt") }} href="#/DvDividend" />} 
                {CheckPgmAccess("R0030") && <MenuItem icon={imgDocument} text="AD8 DVIEW Allocation Status Report from GsbxRpt" onClick={() => { setRecordPgmName("AD8 DVIEW Allocation Status Report from GsbxRpt") }} href="#/DvStkAllocStatus" />} 

                </MenuItem> }                
                {/*
                {CheckPgmAccess("R0018") && <MenuItem icon={imgDocument} text="AD1 DVIEW Statement" onClick={() => { setRecordPgmName("AD1 DVIEW Statement") }} href="#/DvStmt" /> }
                {CheckPgmAccess("R0018") && <MenuItem icon={imgDocument} text="AD2 DVIEW Statement(Today)" onClick={() => { setRecordPgmName("AD2 DVIEW Statement(Today)") }} href="#/Dv2Stmt" /> }
                {CheckPgmAccess("R0018") && <MenuItem icon={imgDocument} text="AD3 Import CSV DVIEW Report to search files" onClick={() => { setRecordPgmName("AD3 Import CSV DVIEW Report to search files") }} href="#/DvsDowntatement" /> }
                */}

                {/* CheckPgmAccess("R0018") && <MenuItem icon={imgDocument} text="Dview Out" onClick={() => { setRecordPgmName("AD Dview Down Statement") }} href="#/DviewOut" /> */}

            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="Report" />
        </Popover>

        const menuSysMain =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { CheckPgmAccess("S0001") && <MenuItem icon={imgDocument} text="GSBx Configuration" onClick={() => { setRecordPgmName("GsbxConfig") }} href="#/GsbxConfig" /> }
                { CheckPgmAccess("S0003") && <MenuItem disabled={false} icon={imgsort_asc  } text="Data Upgrade, Reindex" onClick={() => { setRecordPgmName("System Upgrade, Reindex") }} href="#/SBReindex" /> }
                { CheckPgmAccess("S0012") && <MenuItem disabled={false} icon={imgsort_asc  } text="7R Re-update Current Day Transactions" onClick={() => { setRecordPgmName("Re-update Transactions") }} href="#/ReUpdateTrn" /> }
                <MenuDivider />
                { CheckPgmAccess("S0004") && <MenuItem icon={imgDocument} disabled={GsbFlag.user_key == "WINGHANG"} text="Backup 3B1/3B2, Restore Processing" onClick={() => { setRecordPgmName("Backup/Restore Processing") }} href="#/SBBackup" /> }
                { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="Batch Processing" >
                    { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="Batch Processing 1 (Save Daily Report(s))" onClick={() => { setRecordPgmName("Batch 1 Processing") }} href="#/SBBatch" /> }
                    { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="Batch Processing 2 (Save Monthly Report(s))" onClick={() => { setRecordPgmName("Batch 2 Processing") }} href="#/SBBatch2" /> }
                    { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="Batch Processing 3 (Save Statement(s))" onClick={() => { setRecordPgmName("Batch 3 Processing") }} href="#/SBBatch3" /> }
                    { CheckPgmAccess("R0008") && (userKey == "CHEERFUL") && <MenuItem icon={imgDocument} text="Batch Processing 4 (AD3 DVIEW Statement, Today)" onClick={() => { setRecordPgmName("Batch 3 Processing") }} href="#/SBBatch4" /> }
                    { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="All Batch Processing" onClick={() => { setRecordPgmName("All Batch Processing") }} href="#/SBBatch10" /> }
                    { CheckPgmAccess("R0008") && <MenuItem icon={imgDocument} text="Batch Processing 5 (Save Special Report(s))" onClick={() => { setRecordPgmName("Batch 5 Processing") }} href="#/SBBatch5" /> }
                </MenuItem> }
                { CheckPgmAccess("S0005") && <MenuItem icon={imgDocument} text="Login Log" onClick={() => { setRecordPgmName("LoginLog") }} href="#/LoginLog" /> }
                { CheckPgmAccess("S0010") && <MenuItem icon={imgDocument} text="Reset Process" onClick={() => { setRecordPgmName("Resetprocess") }} href="#/Resetprocess" />  }
                { CheckPgmAccess("S0006") && <MenuItem icon={imgDocument} text="GSB Status" onClick={() => { setRecordPgmName("SB Status") }} href="#/SBstatus" /> }
                <MenuItem icon={imgDocument} text="GSB System Module" onClick={() => { setRecordPgmName("GSB System Module") }} href="#/SBModule" />
                { (CheckPgmAccess("S0007") || CheckPgmAccess("S0008") ) &&
                    <MenuItem icon={imgAccount} text="User Master / Access">
                        { ShowEditUser && CheckPgmAccess("S0007") && <MenuItem icon={imgDocument} text="User Master" onClick={() => { setRecordPgmName("User Master Setup") }} href="#/UserMast" /> }
                        { ShowEditUser && CheckPgmAccess("S0007") && <MenuItem icon={imgDocument} text="User List" onClick={() => { setRecordPgmName("User List") }} href="#/UserMastList" /> }
                        { ShowEditUser && CheckPgmAccess("S0007") && <MenuItem icon={imgDocument} text="User Audit Log" onClick={() => { setRecordPgmName("User Audit Log") }} href="#/UserMastLog" /> }
                        { ShowEditUser && CheckPgmAccess("S0007") && <MenuItem icon={imgDocument} text="Reset User Password" onClick={() => { setRecordPgmName("Reset User Password") }} href="#/UserchgPasswd2" /> }
                        { ShowEditUser && CheckPgmAccess("S0007") && <MenuDivider /> }

                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="Group Access" onClick={() => { setRecordPgmName("Group Access") }} href="#/GroupAccess" /> }
                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="Group Access List" onClick={() => { setRecordPgmName("Group Access List") }} href="#/GroupAccessList" /> }
                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="Group Access Audit Log" onClick={() => { setRecordPgmName("Group Access Audit Log") }} href="#/GroupAccessLog" /> }
                        
                        <MenuDivider />
                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="User Pgm Access" onClick={() => { setRecordPgmName("User Pgm Access") }} href="#/UserPgmAccess" /> }
                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="User Pgm Access List" onClick={() => { setRecordPgmName("User Pgm Access List") }} href="#/UserPgmAccessList" /> }
                        { CheckPgmAccess("S0008") && <MenuItem icon={imgDocument} text="User Pgm Access Audit Log" onClick={() => { setRecordPgmName("User Pgm Access Audit Log") }} href="#/UserPgmAccessLog" /> }
                    </MenuItem> 
                }
                { ( USER_CTRL || ShowEditUser ) && <MenuItem icon={imgDocument} text="Change User Password" onClick={() => { setRecordPgmName("Change User Password") ; history.push("/UserchgPasswd") }} /> }
                { CheckPgmAccess("M0017") && <MenuItem icon={imgDocument} text="Authorizations" onClick={() => { setRecordPgmName("Authorizations") }} href="#/ReqAuthList" /> }
                { CheckPgmAccess("S0009") && <MenuItem icon={imgDocument} intent={warnAbout ? Intent.WARNING : "none"} text="About" onClick={() => { setRecordPgmName("About") }} href="#/About" /> }
                { CheckPgmAccess("S0011") && (GsbFlag.user_key != "CHEERFUL") && <MenuItem icon={imgDocument} text="Temp Change Field Length" onClick={() => { setRecordPgmName("Temp Change Field Length") }} href="#/TempChgField" />  }
                <MenuItem icon={imgDocument} text="Log Out" onClick={() => { setRecordPgmName("Log Out") }} href="#/login" />
            </Menu>

        }>
            <Button minimal icon={imgbuild } intent={warnSysMain ? Intent.WARNING : "none"} text="System Maintenance" />
        </Popover>



    let showuserId = <>
        User ID: { userId }
    </>

    const changeUserLanguage = (lng: string) => {
        (async () => {
            try {
                if (isLoggedIn) {
                    //await svc.post('/userupdatecurrent', {currentLang: lng});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let lngList = [
        {lngName: "English", lng: "en"},
        {lngName: "繁體中文", lng: "zh-HK"},
        {lngName: "简体中文", lng: "zh-CN"},
    ]


    return (
        <>
            <Navbar className="No-Print">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading><AnchorButton minimal icon={imgLogo} text="GSBx" href="#/"/></Navbar.Heading>
                    <Navbar.Divider />
                    {isLoggedIn ? menuMaster : null}
                    {isLoggedIn && showHKIDR1 && showHKIDR2 ? menuHKIDR : null}
                    {isLoggedIn && GSBXHKCRS ? menuHKCRS : null }
                    {isLoggedIn && showDividendProcess ? menuDividendProcess : null}
                    {isLoggedIn ? menuTransaction : null}
                    {isLoggedIn ? menuReport : null}
                    {isLoggedIn ? menuSysMain : null}
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    {/*
                    <BPSwitch style={{marginTop: '8px'}} checked={isDark} label="Dark mode" onChange={() => {
                        let newMode = !isDark
                        let body = document.getElementById("body")
                        if (newMode) {
                            body?.classList.add("bp4-dark")
                        } else {
                            body?.classList.remove("bp4-dark")
                        }
                        setIsDark(newMode)
                    }} />
                    <Navbar.Divider />
                    */}
                    
                    <div><b>GSBx</b> {Sys_VerNo}</div>
                    <Navbar.Divider />
                    {isLoggedIn && <div > {showuserId} </div>} 
                    <MenuDivider />
                    {/*
                    <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {lngList.map((lng) => <MenuItem key={lng.lng} icon="translate" text={lng.lngName} onClick={() => {i18n.changeLanguage(lng.lng); changeUserLanguage(lng.lng)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon="translate" text="Language/語言" />
                    </Popover>
                    */}
                    {isLoggedIn && <Tooltip content="Toggle system status window"><Button minimal icon={imgChecklist} onClick={onSystemStatusClick} /></Tooltip>}
                    {isLoggedIn && <Link target="_blank" to="/tasks"><Tooltip content="Tasks"><Button minimal icon={imgTask} /></Tooltip></Link>}
                    {isLoggedIn && <Link to="/login"><Tooltip content="Logout"><Button minimal icon={imgUser} /></Tooltip></Link>}
                </Navbar.Group>
            </Navbar>
        </>
    )
}

interface MenuBarProps {
    isLoggedIn?: boolean
    onSystemStatusClick?: () => void
}


function MenuBar_ce({ isLoggedIn = false } : MenuBar_ceProps) {
    const [showClMast, setshowClMast] = useState(true)
    const [showClHistory, setshowClHistory] = useState(true)

    const [warnSysMain, setwarnSysMain] = useState(false)
    const [warnAbout, setwarnAbout] = useState(false)

    const [Sys_VerNo, setSys_VerNo] = useState("")
    const [userKey, setuserKey] = useState("DEMO")
    const [userId, setuserId] = useState("")
    const [RecordPgmName, setRecordPgmName] = useState("")
    const [IPAddr, setIPAddr] = useState("")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [MULTI_MKT, setMULTI_MKT] = useState(false)
    const [SING_CURR, setSING_CURR] = useState(false)

    useEffect(() => {
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/GsbFlag');
                    //console.log("MenuBar_ce disp user_key: ", res.data.Flag.user_key)
                    GsbFlag = res.data.Flag;
                    //console.log("MenuBar_ce disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                    setSys_VerNo("v"+GsbFlag.Sys_VerNo) 
                    setuserKey(GsbFlag.user_key)
                    setuserId( GsbFlag.UserID)
                    if ( GsbFlag.isProduction == "Y" ) {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                    } else {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                    }

                    setMULTI_MKT(GsbFlag.MULTI_MKT)
                    setSING_CURR(GsbFlag.SING_CURR)

                } catch (error) {
                    utils.showError(error)
                }
            })()
        } else {
            (async () => {
                try {
                    const res = await gsb.post('/GsbFlag0');
                    setSys_VerNo("v"+res.data.Flag.Sys_VerNo)
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, isLoggedIn])


    useEffect(() => {
        setwarnSysMain(false)
        setwarnAbout(false)

        ;
        if ( isLoggedIn ) {
            (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "GetIconWarnInfo",
                });
                if ( res.data.ok ) {
                    //console.log("GetIconWarnInfo res.data.data", res.data.data)
                    setwarnSysMain(res.data.data[0].warnAbout) 
                    setwarnAbout(res.data.data[0].warnAbout) 
          

                }
            } catch (error) {
                utils.showError(error)
            }
        })()


        }
    }, [gsb, isLoggedIn, userId, GsbFlag, Sys_VerNo])
    

    useEffect(() => {
        //console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }
    
    
    }, [gsb, isLoggedIn, userId, GsbFlag, cardOpen01])
    

    useEffect(() => {
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: IPAddr,
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])
    

    const imgLogo = <img className="AppLogo" src={svgLogo} alt="Logo" />
    const imgAccount = <img className="MenuIcon" src={svgAccount} alt="Account" />
    const imgMaster = <img className="MenuIcon" src={svgMaster} alt="Master" />
    const imgDocument = <img className="MenuIcon" src={svgDocument} alt="Document" />
    const imgzipDocument = <img className="MenuIcon" src={svgZip} alt="Zipdocument" />
    const imgTransaction = <img className="MenuIcon" src={svgInput} alt="Transaction" />
    const imgImport = <img className="MenuIcon" src={svgImport} alt="Import" />
    const imgUser = <img className="MenuIcon" src={svgUser} alt="Login" />
    const imgTask = <img className="MenuIcon" src={svgTask} alt="Task" />
    const imgbuild = <img className="MenuIcon" src={svgbuild} alt="Utility" />
    const imgsort_asc = <img className="MenuIcon" src={svgsort_asc} alt="sort" />
    const history = useHistory()

        const FreemenuMaster =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showClMast &&
                    <MenuItem icon={imgAccount} text="Client Master">
                        { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="25 Client Master List" onClick={() => { setRecordPgmName("25 ClACList") }} href="#/ClACList" /> }
                        <MenuItem icon={imgDocument} text="Client List for SFC" onClick={() => { setRecordPgmName("ClACListSfc") }} href="#/ClACListSfc" />
                    </MenuItem>
                }
            </Menu>
        }>
            <Button minimal icon={imgMaster} text="Master" />
        </Popover>

        const menuReport =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="312 Client Transaction Check List" onClick={() => { setRecordPgmName("312 ClientTranCheckList") }} href="#/ClientTranCheckList" /> }
                { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="74 Stock Holder List" onClick={() => { setRecordPgmName("74 StkHoldList") }} href="#/StkHoldList" /> }
                { showClHistory && userKey !== "COMPSHARE" &&
                    <MenuItem icon={imgDocument} text="29 Client History Report" onClick={() => { setRecordPgmName("29 Client History") }} href="#/ClHistory" />
                }
            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="Report" />
        </Popover>


        const menuSysMain =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                {/* */}
                { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="GSB Configuration" onClick={() => { setRecordPgmName("GsbxConfig") }} href="#/GsbxConfig" /> }
                { userKey == "COMPSHARE" && <MenuItem disabled={false} icon={imgsort_asc  } text="Reindex (Not All Index)" onClick={() => { setRecordPgmName("Reindex") }} href="#/SBReindex" /> }
                { userKey == "COMPSHARE" && <MenuDivider /> }
                { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="Login Log" onClick={() => { setRecordPgmName("LoginLog") }} href="#/LoginLog" /> }
                <MenuItem icon={imgDocument} text="Reset Process" onClick={() => { setRecordPgmName("Resetprocess") }} href="#/Resetprocess" />
                <MenuItem icon={imgDocument} text="GSB Status" onClick={() => { setRecordPgmName("SB Status") }} href="#/SBstatus" />
                <MenuItem icon={imgDocument} text="GSB System Module" onClick={() => { setRecordPgmName("GSB System Module") }} href="#/SBModule" />
                <MenuDivider />
                <MenuItem icon={imgDocument} intent={warnAbout ? Intent.WARNING : "none"} text="About" onClick={() => { setRecordPgmName("About") }} href="#/About" />
                { userKey == "COMPSHARE" && <MenuItem icon={imgDocument} text="Temp Change Field Length" onClick={() => { setRecordPgmName("Temp Change Field Length") }} href="#/TempChgField" />  }
                <MenuItem icon={imgDocument} text="Log Out" onClick={() => { setRecordPgmName("Log Out") }} href="#/login" />
            </Menu>

        }>
            <Button minimal icon={imgbuild } intent={warnSysMain ? Intent.WARNING : "none"} text="System Maintenance" />
        </Popover>
        

    let showuserId = <>
        User ID: { userId }
    </>

    const changeUserLanguage = (lng: string) => {
        (async () => {
            try {
                if (isLoggedIn) {
                    //await svc.post('/userupdatecurrent', {currentLang: lng});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let lngList = [
        {lngName: "Eng", lng: "en"},
        {lngName: "繁體", lng: "zh-HK"},
        {lngName: "简体", lng: "zh-CN"},
    ]

    return (
        <>
            <Navbar className="No-Print">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading><Link to="/"><Button minimal icon={imgLogo} text="GSBce" /></Link></Navbar.Heading>
                    <Navbar.Divider />
                    {isLoggedIn ? FreemenuMaster : null}
                    {isLoggedIn ? menuReport : null}
                    {isLoggedIn ? menuSysMain : null}
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>

                    <Navbar.Divider />
                    <div><b>GSBce</b> {Sys_VerNo}</div>      
                    <Navbar.Divider />
                    {isLoggedIn && <div > {showuserId} </div>} 
                    <MenuDivider />
                    {/*
                    <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {lngList.map((lng) => <MenuItem key={lng.lng} icon="translate" text={lng.lngName} onClick={() => {i18n.changeLanguage(lng.lng); changeUserLanguage(lng.lng)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon="translate" text="Language/語言" />
                    </Popover>
                    */}
                    {isLoggedIn && <Link target="_blank" to="/tasks"><Tooltip content="Tasks"><Button minimal icon={imgTask} /></Tooltip></Link>}
                    {isLoggedIn && <Link to="/login"><Tooltip content="Logout"><Button minimal icon={imgUser} /></Tooltip></Link>}
                </Navbar.Group>
            </Navbar>
        </>
    )
}

interface MenuBar_ceProps {
    isLoggedIn?: boolean
}


function MenuBar_HKIDR({ isLoggedIn = false } : MenuBar_HKIDRProps) {

    const [showSysAdmin, setshowSysAdmin] = useState(false)
    const [showHKIDRUser, setshowHKIDRUser] = useState(false)

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [warnSysMain, setwarnSysMain] = useState(false)
    const [warnAbout, setwarnAbout] = useState(false)
    const [warnGSBchgLog, setwarnGSBchgLog] = useState(false)

    const [showEtTradeList, setshowEtTradeList] = useState(false)
    const [showeBrokerList, setshoweBrokerList] = useState(false)
    const [showAyersList, setshowAyersList] = useState(false)

    const [showInfocastTradeList, setshowInfocastTradeList] = useState(false)
    const [showFINI,setshowFINI] = useState(false)
    const [Sys_VerNo, setSys_VerNo] = useState("")
    const [userKey, setuserKey] = useState("DEMO")
    const [userId, setuserId] = useState("")
    const [RecordPgmName, setRecordPgmName] = useState("")
    const [IPAddr, setIPAddr] = useState("")

    const [MULTI_MKT, setMULTI_MKT] = useState(false)
    const [SING_CURR, setSING_CURR] = useState(false)

    const [showUserSetup, setshowUserSetup] = useState(false)  
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [CM2K_USER, setCM2K_USER] = useState(false)
    const [IMP_CL_Mast1, setIMP_CL_Mast1] = useState(false)
    const [GSBXHKCRS,setGSBXHKCRS] = useState(false)
    const [CNBCAN,setCNBCAN] = useState(false)
    
    useEffect(() => {
        if ( isLoggedIn ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/GsbFlag');
                    console.log("MenuBar_HKIDR disp user_key: ", res.data.Flag.user_key)
                    GsbFlag = res.data.Flag;
                    //console.log("MenuBar_HKIDR disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                    setSys_VerNo("v"+GsbFlag.Sys_VerNo)
                    setuserKey(GsbFlag.user_key)
                    setuserId( GsbFlag.UserID)
                    setMULTI_MKT(GsbFlag.MULTI_MKT)
                    setSING_CURR(GsbFlag.SING_CURR)
                    setCNBCAN(GsbFlag.CNBCAN)
                    setshowUserSetup(! GsbFlag.MU_FLAG)
                    if ( GsbFlag.isProduction == "Y" ) {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                    } else {
                        setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                    }

                    setCM2K_USER( GsbFlag.CM2K_USER);
                } catch (error) {
                    utils.showError(error)
                }
            })()
        } else {
            (async () => {
                try {
                    const res = await gsb.post('/GsbFlag0');
                    setSys_VerNo("v"+res.data.Flag.Sys_VerNo)
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, isLoggedIn])


    useEffect(() => {
        setshowSysAdmin(false)
        setshowHKIDRUser(false)

        ;
        if ( isLoggedIn ) {
            
            (async () => {
                try {
                    // get UserAccessGroupInfo
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            cUserid2: userId,
                        },
                        cPgmId: "UserAccessGroup",
                        cAction: "GetUserAccessGroupInfo",
                        cUserid2: userId,
                    });
                    //console.log("GetUserAccessGroupInfo res.data.data", res.data.data)
                    if ( res.data.count > 0 ) {
                        setshowSysAdmin( res.data.data[0].d_User_Group1=="Y")
                        setshowHKIDRUser( res.data.data[0].d_User_Group2=="Y")
                    }


                } catch (error) {
                    utils.showError(error)
                }
            })()


            //setshowSysAdmin(true)
            //setshowHKIDRUser(true)

        }
    }, [gsb, isLoggedIn, userId, GsbFlag])


    useEffect(() => {
        setwarnSysMain(false)
        setwarnAbout(false)
        setwarnGSBchgLog(false)

        ;
        if ( isLoggedIn ) {
            (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "GetIconWarnInfo",
                });
                if ( res.data.ok ) {
                    //console.log("GetIconWarnInfo res.data.data", res.data.data)
                    setwarnSysMain(res.data.data[0].warnAbout) 
                    setwarnAbout(res.data.data[0].warnAbout) 
                    //setwarnGSBchgLog(res.data.data[0].warnGSBchgLog) 
          

                }
            } catch (error) {
                utils.showError(error)
            }
        })()


        }
    }, [gsb, isLoggedIn, userId, GsbFlag, Sys_VerNo])


    useEffect(() => {
        //console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, isLoggedIn, userId, GsbFlag, cardOpen01])


    useEffect(() => {
        setshowEtTradeList(false)
        setshoweBrokerList(false)
        setshowAyersList(false)
        setshowInfocastTradeList(false)
        if ( isLoggedIn ) {
            if ( userKey === "DEMO" || userKey ==="TUNLIN" || userKey === "YFSEC" || userKey === "TAIFUNG" || userKey === "PASAY" || userKey === "FREDDY" || userKey === "SOOPC"  || userKey === "SUNCAPIT" || userKey ==="AGINCOURT" || userKey ==="GRANDFT" || userKey ==="HENYEP" || userKey === "BERICH" ) {
                setshowEtTradeList(true)
            }
          
            //console.log("MenuBar_HKIDR disp 2 userKey: ", userKey)
            //console.log("MenuBar_HKIDR disp showEtTradeList: ", showEtTradeList)
        }

        if ( isLoggedIn ) {
            if ( userKey === "DEMO" || userKey === "BERICH" || userKey === "HEADNS" || userKey ==="TUNGTAI" ) {
                setshoweBrokerList(true)
            }
            //console.log("MenuBar_HKIDR disp 2 userKey: ", userKey)
            //console.log("MenuBar_HKIDR disp setshoweBrokerList: ", setshoweBrokerList)
        }

        if ( isLoggedIn ) {
            if ( userKey === "DEMO" || userKey === "MINGFUNG"  || userKey=== "TAITACK" || userKey ==="TAITAK") {
                setshowAyersList(true)
            }
        }
        if ( isLoggedIn ) {
            if ( userKey === "DEMO" || userKey === "TARZAN" || userKey ==="PROMISING" || userKey ==="HANGTAI"  || userKey === "NEWREGION") {
                setshowInfocastTradeList(true)
            }
        }
        if ( isLoggedIn ) {
            if (CM2K_USER== true){
                setshowFINI(true)
            }
        }


        if ( isLoggedIn ) {
            if ( userKey === "DEMO" || userKey === "MINGFUNG" ) {
                setIMP_CL_Mast1(true)
            }
            else
            {
                setIMP_CL_Mast1(false)
            }

        }

        if ( isLoggedIn ) {
            if ( userKey === "DEMO" ) {
                setGSBXHKCRS(true)
                setCNBCAN(true)
            }
            else
            {
                setGSBXHKCRS(false)
                setCNBCAN(false)
            }
        }


    }, [gsb, isLoggedIn, userKey, GsbFlag])


    useEffect(() => {
        if ( isLoggedIn && RecordPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: RecordPgmName,
                        cIPAddr: IPAddr,
                    });
                    setRecordPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, RecordPgmName, isLoggedIn])
    

    const imgLogo = <img className="AppLogo" src={svgLogo} alt="Logo" />
    const imgAccount = <img className="MenuIcon" src={svgAccount} alt="Account" />
    const imgMaster = <img className="MenuIcon" src={svgMaster} alt="Master" />
    const imgDocument = <img className="MenuIcon" src={svgDocument} alt="Document" />
    const imgzipDocument = <img className="MenuIcon" src={svgZip} alt="Zipdocument" />
    const imgTransaction = <img className="MenuIcon" src={svgInput} alt="Transaction" />
    const imgImport = <img className="MenuIcon" src={svgImport} alt="Import" />
    const imgUser = <img className="MenuIcon" src={svgUser} alt="Login" />
    const imgTask = <img className="MenuIcon" src={svgTask} alt="Task" />
    const imgbuild = <img className="MenuIcon" src={svgbuild} alt="Utility" />
    const imgsort_asc = <img className="MenuIcon" src={svgsort_asc} alt="Sort" />
    const history = useHistory()

        const menuMaster =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showHKIDRUser &&
                    <MenuItem icon={imgAccount} text="HKIDR Master">
                        <MenuItem icon={imgAccount} text="Import Client from GSB (New Client)" onClick={() => { setRecordPgmName("Import Client from GSB (New Client)") }} href="#/HkidrImpAccount" />
                        <MenuDivider />

                        { ( IMP_CL_Mast1 ) && <MenuItem icon={imgImport} text="Import Client TAB from Text File" onClick={() => { setRecordPgmName("21I Import Client Master from Text File") }} href="#/ImportClMast1" />  }
                       
                        <MenuItem icon={imgAccount} text="HKIDR Master" onClick={() => { setRecordPgmName("HKIDR Master") }} href="#/HkidrMast" />
                        {/*
                        <MenuItem icon={imgAccount} text="Replicate Account Holder (Join Account)" onClick={() => { setRecordPgmName("Replicate HKIDR Account") }} href="#/WIP" />
                        <MenuItem icon={imgAccount} text="Delete Account Holder" onClick={() => { setRecordPgmName("Delete HKIDR Account") }} href="#/WIP" />
                        */}
                        <MenuItem icon={imgDocument} text="HKIDR Master List" onClick={() => { setRecordPgmName("HKIDR Master List") }} href="#/HkidrMastList" />
                        <MenuItem icon={imgDocument} text="HKIDR Master Audit Log" onClick={() => { setRecordPgmName("HKIDR Audit Log")}} href="#/HkidrLog" />
                        <MenuItem icon={imgDocument} intent={warnGSBchgLog ? Intent.WARNING : "none"} text="GSB Client Name Change Log" onClick={() => { setRecordPgmName("GSB Client Name Change Log") }} href="#/GsbNameChangeLog" />
                        <MenuDivider />
                        <MenuItem icon={imgAccount} text="Import HK Consent Status (CSV)" onClick={() => { setRecordPgmName("Import HK Consent Status") }} href="#/importHkconsentCSV" />
 
                        <MenuDivider />
                        {/*
                        <MenuItem icon={imgAccount} text="Generate CNBcan" onClick={() => { setRecordPgmName("Generate CNBcan") }} href="#/CNBcanGen" />
                        */}
                       {/* <MenuItem icon={imgAccount} text="Export Hk Consent Status for Mail Merge (CSV)" onClick={() => { setRecordPgmName("Export Hk Consent Status") }} href="#/ExportHkConsent" />*/}
                    </MenuItem>
                }
            </Menu>
        }>
            <Button minimal icon={imgMaster} text="Master" />
        </Popover>
        
        const menuReport =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showHKIDRUser &&
                    <MenuItem icon={imgDocument} text="Print HK Consent" onClick={() => { setRecordPgmName("Print HK RConsent") ; history.push("/HkidrHkConsent") }} href="#/HkidrHkConsent" />
                }
                { showHKIDRUser &&
                    <MenuItem icon={imgAccount} text="Export HK Consent Status for Mail Merge (CSV)" onClick={() => { setRecordPgmName("Export HK Consent Status") ; history.push("/ExportHkConsent") }} href="#/ExportHkConsent" />
                }
               {/*<MenuDivider />
               <MenuItem icon={imgDocument} text="Print China Consent" onClick={() => { setRecordPgmName("Print HK RConsent") }} href="#/WIP" />
               <MenuItem icon={imgAccount} text="Export china Consent Status for Mail Merge (CSV)" onClick={() => { setRecordPgmName("Export Hk Consent Status") }} href="#/WIP" />
				*/}
            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="Print / export consent" />
        </Popover>

        const menuBCANExportResult =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                { showHKIDRUser &&
                    <MenuItem icon={imgDocument} text="HKBCAN-CID">
                        <MenuItem icon={imgDocument} text="Export HKBCAN-CID Mapping File" onClick={() => { setRecordPgmName("Export HKBCAN Mapping File") }} href="#/HkidrBcanMapping" />
                        <MenuDivider />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID Response File" onClick={() => { setRecordPgmName("Read HKBCAN Response File") }} href="#/HkidrResponseFile" />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID Process Result File" onClick={() => { setRecordPgmName("Read HKBCAN Result File") }} href="#/HkidrProcessResultFile" />
                        <MenuItem icon={imgDocument} text="Read HKBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") }} href="#/HkidrFullImageFile" />
        
                        {/*
                        <MenuItem icon={imgDocument} text="HKBCAN-CID Aggregated Transaction">
                        <MenuItem icon={imgDocument} text="Read Response File" onClick={() => { setRecordPgmName("Read HKBCAN Aggregated Response File") ; history.push("/WIP") }} />
                        <MenuItem icon={imgDocument} text="Read Result File" onClick={() => { setRecordPgmName("Read HKBCAN Aggregated Result File") ; history.push("/WIP") }} />        
                        </MenuItem>

                        <MenuItem icon={imgDocument} text="HKBCAN-CID Change">
                        <MenuItem icon={imgDocument} text="Read Response File" onClick={() => { setRecordPgmName("Read HKBCAN Change Response File") ; history.push("/WIP") }} />
                        <MenuItem icon={imgDocument} text="Read Result File" onClick={() => { setRecordPgmName("Read HKBCAN Change  Result File") ; history.push("/WIP") }} />
                        </MenuItem>

                
                        <MenuItem icon={imgDocument} text="HKBCAN-CID Error">
                        <MenuItem icon={imgDocument} text="Read Response File" onClick={() => { setRecordPgmName("Read HKBCAN Error Response Fie") ; history.push("/WIP") }} />
                        <MenuItem icon={imgDocument} text="Read Result File" onClick={() => { setRecordPgmName("Read HKBCAN Error Result Fie") ; history.push("/WIP") }} />                    
                        </MenuItem>             
                        <MenuItem icon={imgDocument} text="HKBCAN-CID Off-exchange ">

                        <MenuItem icon={imgDocument} text="Read Trade BCAN (Buy-side) Report Response File" onClick={() => { setRecordPgmName("Read HKBCAN Off-exchange Trade BCAN (Buy-side) Report Response File") ; history.push("/WIP") }} />
                        <MenuItem icon={imgDocument} text="Read Trade BCAN (Buy-side) Report Result File" onClick={() => { setRecordPgmName("Read HKBCAN Off-exchange Trade BCAN (Buy-side) Report Result File") ; history.push("/WIP") }} />
                        </MenuItem>
                        */}
                        { ( showEtTradeList || showeBrokerList || showInfocastTradeList) &&
                            <MenuDivider />
                        }
                        { showEtTradeList &&
                            <MenuItem icon={imgDocument} text="Export HKIDR List for ET Trade" onClick={() => { setRecordPgmName("Export HKIDR List for ETTrade") }} href="#/HkidrETTradeList" />
                        }
                        { showeBrokerList &&
                            <MenuItem icon={imgDocument} text="Export HKIDR List for eBroker" onClick={() => { setRecordPgmName("Export HKIDR List for eBroker") }} href="#/HkidreBrokerList" />
                        }
                        { showAyersList &&
                            <MenuItem icon={imgDocument} text="Export HKIDR List for Ayers" onClick={() => { setRecordPgmName("Export HKIDR List for eBroker") }} href="#/HkidrAyersList" />
                        }
                        { showInfocastTradeList &&
                            <MenuItem icon={imgDocument} text="Export HKIDR List for Infocast" onClick={() => { setRecordPgmName("Export HKIDR List for Infocast") }} href="#/HkidrInfocastList" />
                        }                        
                    </MenuItem>
                }
                {CNBCAN && <MenuDivider />}
                {CNBCAN  &&  <MenuItem icon={imgDocument} text="CNBCAN-CID">
                    <MenuItem icon={imgDocument} text="Export CNBCAN-CID Mapping File" onClick={() => { setRecordPgmName("Export CNBCAN-CID Mapping File") }} href="#/CNBcanMapping" />                    
                    <MenuDivider />
                    <MenuItem icon={imgDocument} text="Read CNBCAN-CID Response File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Response File") }} href="#/CNBcanResponseFile" />
                    <MenuItem icon={imgDocument} text="Read CNBCAN-CID Authorisied TTEP List Response File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Authorisied TTEP List Response File") }}href="#/CNBcanAurpResponseFile"  />
                    <MenuItem icon={imgDocument} text="Read CNBCAN-CID validation result File" onClick={() => { setRecordPgmName("Read CNBCAN-CID validation result File") }} href="#/CNBcanRsltResultFile" />
                    <MenuItem icon={imgDocument} text="Read CNBCAN-CID  Full Image Reconcile File" onClick={() => { setRecordPgmName("Read CNBCAN-CID Full Image File") }} href="#/CNBcanFimgFile" />
                </MenuItem>
                }

            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="BCAN-CID Mapping/Result File" />
        </Popover>



        const menuOtcrExportResult =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>          
           
             
                <MenuItem icon={imgDocument} text="Export OTCR Report File (D/W)" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W)") }} href="#/HkidrOtcr" >
                </MenuItem>
                <MenuItem icon={imgDocument} text="Export OTCR Report File (D/W)in History" onClick={() => { setRecordPgmName("Export OTCR Report File (D/W)in History") }} href="#/HkidrOtcr2" >
                </MenuItem>
       
                {/*
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID Response File" onClick={() => { setRecordPgmName("Read HKBCAN Response File") ; history.push("/WIP") }} />
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID Process Result File" onClick={() => { setRecordPgmName("Read HKBCAN Result File") ; history.push("/WIP") }} />
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") ; history.push("/WIP") }} />
                */}
            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="OTCR" />
        </Popover>


        const menuFiniExportResult =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>          
        

 
                 <MenuItem icon={imgDocument} text="Export Fini Report File" onClick={() => { setRecordPgmName("Export Fini Report File") }} href="#/ExportEIPO" >
                </MenuItem> 
                <MenuItem icon={imgDocument} text="Read Fini EIPO Authorised Csv Report File" onClick={() => { setRecordPgmName("Fini EIPO Authorised Csv Report File") }} href="#/EIPOAuthorisedFile" >
                </MenuItem> 
                <MenuItem icon={imgDocument} text="Read Fini EIPO Allotment Csv Report File" onClick={() => { setRecordPgmName("Fini EIPO Allotment Csv Report File") }} href="#/EIPOAllotmentResultFile" >
                </MenuItem> 
                {/*
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID Response File" onClick={() => { setRecordPgmName("Read HKBCAN Response File") ; history.push("/WIP") }} />
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID Process Result File" onClick={() => { setRecordPgmName("Read HKBCAN Result File") ; history.push("/WIP") }} />
                <MenuItem icon={imgDocument} text="Read CNBCAN-CID BCAN Full Image Reconcile File" onClick={() => { setRecordPgmName("Read HKBCAN Full Image File") ; history.push("/WIP") }} />
                */}
            </Menu>
        }>
            {/* remark */}
            <Button minimal icon={imgDocument} text="Fini" />
        </Popover>

        const menuSysMain =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                {/* */}
                {/* CheckPgmAccess("S0003") && <MenuItem disabled={false} icon={imgsort_asc  } text="System Upgrade, Reindex" onClick={() => { setRecordPgmName("System Upgrade, Reindex") }} href="#/SBReindex" /> */}
                <MenuDivider />
                { showSysAdmin &&
                    <MenuItem icon={imgDocument} text="BCAN Configuration" onClick={() => { setRecordPgmName("BcanConfig") }} href="#/BcanConfig" />
                }
                { showSysAdmin &&
                    <MenuDivider />
                }
                <MenuItem icon={imgDocument} text="Reset Process" onClick={() => { setRecordPgmName("Resetprocess") }} href="#/Resetprocess" />
                <MenuItem icon={imgDocument} text="GSB Status" onClick={() => { setRecordPgmName("SB Status") }} href="#/SBstatus" />
                { showSysAdmin && showUserSetup &&
                    <MenuItem icon={imgAccount} text="User Master">
                        <MenuItem icon={imgDocument} text="User Master Setup" onClick={() => { setRecordPgmName("User Master Setup") }} href="#/UserMast" />
                        <MenuItem icon={imgDocument} text="User List" onClick={() => { setRecordPgmName("User List") }} href="#/UserMastList" />
                        <MenuItem icon={imgDocument} text="User Audit Log" onClick={() => { setRecordPgmName("User Audit Log") }} href="#/UserMastLog" />
                        <MenuItem icon={imgDocument} text="Reset User Password" onClick={() => { setRecordPgmName("Reset User Password") }} href="#/UserchgPasswd2" />
                        <MenuItem icon={imgDocument} text="User Access Group" onClick={() => { setRecordPgmName("User Access Group") }} href="#/UserAccessGroup" />
                </MenuItem> }
                { showSysAdmin && ! showUserSetup &&
                    <MenuItem icon={imgDocument} text="User Access Group" onClick={() => { setRecordPgmName("User Access Group") }} href="#/UserAccessGroup" />
                }
                { showUserSetup &&
                    <MenuItem icon={imgDocument} text="Change User Password" onClick={() => { setRecordPgmName("Change User Password") }} href="#/UserchgPasswd" />
                }
                <MenuItem icon={imgDocument} intent={warnAbout ? Intent.WARNING : "none"} text="About" onClick={() => { setRecordPgmName("About") }} href="#/About" />
                { showSysAdmin && <MenuItem icon={imgDocument} text="Temp Change Field Length" onClick={() => { setRecordPgmName("Temp Change Field Length") }} href="#/TempChgField" />  }
                <MenuItem icon={imgDocument} text="Log Out" onClick={() => { setRecordPgmName("Log Out") }} href="#/login" />
            </Menu>

        }>
            <Button minimal icon={imgbuild } intent={warnSysMain ? Intent.WARNING : "none"} text="System Maintenance" />
        </Popover>

    let showuserId = <>
        User ID: { userId }
    </>

    const changeUserLanguage = (lng: string) => {
        (async () => {
            try {
                if (isLoggedIn) {
                    //await svc.post('/userupdatecurrent', {currentLang: lng});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let lngList = [
        {lngName: "Eng", lng: "en"},
        {lngName: "繁體", lng: "zh-HK"},
        {lngName: "简体", lng: "zh-CN"},
    ]

    return (
        <>
            <Navbar className="No-Print">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading><Link to="/"><Button minimal icon={imgLogo} text="HKIDR" /></Link></Navbar.Heading>
                    <Navbar.Divider />
                    {isLoggedIn && showHKIDRUser ? menuMaster : null}
                    {isLoggedIn && showHKIDRUser ? menuReport : null}
                    {isLoggedIn && showHKIDRUser ? menuBCANExportResult : null}
                    { isLoggedIn && showHKIDRUser ? menuOtcrExportResult : null}                    
                    { isLoggedIn && showHKIDRUser && showFINI && GsbFlag.user_key !== "WINGHANG" ? menuFiniExportResult : null}  
                    {isLoggedIn ? menuSysMain : null}
                </Navbar.Group>


                <Navbar.Group align={Alignment.RIGHT}>
                    
                    <div><b>HKIDR</b> {Sys_VerNo}</div>      
                    <Navbar.Divider />
                    {isLoggedIn && <div > {showuserId} </div>} 
                    <MenuDivider />
                    {/*
                    <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {lngList.map((lng) => <MenuItem key={lng.lng} icon="translate" text={lng.lngName} onClick={() => {i18n.changeLanguage(lng.lng); changeUserLanguage(lng.lng)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon="translate" text="Language/語言" />
                    </Popover>
                    */}
                    {isLoggedIn && <Link to="/login"><Button minimal icon={imgUser} /></Link>}
                </Navbar.Group>
            </Navbar>
        </>
    )
}

interface MenuBar_HKIDRProps {
    isLoggedIn?: boolean
}


export default App;
