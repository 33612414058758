import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, Button, HTMLTable, Position, Toaster, Intent, IconName, MaybeElement, Spinner } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import svgWait2 from './svg/wait2.gif'
//import tick_circle from './svg/tick-circle.svg'
import tick_circle from './svg/checked.svg'
import flow_linear from './svg/flow-linear.svg'
import svgNotStarted from './svg/dash.svg'
import svgComleted from './svg/checked.svg'

import { SystemHeader } from './CommonUI';


var GsbFlag: any;

interface SBstatusMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function SBstatusMain({ gsb }: SBstatusMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgtick_circle = <img className="tickIcon" src={tick_circle} alt="completed" />
    const imgflow_linear = <img src={flow_linear} alt="Progressing" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />


    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [Sys_Start, setSys_Start] = useState(0)
    const [Sys_End, setSys_End] = useState(0)
    const [Trial_Match, setTrial_Match] = useState(0)
    const [Daily_Backup, setDaily_Backup] = useState(0)
    const [Batch_Running, setBatch_Running] = useState(0)
    const [Batch_Porcessing, setBatch_Porcessing] = useState("")
    const [LuDate, setLuDate] = useState("")
    const [Refresh_min, setRefresh_min] = useState(0)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, Sys_Start])

    
    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    const refreshTasks = async () => {
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)

        try {
            const res = await gsb.post('/SBstatus' )
            if ( res.data.count > 0 ) {
                //console.log("res.data.data: ", res.data.data) 
                //console.log("res.data.data Sys_Start: ", res.data.data[0].Sys_Start) 
                setSys_Start(res.data.data[0].Sys_Start)
                setSys_End(res.data.data[0].Sys_End)
                setTrial_Match(res.data.data[0].Trial_Match)
                setDaily_Backup(res.data.data[0].Daily_Backup)
                setBatch_Running(res.data.data[0].Batch_Running)
                setBatch_Porcessing(res.data.data[0].Batch_Porcessing)
                //console.log("res.data.data LuDate: ", res.data.now) 
                setLuDate(res.data.LuTime)

                setShowWait(false)
                setShowNoRecord(false)
                setShowRpt(true)
            } else {
                setShowWait(false)
                setShowNoRecord(true)
                setShowRpt(false)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    
    useEffect(() => {
        //console.log("/SBstatus ") 
        refreshTasks()
    }, [gsb])


    useEffect(() => {
        //console.log("/SBstatus ") 
        let timer = setInterval(refreshTasks, 120*60*1000)
        setRefresh_min(120)
        if ( Batch_Running == 1 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 1*60*1000)
            setRefresh_min(1)
        } else if ( Batch_Running == 2 || Daily_Backup == 0 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 120*60*1000)
            setRefresh_min(120)
        } else if ( Daily_Backup == 2 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 2*60*1000)
            setRefresh_min(2)
        } else if ( Trial_Match == 2 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 3*60*1000)
            setRefresh_min(3)
        }
            return () => {clearInterval(timer)
        }
    }, [gsb, Daily_Backup, Sys_End, Batch_Running, Refresh_min])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setRefresh_min(0)
        refreshTasks()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

 
    let list = <>
        <div className="Spacing-V-16" />
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    <th ></th>
                    <th ></th>
                    <th >Status</th>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>System Start</td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Sys_Start}</td>
                    { (Sys_Start == 1) && <td >{imgflow_linear} Running</td> }
                    { (Sys_Start == 2) && <td >{imgtick_circle}</td> }
                </tr>
                <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>Trial Match</td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Trial_Match}</td>
                    { (Trial_Match == 1) && <td >{imgflow_linear} Running</td> }
                    { (Trial_Match == 2) && <td >{imgtick_circle}</td> }
                </tr>
                <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>Daily Backup</td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Daily_Backup}</td>
                    { (Daily_Backup == 1) && <td >{imgflow_linear} Running</td> }
                    { (Daily_Backup == 2) && <td >{imgtick_circle}</td> }
                </tr>
                <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>System End</td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Sys_End}</td>
                    { (Sys_End == 1) && <td >{imgflow_linear} Running</td> }
                    { (Sys_End == 2) && <td >{imgtick_circle}</td> }
                    {/*
                    { (Sys_End == 2) && <td ><Callout icon="tick" intent={Intent.SUCCESS}></Callout></td> }
                    */}
                </tr>
                {/*
                <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>Batch Running</td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Batch_Running}</td>
                    { (Batch_Running == 1) && <td >{imgflow_linear} Running</td> }
                    { (Batch_Running == 2) && <td >{imgtick_circle}</td> }
                </tr>
                */}
                {/* (Batch_Running == 1) && <tr onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>Running at </td>
                    <td ></td>
                    <td className="RCol-Rpt" onClick={() => {  }}>{Batch_Porcessing}</td>
                    </tr>
                */}
           </tbody>

            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            Last Update Time : {LuDate}
            <div className="Spacing-V-16" />
            * Auto Refresh every {Refresh_min} Mins
        </HTMLTable>
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <Button type="submit">Refresh</Button>
                    </div>
                    <H3>GSB System Status</H3>
                    <div className="Spacing-V-16" />
                </Card>
                {ShowWait && <div>{imgWait}</div> }
                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">File SBSTATUS.LOG cannot be Open</span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
            </form>
        </>
    );
}

export function SBstatusMini({ gsb }: SBstatusMainProps) {
    
    
    const imgtick_circle = <img className="tickIcon" src={tick_circle} alt="completed" />
    const imgflow_linear = <img src={flow_linear} alt="Progressing" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />


    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [Sys_Start, setSys_Start] = useState(0)
    const [Sys_End, setSys_End] = useState(0)
    const [Trial_Match, setTrial_Match] = useState(0)
    const [Daily_Backup, setDaily_Backup] = useState(0)
    const [Batch_Running, setBatch_Running] = useState(0)
    const [Batch_Processing, setBatch_Processing] = useState("")
    const [LuDate, setLuDate] = useState("")
    const [Refresh_min, setRefresh_min] = useState(0)

    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, Sys_Start])

    
    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    const refreshTasks = async () => {
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)

        try {
            const res = await gsb.post('/SBstatus' )
            if ( res.data.count > 0 ) {
                //console.log("res.data.data: ", res.data.data) 
                //console.log("res.data.data Sys_Start: ", res.data.data[0].Sys_Start) 
                setSys_Start(res.data.data[0].Sys_Start)
                setSys_End(res.data.data[0].Sys_End)
                setTrial_Match(res.data.data[0].Trial_Match)
                setDaily_Backup(res.data.data[0].Daily_Backup)
                setBatch_Running(res.data.data[0].Batch_Running)
                setBatch_Processing(res.data.data[0].Batch_Porcessing)
                //console.log("res.data.data LuDate: ", res.data.now) 
                setLuDate(res.data.LuTime)

                setShowWait(false)
                setShowNoRecord(false)
                setShowRpt(true)
            } else {
                setShowWait(false)
                setShowNoRecord(true)
                setShowRpt(false)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    
    useEffect(() => {
        //console.log("/SBstatus ") 
        refreshTasks()
    }, [gsb])


    useEffect(() => {
        //console.log("/SBstatus ") 
        let timer = setInterval(refreshTasks, 120*60*1000)
        setRefresh_min(120)
        if ( Batch_Running == 1 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 1*60*1000)
            setRefresh_min(1)
        } else if ( Batch_Running == 2 || Daily_Backup == 0 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 120*60*1000)
            setRefresh_min(120)
        } else if ( Daily_Backup == 2 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 2*60*1000)
            setRefresh_min(2)
        } else if ( Trial_Match == 2 ) {
            clearInterval(timer)
            timer = setInterval(refreshTasks, 3*60*1000)
            setRefresh_min(3)
        }
            return () => {clearInterval(timer)
        }
    }, [gsb, Daily_Backup, Sys_End, Batch_Running, Refresh_min])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setRefresh_min(0)
        refreshTasks()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    const statusIcon = (status: number) => {
        //if (status == 1) return <img className="MenuIcon" src={svgRunning} alt="Running" />
        if (status == 1) return <Spinner size={16}/>
        if (status == 2) return <img className="MenuIcon" src={svgComleted} alt="Completed" />
        return <img className="MenuIcon" src={svgNotStarted} />
    }
 
    let list = <>
        <StatusItem icon={statusIcon(Sys_Start)} text="System Start"></StatusItem>
        <StatusItem icon={statusIcon(Trial_Match)} text="Trial Match"></StatusItem>
        <StatusItem icon={statusIcon(Daily_Backup)} text="Daily Backup"></StatusItem>
        <StatusItem icon={statusIcon(Sys_End)} text="System End"></StatusItem>
        {/*
        <StatusItem icon={statusIcon(Batch_Running)} text="Batch Processing"></StatusItem>
        */}
        {/* (Batch_Running == 1) && <>
            <hr></hr>
            <div style={{fontSize: 12, textAlign: 'left'}}>Now Running: {Batch_Processing}</div>
        </> */} 
        <hr></hr>
        <div style={{fontSize: 12}}>
            Last Update: {LuDate}<br/>
            * Auto Refresh every {Refresh_min} Mins
        </div>
    </>

    return (
        <>
            <H4>GSB System Status</H4>
            <Button small fill onClick={onSubmit}>Refresh</Button>
            {ShowWait && <Spinner /> }
            {ShowNoRecord && <p>
                <div style={{fontSize: 12}}>
                    <span id="Err_msg">File SBSTATUS.LOG cannot be Open</span>
                </div>
            </p>
            }
            {ShowRpt && list}
        </>
    );
}

interface StatusItemProps {
    icon: IconName | MaybeElement
    text: string
}

function StatusItem({icon, text}: StatusItemProps) {
    return <div style={{display: 'flex', flexDirection: 'row', margin: 4}}>
    <div style={{margin: 4}}>{icon}</div>
    <div style={{margin: 4}}>{text}</div>
</div>
}

export default SBstatusMain