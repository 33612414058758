import React, { useState, FormEvent, useEffect } from 'react';
 
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem,  Label } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { Account, StatusInfo,IntRInfo,CurrencyInfo,ACBalCCYInfo,ACBalCCYInfos,CLSTKPFinfo,ClStkEntit} from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';
import { act } from '@testing-library/react';

 
 
//import { Column, Cell, Table, JSONFormat2 } 
    //from "@blueprintjs/table";
//import { LABEL } from '@blueprintjs/core/lib/esm/common/classes';
import { TH } from '@blueprintjs/icons/lib/esm/generated/iconContents';
interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;

interface ClientPosEnequiryViewProps {
    gsb: AxiosInstance,
    ac: Account, 
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onReplicate?: () => void,
    onRegGenBcan?:()=>void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}



function ClientPosEnquiryView({ gsb, ac = {} as Account,  baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onReplicate = () => { }, onRegGenBcan=()=>{ }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: ClientPosEnequiryViewProps) {

    const [isReplicate, setisReplicate] = useState(false)
    const [isRegenbcan, setisRegenbcan] = useState(false)

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    
    const [hk_Outs_bal, sethk_Outs_bal] = useState("")
    const [hk_Prn_bal,sethk_Prn_bal] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")
    //Acbalinfo
    const [Acbal_PrevDay, setAcbal_PrevDay] = useState("")
 
    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    
    // 1st paging
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [ShowWait, setShowWait] = useState(false)

    //ccy 
    const [nPageCCY, setnPageCCY] = useState(0)
    const [nTotalPageCCY, setnTotalPageCCY] = useState(0)
    const [aPageNumberCCY, setaPageNumberCCY] = useState([] as PageInfo[])
    const [selectedTabIdCCY, setSelectedTabIdCCY] = useState("listCCY")
    const [ShowWaitCCY, setShowWaitCCY] = useState(false)
/*
    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as IPOINFO)
    const [BlankAc, setBlankAc] = useState({} as IPOINFO)
    const [CurrentLog, setCurrentLog] = useState({} as IPOINFO)
*/

    //Stk
    const [nPageStk, setnPageStk] = useState(0)
    const [nTotalPageStk, setnTotalPageStk] = useState(0)
    const [aPageNumberStk, setaPageNumberStk] = useState([] as PageInfo[])
    const [ShowWaitStk, setShowWaitStk] = useState(false)


    const imgWaitStk = <img className="WaitIcon" src={svgWait2} alt="wait" />
    
    //Entit

    const [nPageEntit, setnPageEntit] = useState(0)
    const [nTotalPageEntit, setnTotalPageEntit] = useState(0)
    const [aPageNumberEntit, setaPageNumberEntit] = useState([] as PageInfo[])
    const [ShowWaitEntit, setShowWaitEntit] = useState(false)
    const imgWaitEntit = <img className="WaitIcon" src={svgWait2} alt="wait" />
    

    //ACBalInfoCCY
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as ACBalCCYInfo[])
    const [ACBalCCYdata, setACBalCCYData] = useState([] as ACBalCCYInfo[])
    const [ACBalCCYdatas, setACBalCCYDatas] = useState({} as ACBalCCYInfos)
   
    const [CLSTKPFinfo, setCLSTKPFinfo] = useState({} as CLSTKPFinfo[])
    const [ClStkEntit, setClStkEntit] = useState({} as ClStkEntit[])

      
    const [GEMDATE,setGEMDATE] = useState("")
    const [d_RISKALLOW,setRISKALLOW] = useState("")
    const [d_RnerName,setRnerName] = useState("")
    const [d_RnerName2,setRnerName2] = useState("")
    const [d_AC_STATUS,setAC_STATUS] = useState("")
 
    //const [d_Oh_qty_hk_MktVal,setOh_qty_hk_MktVal] = useState("") 
    //const [d_Oh_qty_hk_MgnVal,setoh_qty_hk_MgnVal] = useState("") 
    
    const [showMGN,setshowMGN] = useState(false)   
    const [curr_Day_Status,setcurr_Day_Status] = useState("")

    
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClientPosEnquiryView UserPgmAccess22 res.data.data", res.data.data)
                   // setData456(data123);
                    //console.log(data456[0].title);
                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

    //hkd

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                /*
                                   const res = await gsb.post('/Report',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_sortby: s_sortby,
                            s_ClCode1: s_ClCode1,
                            s_ClCode2: s_ClCode2,
                            s_AeCode1: s_AeCode1,
                            s_AeCode2: s_AeCode2,
                            s_RptName: RptName,
                            DataFormat: DataFormatValue,
                            nPage: nPage,
                            cPrintMode: "S",
                            limit: LineLimit,
                            PgmName: "Portfolio",
                        },
                        cPgmId: "PortfolioEvaluation",
                    });
                */
                setShowWait(true)
                const res = await gsb.post('/Report',
                 {sb_data: {}, cPgmId: "ClientPostEnquiryMast", cAction: "GetACBalInfoCCY", cAcCode: ac.d_acCode});
                console.log("detial",res.data.data[0]);
                if ( ac.d_acCode.trim().length > 0 && res.data.count > 0 )
                {

                    //    const [ACBalCCYdatas, setACBalCCYDatas] = useState({} as ACBalCCYInfos)
   
                    setAC_STATUS(res.data.data[0].summary.d_AC_STATUS)
                    setACBalCCYData(res.data.data[0].ACBalCCYInfo);      
                    setACBalCCYDatas(res.data.data[0]);
               
                    setCLSTKPFinfo(res.data.data[0].CLSTKPFinfo);
                    setClStkEntit(res.data.data[0].ClStkEntit);
                    //console.log("ClStkEntit:",res.data.data[0].ClStkEntit);
                    //console.log("d_i_MgnCall_T0:",res.data.data[0].d_i_MgnCall_T0);
                    //console.log("d_i_MgnCall_T2:",res.data.data[0].d_i_MgnCall_T2);
                    //console.log("d_i_Value_Remain:",res.data.data[0].d_i_Value_Remain);
                    //console.log("d_i_Purchase_allow:",res.data.data[0].d_i_Purchase_allow);                
                    sethk_Outs_bal(res.data.data[0].summary.d_hk_Outs_bal);
                    sethk_Prn_bal(res.data.data[0].summary.d_i_Ttl_hk_Prin_bal);
                    setGEMDATE(res.data.data[0].summary.d_GEMDATE);
                    //setRISKALLOW(res.data.data[0].summary.d_RISKALLOW);
                    setRnerName(res.data.data[0].summary.d_RnerName);
                    setRnerName2(res.data.data[0].summary.d_RnerName2);
                    //setOh_qty_hk_MktVal(res.data.data[0].d_oh_qty_hk_MktVal);
                    //setoh_qty_hk_MgnVal(res.data.data[0].d_oh_qty_hk_MgnVal);
                    setshowMGN(res.data.data[0].Show_MGN);
                    setcurr_Day_Status(res.data.data[0].curr_Day_Status);
                 
                    //console.log(res.data.data[0].d_oh_qty_hk_MktVal);
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                    setAcbal_PrevDay("")
                }
                setShowWait(false)
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, ac])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPageCCY > 0 && nPageCCY <= nTotalPageCCY && selectedTabIdCCY == "listCCY" ) {

            setShowWait(true)
            if ( nPageCCY > 0 ) {
                //setcPrintMode("S")
            } 
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Report',
                    {   sb_data: "",cPgmId: "ClientPostEnquiryMast",cAction: "GetACBalInfoCCY",
                    });  
                    
                    setACBalCCYData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPageCCY, selectedTabIdCCY])

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    function PageNoCCY(n: number) {
        if (n > 0 ) {
            if ( nPageCCY+n <= nTotalPageCCY ) {
                setnPageCCY(nPageCCY+n)
            }
        } else
        {
            if ( nPageCCY+n > 0 ) {
                setnPageCCY(nPageCCY+n)
            }
        }
        return null;
    }

    let PaginationCCY = <>
    <div className="Row">
        <FormGroup className="Col-1-12" label="">
            Page
            { (nTotalPageCCY > 1) &&
                <Button minimal onClick={() =>PageNoCCY(-1)}>&laquo;</Button>
            }
            { (nTotalPageCCY > 1) && (nPageCCY != 1) && 
                <Button minimal onClick={() => setnPageCCY(1)}>{1}</Button>
            }
            {aPageNumberCCY.map((no, j) => 
                (j+1 > nPageCCY-5) && (j+1 < nPageCCY) && (j+1 != 1) && <Button minimal onClick={() => setnPageCCY(no.n)}>{no.n}</Button>
            )}
            { (nTotalPageCCY >= 1) && <Button intent='primary'>{nPageCCY}</Button> }
            {aPageNumberCCY.map((no, j) => 
                (j+1 > nPageCCY) && (j+1 < nPageCCY+5) && (j+1 != nTotalPageCCY) && <Button minimal onClick={() => setnPageCCY(no.n)}>{no.n}</Button>
            )}
            { (nTotalPageCCY > 1) && (nPageCCY != nTotalPageCCY) && 
                <Button minimal onClick={() => setnPageCCY(nTotalPageCCY)}>{nTotalPageCCY}</Button>
            }
            { (nTotalPageCCY > 1) &&
                <Button minimal onClick={() =>PageNoCCY(+1)}>&raquo;</Button>
            }
        </FormGroup>
    </div>
</>

    let listCCY = <>
   
    {ShowWait && <div>{imgWait}</div> }
    {nPageCCY > 0 && PaginationCCY}
    <HTMLTable striped interactive>
        <thead>
            <th className="TCol-100"><div>CCY</div></th>  
            <th className="TCol-150"><div className="Align-R">Outstanding</div></th>
            <th className="TCol-150"><div className="Align-R">Principal</div></th>   
            <th className="TCol-150"><div className="Align-R">T+1</div></th>   
            <th className="TCol-150"><div className="Align-R">T+2</div></th>   
            <th className="TCol-150"><div className="Align-R">Interest</div></th>     
            <th className="TCol-150"><div className="Align-R">Uncleared Cheque</div></th>   
            <th className="TCol-100"><div className="Align-R">Ex. Rate </div></th>                            
        </thead>
        
        <tbody id="ccy_tr">
            {ACBalCCYdata.map((ac, i) =>              
                <tr>
                    <td className="TCol-100"><div>{ac.d_Currency}</div></td>
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_Outs_bal)}</div></td>
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_Prin_bal)}</div></td>             
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_T1_bal)}</div></td>
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_T2_bal)}</div></td>         
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_Interest)}</div></td>          
                    <td className="TCol-150"><div className="Align-R">{utils.formatNumber2(ac.d_i_Unclear_chq)}</div></td>        
                    <td className="TCol-100"><div className="Align-R">{(ac.d_i_exRate)}</div></td>       
                </tr>   
                
            )}     
                <tr>
                    <td></td>                   
                    <td className="Column-Sum"><div className="Align-R">{utils.formatNumber2(hk_Outs_bal)}</div></td>
                    <td className="Column-Sum"><div className="Align-R">{utils.formatNumber2(hk_Prn_bal)}</div></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>           
        </tbody>
    </HTMLTable>
    {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
    {nPageCCY > 0 && PaginationCCY}
    </>



    function PageNoStk(n: number) {
        if (n > 0 ) {
            if ( nPageStk+n <= nTotalPageStk ) {
                setnPageStk(nPageStk+n)
            }
        } else
        {
            if ( nPageStk+n > 0 ) {
                setnPageStk(nPageStk+n)
            }
        }
        return null;
    }

    function PageNoEntit(n: number) {
        if (n > 0 ) {
            if ( nPageEntit+n <= nTotalPageEntit ) {
                setnPageEntit(nPageEntit+n)
            }
        } else
        {
            if ( nPageEntit+n > 0 ) {
                setnPageEntit(nPageEntit+n)
            }
        }
        return null;
    }

    function oddOrEven(n:number) {
        return (n & 1 ) ? "odd" : "even";
     }

    let PaginationStk = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPageStk > 1) &&
                    <Button minimal onClick={() =>PageNoStk(-1)}>&laquo;</Button>
                }
                { (nTotalPageStk > 1) && (nPageStk != 1) && 
                    <Button minimal onClick={() => setnPageStk(1)}>{1}</Button>
                }
                {aPageNumberStk.map((no, j) => 
                    (j+1 > nPageStk-5) && (j+1 < nPageStk) && (j+1 != 1) && <Button minimal onClick={() => setnPageStk(no.n)}>{no.n}</Button>
                )}
                { (nTotalPageStk >= 1) && <Button intent='primary'>{nPageStk}</Button> }
                {aPageNumberStk.map((no, j) => 
                    (j+1 > nPageStk) && (j+1 < nPageStk+5) && (j+1 != nTotalPageStk) && <Button minimal onClick={() => setnPageStk(no.n)}>{no.n}</Button>
                )}
                { (nTotalPageStk > 1) && (nPageStk != nTotalPageStk) && 
                    <Button minimal onClick={() => setnPageStk(nTotalPageStk)}>{nTotalPageStk}</Button>
                }
                { (nTotalPageStk > 1) &&
                    <Button minimal onClick={() =>PageNoStk(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>





    let PaginationEntit = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPageEntit > 1) &&
                    <Button minimal onClick={() =>PageNoEntit(-1)}>&laquo;</Button>
                }
                { (nTotalPageEntit > 1) && (nPageEntit != 1) && 
                    <Button minimal onClick={() => setnPageEntit(1)}>{1}</Button>
                }
                {aPageNumberEntit.map((no, j) => 
                    (j+1 > nPageEntit-5) && (j+1 < nPageEntit) && (j+1 != 1) && <Button minimal onClick={() => setnPageEntit(no.n)}>{no.n}</Button>
                )}
                { (nTotalPageEntit >= 1) && <Button intent='primary'>{nPageEntit}</Button> }
                {aPageNumberEntit.map((no, j) => 
                    (j+1 > nPageEntit) && (j+1 < nPageEntit+5) && (j+1 != nTotalPageEntit) && <Button minimal onClick={() => setnPageEntit(no.n)}>{no.n}</Button>
                )}
                { (nTotalPageEntit > 1) && (nPageEntit != nTotalPageEntit) && 
                    <Button minimal onClick={() => setnPageEntit(nTotalPageEntit)}>{nTotalPageEntit}</Button>
                }
                { (nTotalPageEntit > 1) &&
                    <Button minimal onClick={() =>PageNoEntit(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    let listStk = <>
        <div className="Row">
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWaitStk && <div>{imgWaitStk}</div> }
        {nPageStk > 0 && PaginationStk}
        {/*check no on hand stock   => mgn*/} 
        {CLSTKPFinfo.length > 0 && <HTMLTable striped interactive>
            <thead>
            
                    <th className="TCol-100"><div>Stock</div></th>
                    <th className="TCol-100"><div>Market</div></th>
                    <th className="TCol-100"><div>CCY</div></th>
                    <th className="TCol-350"><div>Stock Code</div></th>
                    <th className="TCol-150"><div className="Align-R">Qty On Hand</div></th>
                    <th className="TCol-100"><div className="Align-R">Close Price</div></th>                
                    <th className="TCol-150"><div className="Align-R">Qty On Hand Market Value</div></th>           
                    <th className="TCol-150"><div className="Align-R">Qty On Hand Market Value (HKD)</div></th>
                    {showMGN && <th className="TCol-100"><div className="Align-R">Margin Ratio</div></th>}  
                    {showMGN && <th className="TCol-150"><div className="Align-R">Margin Value (HKD)</div></th>}
                    <th className="TCol-100"><div className="Align-R">Board lot</div></th>
                    <th className="TCol-100"><div className="Align-R">Avg Cost</div></th>
                
            </thead>
        
        {<tbody id="ma_tr">
            {CLSTKPFinfo.map((ac, i) =>
                {return (ac.showline === "Y") ? <tr><td></td></tr> :
                    <tr  key={(i)}>
                        <td className="TCol-100"><div>{ac.d_i_StkNo}</div></td>
                        <td className="TCol-100"><div>{ac.d_Mkt}</div></td>
                        <td className="TCol-100"><div>{ac.d_MCURCY}</div></td>
                        <td className="TCol-350">
                            <div>{ac.d_i_StkCode}</div>
                            {ac.d_i_stkMsg_75.map((m, i) => <div>{m}</div>)}
                        </td>
                        <td className="TCol-150"><div className="Align-R">{utils.formatNumber(ac.d_i_oh_qty)}</div></td>
                        <td className="TCol-100"><div className="Align-R">{(ac.d_i_Price)}</div></td>
                        <td className="TCol-150"><div className="Align-R">{utils.formatMoney(ac.d_i_oh_qty_MktVal)}</div></td>
                        <td className="TCol-150"><div className="Align-R">{utils.formatMoney(ac.d_i_oh_qty_hk_MktVal)}</div></td>
                        {showMGN && <td className="TCol-100"><div className="Align-R">{(ac.d_i_MarginRatio)}</div></td>}                     
                        {showMGN && <td className="TCol-150"><div className="Align-R">{(utils.formatMoney(ac.d_i_oh_qty_hk_MgnVal))}</div></td>}
                        <td className="TCol-100"><div className="Align-R">{(ac.d_m_lots)}</div></td>      
                        <td className="TCol-100"><div className="Align-R">{(ac.d_i_avgcost)}</div></td>                    
                    </tr>
                }
            )}  
 
               
                <tr>
                    <td><div></div></td>
                    <td><div></div></td>
                    <td><div></div></td>                    
                    <td><div></div></td>
                    <td><div></div></td>                    
                    <td><div></div></td>                    
                    <td><div className="Align-R">Total: </div></td>
                    <td className="Column-Sum"><div className="Align-R">{utils.formatMoney(ACBalCCYdatas.d_oh_qty_hk_MktVal)}</div></td>
                    {showMGN && <td></td>}
                    {showMGN && <td className="Column-Sum"><div className="Align-R">{utils.formatMoney(ACBalCCYdatas.d_oh_qty_hk_MgnVal)}</div></td>}
                    <td></td>
                </tr> 
                
                {!(ACBalCCYdatas.d_i_haveStk) &&  <div><tr><td className="Table1-80" colSpan={10}>{"** There is no stock on hand **"}</td></tr> </div>}
                {(showMGN && ACBalCCYdatas.d_i_MgnCall_T0 > 0) ? <tr><td>T+0 Margin Call :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_MgnCall_T0)}</td></tr> : null}
                {(showMGN &&  ACBalCCYdatas.d_i_MgnCall_T2 > 0) ? <tr><td>T+2 Margin Call :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_MgnCall_T2)}</td></tr> : null}

                {(showMGN && ACBalCCYdatas.d_i_Value_Remain > 0) ? <tr><td>Value Remained:</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_Value_Remain)}</td></tr> : null}
                {(showMGN &&  ACBalCCYdatas.d_i_Purchase_allow > 0) ? <tr><td>Further Purchase Allowed :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_Purchase_allow)}</td></tr> : null}


                {showMGN ? <tr><td colSpan={10}></td></tr> : <tr><td colSpan={8}></td></tr>}
                {showMGN ? <tr><td colSpan={10}>{curr_Day_Status}</td></tr> : <tr><td colSpan={8}>{curr_Day_Status}</td></tr>}
                
            </tbody>}
             
        </HTMLTable> }
     
        {CLSTKPFinfo.length === 0 && <HTMLTable striped interactive>
        <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>                
                <th></th>           
                <th></th>
                {showMGN &&  <th><div className="Table1-100"></div></th>}  
                {showMGN &&  <th><div className="Table1-100"></div></th>}
                <th><div className="Table1-80"></div></th>
            </thead>
            <tbody>
                <tr><td className="Table1-80"colSpan={10} >{"** There is no stock on hand **"}</td>
                </tr>
                {(showMGN && ACBalCCYdatas.d_i_MgnCall_T0 > 0) ? <tr><td>T+0 Margin Call :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_MgnCall_T0)}</td></tr> : null}
                {(showMGN &&  ACBalCCYdatas.d_i_MgnCall_T2 > 0) ? <tr><td>T+2 Margin Call :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_MgnCall_T2)}</td></tr> : null}

                {(showMGN && ACBalCCYdatas.d_i_Value_Remain > 0) ? <tr><td>Valie Remained:</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_Value_Remain)}</td></tr> : null}
                {(showMGN &&  ACBalCCYdatas.d_i_Purchase_allow > 0) ? <tr><td>Further Purchase Allowed :</td><td colSpan={9}>{utils.formatNumber2(ACBalCCYdatas.d_i_Purchase_allow)}</td></tr> : null}
              
                </tbody> 
            </HTMLTable>
            }
        {ShowWaitStk && nTotalPageStk > 0 && <div>{imgWaitStk}</div> }
        {nPageStk > 0 && PaginationStk}
    </>


    let listEntit = <>
        <div className="Row">
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWaitEntit && <div>{imgWaitEntit}</div> }
        {nPageEntit > 0 && PaginationEntit}
        {/*check no on hand stock   => mgn*/} 
        {ClStkEntit.length > 0 && <HTMLTable  className="Table1-80">
            <thead  >
               
                    <th><div className="Table1-100">Stock No</div></th>
                    <th><div className="Table1-100">Code</div></th>
                    <th><div className="Table1-100">issue & Particular</div></th>
                    <th><div className="Table1-100">Ex. Date</div></th>
                    <th><div className="Table1-100">Book Close Date</div></th>
                    <th><div className="Table1-100">Payable Date</div></th>
                    <th><div className="Table1-100">Book Closed Quantity</div></th>  
             
            </thead>               
        {<tbody id="ma_tr">
            {ClStkEntit.map((ac, i) =>              
                <tr key={i} className={"Table1-100"}>    
                                    
                    <td><div className={"Table1-100"}>{ac.d_i_StkNo}</div></td>
                    <td><div className={"Table1-100"}>{ac.d_i_StkCode}</div></td>
                    <td><div className={"Table1-100"}>{ac.d_i_Desc}</div></td>
                    <td><div className={"Table1-100"}>{ac.d_i_ExDate}</div></td>
                    <td><div className={"Table1-100"}>{ac.d_i_BCDate}</div></td>                   
                    <td><div className={"Table1-100"}>{ac.d_i_PayDate}</div></td>                
                    <td><div className={"Table1-100"}>{ac.d_i_BCQty}</div></td>                                    
                </tr>             
            )}   
                
            </tbody>}
             
        </HTMLTable> }
     
        {ShowWaitEntit && nTotalPageEntit > 0 && <div>{imgWaitEntit}</div> }
        {nPageEntit > 0 && PaginationEntit}
    </>



    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }
    if (! ("d_acCode" in ac)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    return (
 
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
 
       
 
                <Button className="Col-2-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-7-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
          
            </div> }
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No.">
                    <InputGroup readOnly value={(ac.d_acCode) } />
                </FormGroup>
                <FormGroup className="Col-2-3" label="Name">
                    <InputGroup readOnly value={(ac.d_acName1) } />
                    <InputGroup readOnly value={(ac.d_acName2) } />
                </FormGroup>         
                <FormGroup className="Col-5-1" label="Runner">
                    <InputGroup readOnly value={ac.d_aeCode} /> 
                </FormGroup> 
                <FormGroup className="Col-6-3" label="Runner Name">
                    <InputGroup readOnly value={d_RnerName} /> 
                    <InputGroup readOnly value={d_RnerName2} /> 
                </FormGroup> 
                <FormGroup className="Col-9-1" label="Status">  
                    { GsbFlag.SUSPEND_AC && <InputGroup readOnly value={(d_AC_STATUS) } /> }                 
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Phone">                   
                    <InputGroup readOnly value={(ACBalCCYdatas.d_Tel) } />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Office">                   
                    <InputGroup readOnly value={(ACBalCCYdatas.d_OffTel) } />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Mobile">                   
                    <InputGroup readOnly value={(ACBalCCYdatas.d_Mobile) } />
                </FormGroup>

                {ACBalCCYdatas.d_Is_GEM? <FormGroup className="Col-8-1" label="GEM"><Button intent='success'>Allowed</Button></FormGroup> : 
                null
                }

                {ACBalCCYdatas.d_RISKALLOW ? <FormGroup className="Col-9-1" label="High Risk"><Button intent='success'>Allowed</Button></FormGroup> : 
                <FormGroup className="Col-9-1" label="High Risk"><Button intent='danger'>NOT Allowed</Button></FormGroup>
                }
            </div>
            <div className="Row">
                {(showMGN == true) && <FormGroup className="Col-1-2" label="Loan Limit">
                    <InputGroup readOnly value={utils.formatNumber2(ACBalCCYdatas.d_TradingLoanLimit)} /> 
                    </FormGroup>  
                ||(showMGN == false) && <FormGroup className="Col-1-2" label="Trading Limit">
                    <InputGroup readOnly value={utils.formatNumber2(ACBalCCYdatas.d_TradingLoanLimit)} /> 
                    </FormGroup>
                }

                {(showMGN == true) && <FormGroup className="Col-3-1" label="Margin Ratio" labelInfo="*">
                    <InputGroup readOnly value={utils.formatNumber2(ACBalCCYdatas.d_MarginRatio)} /> 
                    </FormGroup>  
               
                }

                <FormGroup className="Col-5-1" label="BCAN HK">
                    <InputGroup readOnly value={(ACBalCCYdatas.d_HKBCAN) } />
                </FormGroup>
            </div>
            {listCCY}
            {listStk}
            {listEntit}
            {/* <div className="Row">
                <FormGroup className="Col-1-7" label="" labelInfo="*">
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="" panel={listCCY} />
                    </Tabs>
                </FormGroup>
                <FormGroup className="Col-1-7" label="" labelInfo="*">
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="" panel={listStk} />
                    </Tabs>
                </FormGroup> 
                <FormGroup className="Col-1-7" label="" labelInfo="*">
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="" panel={listEntit} />
                    </Tabs>
                </FormGroup> 
            </div> */}
      
           
         
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={(ac.d_acCode) } />
                    { GsbFlag.SUSPEND_AC && <InputGroup readOnly value={(d_AC_STATUS) } /> }                 

                    <InputGroup readOnly value={GEMDATE} />
                    <InputGroup readOnly value={d_RISKALLOW} />             
                </FormGroup>           
              
                <FormGroup className="Col-4-2" label="Runner" labelInfo="*">
                    <InputGroup readOnly value={ac.d_aeCode} /> 
                    <FormGroup  label="Runner Name" labelInfo="*">
                        <InputGroup readOnly value={d_RnerName} />
                    </FormGroup>                    
                </FormGroup>      
            
                <FormGroup className="Col-1-2" label="Client Name" labelInfo="*">
                    <InputGroup readOnly value={ac.d_acName1} />
            
                </FormGroup>

                <div className="Col-7-2">
                    test 6-1
            
                </div>
   
 
                <FormGroup className="Col-1-2" label="TEL:" labelInfo="*">
                    <InputGroup readOnly value={ac.d_tel} />
            
                </FormGroup>
   
                <FormGroup className="Col-1-2" label="Trading Limit" labelInfo="*">
                  
                    <InputGroup readOnly value={utils.formatNumber2(ac.d_loanLimit)} />
                </FormGroup> 

            </div>
            */}
            {/* when clienttype 1,2 */}
 
           

            {/* when clienttype 3,4,5,6  */}

 
 

            {/*
            <div className="Row">
            <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title="List" panel={listStk} />
            </Tabs>

            </div> */}
  
        
        


        </div>
    )
}

interface ClientPosEnquiryEditProps {
    gsb: AxiosInstance,
    ac?: Account,
 
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
}


// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ClientPosEnquiryEdit({ gsb, ac = {} as Account , isNew = false, onSave = () => { }, onCancel = () => { } }: ClientPosEnquiryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
/*
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

*/

    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")
    const [BCAN_Allow_Change, setBCAN_Allow_Change] = useState(false)
     
   // const [search, setse] = useState(Bcan.d_acCode)
   const [isOpened, setIsOpened] = useState(false);

    //const [def_d_NetAsset, setdef_d_NetAsset] = useState(ac.d_NetAsset)

   // const [SendemailValue, setSendemailValue] = useState(Bcan.d_sendemail==="Y")

    const [def_d_JurisdictionofResidence3, setdef_d_JurisdictionofResidence3] = useState("")
 
    const [searchKey, setSearchKey] = useState("acCode")


    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>
   
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_aeCode" })
        register({ name: "d_status" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])



    if (!isNew && !("d_acCode" in act)) return null

    let idNoLength = 40
//    if ( GsbFlag.user_key === "TATLEE" ) {
//        idNoLength = 22
//    }




    const onSubmit = (data: Account) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();
    
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();
     
 


/*        
        data.d_sendemail = "Y";
        if (SendemailValue) {
            data.d_sendemail = "Y";
        } else {
            data.d_sendemail = "N";
        }
*/        

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call HkidrMastedit data", data)
                //console.log("call HkidrMastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "HkidrMast", cAction: "HkidrValidate", isNew: isNew})
                // console.log("HkidrMastedit res.data.ok", res.data.ok)
                //console.log("HkidrMastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


 

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode" labelInfo="*">
                </FormGroup>

  
            {/* 
 
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                    </Tooltip>                         
                                 
                   </FormGroup>
 
                { GsbFlag.SUSPEND_AC && <FormGroup className="Col-5-1" label="Status" labelFor="inputStatus">
                    <div>
                        <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup> }
                <FormGroup className="Col-6-3" label="Name in GSB" labelFor="inputName">
                    <div>
                        <div id="DispText">{AcName1Value}3</div>
                        <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
           
            */}
            </div>
 ``
  
        
 
            
        </form>
    )
}

interface ClientPosEnquiryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClientPosEnquiryMain({ gsb }: ClientPosEnquiryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

 
    let acCodeKey = utils.getUrlParameter('acCode', url); 
    let acCodeKey2 = acCodeKey.substr(0,acCodeKey.indexOf("?")); 
    let acCodeonly = utils.getUrlParameter('acCodeonly', url); 

   // let acCode =  utils.getUrlParameter('acCode', url); 
    const [data, setData] = useState([] as Account[])


    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchConsent, setsearchConsent] = useState("")
    const [searchClientType, setsearchClientType] = useState("")
    const [searchIDType, setsearchIDType] = useState("")
    const [searchCNConsent, setsearchCNConsent] = useState("")

    const [def_id_Type, setid_Type] = useState("")

    const [searchTel, setSearchTel] = useState("")


    const [s_AcStatus, sets_AcStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)
    const [searchStatus, setSearchStatus] = useState("")

    const [CurrentLog, setCurrentLog] = useState({} as Account)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    
    const { t, i18n } = useTranslation();
    //HKIDR default value
    //BlankAc.d_sendemail = "Y"
    
    // 
    //const [countryList, setCountryList] = useState([] as CountryINFO[])
 
    //console.log("ClMastMain logKey: ", logKey)
    //console.log("ClMastMain logKey2: ", logKey2)
    //console.log("ClMastMain acCodeonly: ", acCodeonly)

    const [currentIntR, setCurrentIntR] = useState({} as IntRInfo)
    const [BlankIntR, setBlankIntR] = useState({} as IntRInfo)
    
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("HkidrMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("HkidrMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("HkidrMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("HkidrMastMain disp GsbFlag: ", GsbFlag)
                //console.log("HkidrMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("HkidrMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("HkidrMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("HkidrMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("HkidrMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);
 

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

 
    useEffect(() => {
     
        if (acCodeKey.length> 0) {
            setViewMode("L")
        
        }
        else
        {
             setViewMode("V")
        

        }
 
        ;
        (async () => {
            try {
                if ( acCodeKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_acCode: acCodeKey,
                            nPage: 1,
                            limit: 1,
                        }, 
                        cPgmId: "Clmast", 
                        cAction: "GetRec"
                    })
                    //console.log("CHkidrMastMain GetRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentAc(res.data.data)
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, acCodeKey])

 
    const PrevRecProcess = async (ac: Account) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }
    
  

    const NextRecProcess = async (ac: Account) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (ac: Account) => {
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
            } else {
                showSuccess("Account No. " + ac.d_acCode + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (ac: Account) => {
        try {
            console.log("call editOnSave", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account No. " +ac.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

  
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            idNo: searchIdNo,
                            tel: searchTel,
                            status: searchStatus
                        },
                    },
                    cPgmId: "Clmast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                //console.log("ListRec res.data.PageShowInfo", res.data.PageShowInfo)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "Clmast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)

            ;

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });

                    //console.log("ListRec Press  res.data.PageShowInfo", res.data.PageShowInfo)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }
    
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show   //IntR={currentIntR} CurrencyList={CurrencyList}
    let detail = <ClientPosEnquiryView gsb={gsb} ac={currentAc}   baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")}  onEdit={() => setCurrentMode("edit")} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={acCodeKey2.trim()} ></ClientPosEnquiryView>
    switch (currentMode) {
        case "edit":
            detail = <ClientPosEnquiryEdit gsb={gsb} ac={currentAc}    onSave={editOnSave} onCancel={() => setCurrentMode("show")}></ClientPosEnquiryEdit>
            break
        case "new":
            detail = <ClientPosEnquiryEdit gsb={gsb} ac={BlankAc}      isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ClientPosEnquiryEdit>
            break
    }

    let logKeyViewRec = <ClientPosEnquiryView gsb={gsb} ac={currentAc}    baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")}  onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={acCodeKey2.trim()} ></ClientPosEnquiryView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


 
 
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                 {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
    {/*
    <div className="Row">
        <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
    </div>*/}
    <div className="Spacing-V-16" />
    {ShowWait && <div>{imgWait}</div> }
    {nPage > 0 && Pagination}
    <HTMLTable striped interactive condensed>
        <thead>
            <th className="TCol-ACCode">Account</th>
            <th className="TCol-ACName">Name</th>
            {/*<th className="TCol-IDNo">ID</th>*/}
            <th className="TCol-Com">Comm</th>
            <th className="TCol-AECode">Runner</th>
            <th className="TCol-Tel">Phone</th>
            <th className="TCol-Int">Int</th>
            <th className="TCol-Mgn">Mgn</th>
            
            <th className="TCol-Status">Status</th>
        </thead>
        <tbody id="ma_tr">
            {data.map((ac, i) =>
                <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                    <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                    <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>
                    {/*<td className="TCol-IDNo" onClick={() => { setCurrentAc(ac) }}>{ac.d_idNo}</td>*/}
                    <td className="TCol-Tel" onClick={() => { setCurrentAc(ac) }}>{ac.d_commCode}</td>
                    <td className="TCol-AECode" onClick={() => { setCurrentAc(ac) }}>{ac.d_aeCode}</td>
                    <td className="TCol-Tel" onClick={() => { setCurrentAc(ac) }}>{ac.d_tel}</td>
                    <td className="TCol-Int" onClick={() => { setCurrentAc(ac) }}>{ac.d_intCode}</td>
                    <td className="TCol-Tel" onClick={() => { setCurrentAc(ac) }}>{ac.d_MarginRatio}</td>   
                               
                    <td className="TCol-Status" onClick={() => { setCurrentAc(ac) }}>{ac.d_status}</td>
                </tr>
            )}
        </tbody>
    </HTMLTable>
    {ShowWait && nPage > 0 && <div>{imgWait}</div> }
    {nPage > 0 && <><div className="Spacing-V-16" />{Pagination}</>}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( acCodeKey.length > 0 ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Client Position Enquiry</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>                                    
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >Search/List</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        {/*<Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                    </div>
                    <Collapse isOpen={showFilters}>


                        { SUSPEND_AC && <div className="Row">
                            <FormGroup className="Col3-1-1" label="A/C Status" labelFor="inputAcStatus">
                                <select  id="inputAcStatus" value={s_AcStatus} onChange={utils.handleStringChange(s => sets_AcStatus(s))}>
                                    <option value=""> </option>
                                    <option value="A">Active</option>
                                    <option value="NA">Non-Active</option>
                                    {/*<option value="C">Closed</option>*/}
                                    {/*<option value="S">Suspend</option>*/}                                                                        
                                </select>
                            </FormGroup>
                        </div> }
                        <div className="Row">
                           <FormGroup className="Col-1-1" label="ID No" labelFor="searchIdNo">
                                <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                            </FormGroup>          

                            <FormGroup className="Col3-2-1" label="ClientType" labelFor="searchClientType">
                                <select  id="searchClientType" value={searchClientType} onChange={utils.handleStringChange(s => setsearchClientType(s))}>
                                    <option value="">{t('')}</option>
                                    <option value=" ">{t('no client type')}</option>
                                    <option value="1">{t('Individual')}</option>
                                    <option value="2">{t('Joint Account')}</option>
                                    <option value="3">{t('Funds')}</option>
                                    <option value="4">{t('Legal Entity - Fund managers')}</option>
                                    <option value="5">{t('Proprietary Trading')}</option>
                                    <option value="6">{t('Legal entity - Others')}</option>    
                                </select>
                            </FormGroup>       
   
                               <FormGroup className="Col3-3-1" label="IDType" labelFor="searchIDType">
                                <select  id="searchClientType" value={searchIDType} onChange={utils.handleStringChange(s => setsearchIDType(s))}>
                                    <option value="">{t('')}</option>
                                    <option value=" ">{t('no ID type')}</option>
                                    <option value="1">{t('HKID')}</option>
                                    <option value="2">{t('National Identification Document')}</option>
                                    <option value="3">{t('Passport')}</option>
                                    <option value="4">{t('LEI')}</option>
                                    <option value="5">{t('Certificate of Incorporation')}</option>
                                    <option value="6">{t('Business Registration Certificate')}</option>    
                                    <option value="7">{t('Other official incorporation documents')}</option>    
                                </select>
                            </FormGroup>           
                               
                            <FormGroup className="Col3-4-1" label="Consent" labelFor="searchConsent">
                                <select  id="searchConsent" value={searchConsent} onChange={utils.handleStringChange(s => setsearchConsent(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>       

                            <FormGroup className="Col3-5-1" label="CNConsent" labelFor="searchCNConsent">
                                <select  id="searchCNonsent" value={searchCNConsent} onChange={utils.handleStringChange(s => setsearchCNConsent(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>                            
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                      <div className="Spacing-V-16" />
                      <H3><div style={{justifyContent: 'flex-end'}}>Account Balance & Portfolio</div></H3>
                      
                   
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ClientPosEnquiryMain